<title>Offer</title>
<div class="container" style="padding-top: 50px;">
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
<img src="../../assets/Images/Offers-Grey.png" class="center-block" style="width: 70px;">

<h4 style="text-align: center;font-size: 30px;font-family:Ubuntu-Medium !important ;">Offers</h4>
    </div>
</div>


<div class="container-fluid" style="padding-top:40px;padding-bottom: 80px;" >
    
    <div class="col-lg-6 col-xs-12 col-sm-12 col-md-6" *ngFor ="let item of OffersCoupon" style="margin-bottom: 50px;" >
        <div class="col-lg-1"></div>
        <div class=" col-xs-12 col-sm-12 col-md-11 " style="padding-left: 25px;"   >
           <div class="col-lg-11 col-md-11 col-xs-11 col-sm-11"style="padding: 20px; border:1px solid rgba(111,111,111,0.5);border-radius: 8px;">
            <div *ngIf="item.couponId != '' ? true :false" >
                <h5 class="tria"><span style="    top: 7px; position: relative;">Coupon Availabe</span> </h5>
            </div>
            <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12" >
                <img src={{item.imageUrl}} style="height: 150px;width: 150px;" class="center-block" />
            </div>
            <div class="col-lg-7 col-md-7 col-xs-6 col-sm-6">
                <h3 style="font-family:Ubuntu-Medium ;margin-top: unset;font-size: 22px;height: 43px;" >{{item.itemName}}</h3>
                <div >
                    <p class="oldprice " style="font-size:18px"   *ngIf='item.couponId == "" ? true :false'  >$ {{item.prize  | number:'.2'}}</p> 
                </div>
                <p style="font-size:24px;font-family: Ubuntu-Bold !important;" >${{ item.couponId == '' ? item.couponType=="P" ? ((item.prize -(item.couponAmt/100))| number:'.2') : (((item.prize -(item.couponAmt)) )| number:'.2') : (item.prize  | number:'.2')}} 
                     <span style="font-size: 18px;font-family:Ubuntu-Regular !important;" *ngIf="item.couponId != '' ? false :true" > You Save $ {{item.couponType=="P" ? ( (  (item.prize -(item.prize -(item.couponAmt/100)))) | number:'.2' ) : (  item.prize -(item.prize -(item.couponAmt)) )|  number : '2 2-2' }} </span></p>
          <div class="col-lg-4 col-xs-6 col-sm-6 col-md-6" style="padding: unset;">
            <div class="input-group" style="background-color:  #EE562F;color: white;">
                <div class="input-group-btn">
                    <button id="up" class="btn" style="color: white;border:unset;;padding-left: 5px !important;padding: unset; font-size: 17px;z-index: 0;background: none;"  (click)="down('1',item)"><span class="glyphicon glyphicon-minus" ></span></button>
                </div>
               
                <input type="number" id="myNumber" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"   style="background-color:  #EE562F;color: white; -webkit-box-shadow:unset;box-shadow: unset; border:unset;text-align: center; font-size: 17px;z-index: 0;font-family: Ubuntu-BOLD !important;"  class="form-control input-number" [(ngModel)]="item.quantity" (change)="AddcartQuant(item)" />
                <div class="input-group-btn">
                    <button id="down" class="btn" style="color: white; border:unset;padding-right: 5px !important; padding: unset; font-size: 17px;z-index: 0;background: none;" (click)="up('100',item)" ><span class="glyphicon glyphicon-plus" ></span></button>
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-xs-6 col-md-6" >
              <button class="btn " style="background-color: #EE562F;color: white;" (click)="open(Addon,item)"> ADD TO CART</button>
          </div>
            </div>
        </div>
        </div>
      
    </div>
 
</div>



<ng-template #Addon let-modal id="" style="width:90%">

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold;">ADD ON</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;"  style="padding-bottom: 10px;" >
    <fieldset>
        <!-- Sign In Form -->
        <!-- Text input-->
        <div class="control-group">

            <div class="controls">
                <textarea required="" id="userid" name="userid"  [(ngModel)]="AddonText" width="200" style="height: 200px;" class="form-control " placeholder=""></textarea>
             
            </div>
        </div>


        <!-- Button -->
        <div class="control-group">
            <label class="control-label" for="signin"></label>
            <div class="controls" style="    text-align: center;">
                <button id="signin" class="btn " style="background-color:#EE562F;color:white" (click)="AddonCartitems('Addon')" >Submit</button>
            </div>
        </div>

    </fieldset>
  
    </div>
    </div>

  
   
  
  </ng-template>