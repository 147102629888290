<title>Terms & Conditions</title>
<div style="padding: 10px 20px 0px 60px ;"><a href="/"><img src="../../assets/Images/Homeicon.png"> Home </a> &nbsp;/&nbsp;<span
        style="font-family: Ubuntu-Regular !important;">Terms & Conditions</span></div>
<div class="container" style="padding-top: 30px;">

    <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;">Terms & Conditions</h4>
    <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">1.Terms of Use</h4>
    <p style="font-size: 16px; text-align:justify ;">This Terms and Conditions Agreement sets forth the standards of use
        of the Food Delivery service, (“Zapata”) Online Service. By using the www.Zapata.com website (the ”User”) agree
        to these terms and conditions. If you do not agree to the terms and conditions of this Agreement, you should
        immediately cease all usage of this website. We reserve the right, at any time, to modify, alter, or update the
        terms and conditions of this agreement without prior notice. Modifications shall become effective immediately
        upon being posted at www.Zapata.com website. Your continued use of the Service after amendments are posted
        constitutes acknowledgement and acceptance of the Agreement and its modifications. Except as provided in this
        paragraph, this Agreement may not be amended.
    </p><br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">2.Registration</h4>
    <p style="font-size: 16px; text-align:justify ;">By signing up to our website you are agreeing to the terms and
        conditions of sale which apply to all transactions on www.Zapata.com
        Signing up to the service means we must have the following information:
    </p><br>
    <ul>
        <li>Your address, including the postcode.</li>
        <li>Your home telephone number or mobile telephone number.</li>
        <li>Your email address, so we can supply you with important information such as your order confirmation and
            delivery details</li>
    </ul>

    <p style="font-size: 16px; text-align:justify ;">You may access the Services either by (a) registering to create an
        account and become a member </p>
    <p style="font-size: 16px; text-align:justify ;">You agree to provide accurate, current and complete information
        during the registration process and to update such information to keep it accurate, current and complete.</p>
    <p style="font-size: 16px; text-align:justify ;">We reserve the right to suspend or terminate your Zapata Account
        and your access to the Services if any information provided during the registration process or thereafter be
        inaccurate, not current or incomplete.</p><br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">3.Product prices</h4>
    <p style="font-size: 16px; text-align:justify ;">The price of goods charged will be as quoted on the web site at the
        time you confirm your order with us. Excluding any inadvertent technical error on pricing, we will honour any
        prices as published at the time of placing your order.</p>
    <p style="font-size: 16px; text-align:justify ;">Due to differing product promotions the prices displayed on the
        website may vary to original prices. You agree to pay all fees and charges incurred in connection with your
        purchases, including any applicable taxes. The final charges for your order may be different than those stated
        on the website.</p>
        <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">4.Ordering</h4>
    <p style="font-size: 16px; text-align:justify ;">Any contract for the supply of Food Delivery from this Website is
        between you and the Participating Restaurant; for the supply of Food Services Delivery from this Website and App
        any contact is between you and Zapata. You agree to take particular care when providing us with your details and
        warrant that these details are accurate and complete at the time of ordering.</p>
    <p style="font-size: 16px; text-align:justify ;">Any order that you place with us is subject to product
        availability, delivery capacity and acceptance by us and the Participating Restaurant. When you place your order
        online, we will send you a message to confirm that we have received it. You must inform us immediately if any
        details are incorrect. Once we have sent a confirmation message we will check availability.</p>
    <p style="font-size: 16px; text-align:justify ;">If the ordered Food Delivery and delivery capacity is available, If
        the details of the order are correct, the contract for the Food Delivery will be confirmed by text message (SMS)
        or Call.</p>
    <p style="font-size: 16px; text-align:justify ;">Once the ordered food is ready to deliver, The confirmation message
        will specify delivery details including the approximate delivery time, the price of the Food ordered and the
        name of delivery executive.</p>
    <p style="font-size: 16px; text-align:justify ;">If the Food is not available or if there is no delivery capacity,
        we will also let you know by text message (SMS) or phone call.</p>
        <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">5.Delivery</h4>
    <p style="font-size: 16px; text-align:justify ;">Delivery period quoted at the time of ordering are approximate only
        and may vary. Food will be delivered to the address as intimated by you while ordering.</p>
    <p style="font-size: 16px; text-align:justify ;">If the Food is not delivered within the estimated delivery time
        quoted, User shall contact Zapata by email or support number and Zapata will try to ensure that you receive your
        order as quickly as reasonably possible.</p>
    <p style="font-size: 16px; text-align:justify ;">If you fail to accept delivery of Food at the time they are ready
        for delivery or Zapata is unable to deliver at the nominated time due to your failure to provide appropriate
        instructions or authorizations, then the Food shall be deemed to have been delivered to you and all risk and
        responsibility in relation to such Food shall pass to you. Any storage, insurance and other costs which Zapata
        incur as a result of the inability to deliver shall be your responsibility and you shall indemnify Zapata in
        full for such cost.</p>
    <p style="font-size: 16px; text-align:justify ;">You must understand and accept that it might not be possible to
        deliver to some locations. In such cases, Zapata will inform you accordingly and arrange for cancellation of the
        order or delivery to any alternative delivery address.</p>
        <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">6.Cancellations and Refunds</h4>
    <p style="font-size: 16px; text-align:justify ;">You must notify Zapata immediately if you decide to cancel your
        order, preferably by phone, and quote your order number.</p>
    <p style="font-size: 16px; text-align:justify ;">In the unlikely event that the Participating Restaurant delivers a
        wrong item, you have the right to reject the delivery of the wrong item and you shall be fully refunded for the
        missing item.</p>
        <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">7.Limitation of Liability</h4>
    <p style="font-size: 16px; text-align:justify ;">Great care has been taken to ensure that the information available
        on this App or Website is correct and error free. We apologize for any errors or omissions that may have
        occurred. We cannot warrant that use of the App and the Website will be error free or fit for purpose, timely,
        that defects will be corrected, or that the site or the server that makes it available are free of viruses or
        bugs or represents the full functionality, accuracy, reliability of the App and theWebsite and we do not make
        any warranty whatsoever, whether express or implied, relating to fitness for purpose, or accuracy.</p>
    <p style="font-size: 16px; text-align:justify ;">By accepting these Terms of Use you agree to relieve us from any
        liability whatsoever arising from your use of information from any third party, or your use of any third party
        website, or your consumption of any food or beverages from a Participating Restaurant.</p>
    <p style="font-size: 16px; text-align:justify ;">We shall not be held liable for any failure or delay in delivering
        Food where such failure arises as a result of any act or omission, which is outside our reasonable control such
        as all overwhelming and unpreventable events caused directly and exclusively by the forces of nature that can be
        neither anticipated, nor controlled, nor prevented by the exercise of prudence, diligence, and care, including
        but not limited to: war, riot, civil commotion; compliance with any law or governmental order, rule, regulation
        or direction and acts of third parties.</p>
    <p style="font-size: 16px; text-align:justify ;">We have taken all reasonable steps to prevent Internet fraud and
        ensure any data collected from you is stored as securely and safely as possible. However, we cannot be held
        liable in the extremely unlikely event of a breach in our secure computer servers or those of third parties.</p>
        <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">8.General</h4>
    <p style="font-size: 16px; text-align:justify ;">We may subcontract any part or parts of the Services that we
        provide to you from time to time and we may assign or novate any part or parts of our rights under these Terms
        and Conditions without your consent or any requirement to notify you.</p>
    <p style="font-size: 16px; text-align:justify ;">We may alter or vary the Terms and Conditions at any time without
        notice to you.</p>
    <p style="font-size: 16px; text-align:justify ;">Do not collect or harvest any personally identifiable information
        from the website, use communication systems provided by the website for any commercial solicitation purposes,
        solicit for any reason whatsoever any users of the website with respect to their submissions to the website, or
        publish or distribute any vouchers or codes in connection with the website, or scrape or hack the website.</p>
    <p style="font-size: 16px; text-align:justify ;">The Terms and Conditions together with the Privacy Policy, any
        order form and payment instructions constitute the entire agreement between you and us. No other terms whether
        expressed or implied shall form part of this Agreement. In the event of any conflict between these Terms and
        Conditions and any other term or provision on the Website, these Terms and Conditions shall prevail.</p>
    <p style="font-size: 16px; text-align:justify ;">These Terms and Conditions and our Agreement shall be governed by
        and construed in accordance with the laws of US North America The parties hereto submit to the exclusive
        jurisdiction of the courts of US North America</p>
    <p style="font-size: 16px; text-align:justify ;">No delay or failure on our part to enforce our rights or remedies
        under the Agreement shall constitute a waiver on our part of such rights or remedies unless such waiver is
        confirmed in writing.</p>
    <p style="font-size: 16px; text-align:justify ;">8 These Terms and Conditions and a contract (and all
        non-contractual obligations arising out of or connected to them) shall be governed and construed in accordance
        with US North America Laws. Both we and you hereby submit to the non-exclusive jurisdiction of the US North
        America Courts. All dealings, correspondence and contacts between us shall be made or conducted in the English
        language.</p>

</div><br>