<div style="padding-top: 0px;">
    <img src="../../assets/Images/About-usBanner.jpeg" class="center-block" style="width:100% !important">
</div>
<div style="padding: 10px 20px 0px 60px ;"><a href="/"><img src="../../assets/Images/Homeicon.png">Home </a> &nbsp;/&nbsp;<span
    style="font-family: Ubuntu-Regular !important;">About Us</span></div>
<div class="container" style="padding-top: 50px;">

    <div class="row">
        <!-- <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <img src="../../assets/Images/About-usBanner.jpg" class="center-block">
        </div> -->
        <div class="col-lg-7 col-xs-12 col-sm-12 col-xs-12">
            <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;">About Zapata</h4><br>
            <p style="font-size: 16px; text-align:justify ;">
                &nbsp;&nbsp;&nbsp;The Zapata Food is the leading Mexican Restaurant in United States. Zapata allows
                users to conveniently
                discover food around their neighbourhood and directly order their favourite meals online or via mobile.
                In
                the philippines, Zapata has since grown to over resturant nationwide.
            </p><br>
            <p style="font-size: 16px; text-align:justify ;">
                &nbsp;&nbsp;&nbsp;We are a small team of problem solvers, designers, thinkers and tinkers, working
                around the clock to
                make
                foodpanda the most powerful online tool for food delivery. We believe that ordering food should be easy,
                fast and definitely fun!
            </p><br>
            <p style="font-size: 16px; text-align:justify ;">
                &nbsp;&nbsp;&nbsp; Tried of the same take-out for lunch? Too lazt to go out for dinner? Simply download
                the Zapata app or
                go to
                www.zapata.com, and enter your location to see wich resturants deliver to you. Food delivery has never
                been
                easier!
            </p>
        </div>
        <div class="col-lg-5  col-xs-12 col-sm-12 col-xs-12">
            <img src="../../assets/Images/About-usImage.jpeg" class="center-block">

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12  col-xs-12 col-sm-12 col-xs-12">
            <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;">Our Values</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4  col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">Resilience</h5>
            <p style="font-size: 16px; text-align:justify ;">We push ourselves beyond our abilities when faced with
                tough times. When we foresee uncertainly, we address it only with flexibility. </p>
        </div>
        <div class="col-lg-4  col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">Acceptance</h5>
            <p style="font-size: 16px; text-align:justify ;">
                Feedback is never taken personally, we break it into positive pieces and strive to work on each and
                every element event more effectively.
            </p>
        </div>
        <div class="col-lg-4  col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">Ownership</h5>
            <p style="font-size: 16px; text-align:justify ;">
            People here don't work 'for' Zomoto, they work 'with' Zomoto. We treat every problem as our own, take accountability and drive the change.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4  col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">Humility</h5>
            <p style="font-size: 16px; text-align:justify ;">
            It's always 'us' over 'me'. We don't lose ourselves in pride or confidence during individual successes, but focus on being our simple selves in every which way.</p>
        </div>
        <div class="col-lg-4  col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">Spark</h5>
            <p style="font-size: 16px; text-align:justify ;">
            We belive in, stand for and are evangelists of our culture - both, Within Zomoto and externally with all our stakeholder.</p>
        </div>
        <div class="col-lg-4  col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">Judgement</h5>
            <p style="font-size: 16px; text-align:justify ;">
            It's not our abilities that show who we truly are - it's our choice, We aim to get these right, at least in the majority of the cases.
            </p>
        </div>
    </div>
    <br>
</div>