
<title>Check-Out</title>

<div>



    <div class="container-fluid" style="padding-top:80px;padding-left:30px;padding-bottom:50px;">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12 col-md-12">
            <div class="col-lg-1"></div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 center-block"*ngIf="Deliver" style="border:1px solid rgba(111,111,111,0.5);padding:60px;">
                <h4 style="padding:20px;font-family:Ubuntu-Medium !important;text-align:center">To place your Order now,log in to your existing account  or SignUp </h4>
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                    <div class="col-lg-1">

                    </div>
                    <div class="col-lg-5 col-md-3 col-sm-6 colxs-6 " style="padding:unset;">
                        <button class="btn" style="border:1px solid #EE562F;width:73%;background:white;height:50px;color:#EE562F;font-size:18px"  (click)="open(Login)">LOGIN</button>
                        <h5 style="font-family:Ubuntu-Medium !important">Already Have a Account ?</h5>
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-5 col-md-3 col-sm-6 colxs-6 " style="padding:unset;">
                        <button class="btn" style="background:#EE562F;width:73%;height:50px;color:white;font-size:18px"(click)="open(SignUp)">SIGNUP</button>
                        <h5 style="font-family:Ubuntu-Medium !important">New to Zapata ? </h5>
                    </div>
                    <div class="col-lg-3">

                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="padding-top: 30px;">
                    <h4 style="text-align: center;padding-top: 40px;font-family: Ubuntu-Medium;" (click)="open(Guest)">or Continue as <b style="color: #EE562F;font-size: 23px;">GUEST</b> </h4>
                </div>
              
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 center-block"  *ngIf="Deliverlo" style="border:1px solid rgba(111,111,111,0.5);padding:10px;">
                <h4 style="padding:20px;font-family:Ubuntu-Regular !important;font-size:18px;text-align:center">How Do you want your Order ?</h4>
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-bottom: 20px;">
                    <div class="col-lg-2">

                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 colxs-6 " style="padding:unset;text-align: center;">
                        <button class="btn" style="border:1px solid black;width:100%;background:white;height:50px;color:black;font-size:18px;font-family: Ubuntu-Bold !important;" (click)="Delivery()">DELIVERY</button>
                        
                    </div>
                    <div class="col-lg-1" style="padding-top: 18px;"> OR </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 colxs-6 " style="padding:unset;">
                        <button class="btn" style="background:#EE562F;width:73%;height:50px;color:white;font-size:18px;font-family: Ubuntu-Bold !important"  (click)="CarryOut()">CARRY OUT</button>
                    
                    </div>
                    <div class="col-lg-3">

                    </div>
                </div>
              
              
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 center-block" *ngIf="DeliverAdd" style="  border:1px solid rgba(111,111,111,0.5);padding:unset;">
                
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:20px;border-bottom:1px dashed" >
                    <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6" *ngFor='let items of EditAddress' style="border-right:1px dashed">
                        <h3>Delivery Address</h3>
                        <div class=""  >
                            <div class="" style="border: 1px solid rgba(111,111,111,0.5);padding: 15px; border-radius: 8px;  height: 200px;;">
                            <p style="margin-bottom:unset;font-size: 21px;color: #555;">{{items.name}} {{items.lastName}}</p>
                            <p style="margin-bottom:unset;font-size: 21px;color: #555">{{items.addLine1}} {{items.addLine2}}</p>
                            <p style="margin-bottom:unset;font-size: 21px;color: #555">{{items.city}} {{items.state}}, {{items.zip}}</p>
                        </div>
        <div class="col-lg-6 col-md-6 col-xs-5 col-sm-5" style="padding-top: 25px;">
            <h4 (click)="open3(ChangeAddress,items.deliveryId)"  style= "cursor: pointer; text-align:right;padding-top:20px;color: #337ab7; text-decoration: none;padding-bottom:20px ;font-size: 18px;"   >Change Address?</h4>

          
        </div>
        
                               
                            
        
                     
                    </div>
                 
                </div>
                <div class="col-lg-5 col-sm-6 col-md-6 col-xs-6 center-block" style="padding-top:80px">
                    <button class="btn center-block" style="background:#EE562F;color:white;width:73%;height:50px;"  (click)="open(AddNewAddress)"  >ADD NEW</button>
                </div>
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:20px;padding-bottom:20px">
                    <button class="btn " style="background:#EE562F;color:white;border-radius:32px;float:right;    width: 25%;height: 50px;" (click)="Checkadd(Payout)" > CHECKOUT <i class="fa fa-shopping-basket"></i> </button>
                </div>
            </div>
        </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 center-block" *ngIf="FillAdd"   style="border:1px solid rgba(111,111,111,0.5)">
                
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:51px;border-bottom:1px solid;padding-left: unset ;padding-right: unset;" >
                    <div class="col-lg-5 col-sm-6 col-md-6 col-xs-6" >
                       <h4 style="text-align: center;font-size: 22px;font-family: Ubuntu-regular !important;">Select your Preference</h4>
                    </div>
                    <div class="col-lg-7 col-sm-6 col-md-6 col-xs-6 center-block"  style="padding: unset;">
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-left:unset ;padding-right: unset;">
                            <div class="radio-item" >
                                <input type="radio" id="ritema3" name="ritem1" (click)="Imedia('Immediate')" value="ropt2">
                                <label for="ritema3" style="font-size: 22px;">  Immediate </label>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-left:unset ;padding-right: unset;padding-top: 20px;">
                            <div class="radio-item" >
                                <input type="radio" id="ritema4" name="ritem1"  (click)="Imedia('Later')" value="ropt2">
                                <label for="ritema4" style="font-size: 22px;">  Later <span  style="font-size:16px;font-weight:unset;font-family:Ubuntu-Regular !important">(30 min before resturant closing time)</span> </label>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-left:unset ;padding-right: unset;padding-top: 35px;padding-bottom: 35px;">
                           
                                <label for="ritema4" style="font-size: 22px;padding-left: 25px;color: #666;;"> Select Carryout  Time <input type="time"  min={{stattime}} max={{endtime}} [(ngModel)]="SelectTime" style="border: none;"> </label>
                            
                        </div>
                        <div>
                            <span *ngIf="Alertshow" style="color: red;" >{{AlertMsg}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:51px;padding-bottom: 50px;padding-left: unset ;padding-right: unset;" >
                    <div class="col-lg-5 col-sm-6 col-md-6 col-xs-6" >
                       <!-- <h4 style="text-align: center;font-size: 22px;font-family: Ubuntu-regular !important;">Fill the Followig Fields</h4> -->
                    </div>
                    <div class="col-lg-7 col-sm-6 col-md-6 col-xs-6 center-block"  style="padding: unset;">
                        <!-- <div class="col-lg-12 col-xs-12 col-md-6 col-sm-12" style="padding-left:unset ;padding-right: unset;    padding-top: 10px;">
                          <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6 ">
                              <input type="text" placeholder="First Name" [(ngModel)]="CarryFillFName" class="form-control" style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">

                          </div>
                          <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
                            <input type="text" placeholder="Last Name" class="form-control" [(ngModel)]="CarryFillLName"  style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">
                            
                        </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12 " style="padding-top: 25px;">
                            <input type="text" class="form-control" placeholder="Email Id (Optional)" [(ngModel)]="CarryFillEmail" style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">

                        </div>
                        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top: 25px;">
                            <input type="number" class="form-control" placeholder="Mobile Number" [(ngModel)]="CarryFillMobile" style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">
                            
                        </div>
                        <div style="padding-top: 15px;">
                        <div class="radio-item " >
                            <input type="radio" id="ritema6" name="ritem3" value="ropt3">
                            <label for="ritema6" style="font-size:18px ;">I Accept <span style="color: #337ab7;font-size:18px ;"  [routerLink]="'/TermsCondition'"> Terms & Condition </span> </label>
                        </div>
                    </div> -->
                    <div class="radio-item " >
                        <input type="radio" id="ritema6" name="ritem3" value="terms">
                        <label for="ritema6" style="font-size:18px ;">I Accept <span style="color: #337ab7;font-size:18px ;"  [routerLink]="'/TermsCondition'"> Terms & Condition </span> </label>
                    </div>
                        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top: 25px;">
                            <button class="btn center-block" style="height: 61px; font-family: Ubuntu-Medium !important; background:#EE562F;color:white;width:100%;height:50px;border-radius:30px;font-size: 20px ;"  (click)="CarreyOut(CarryPayout)" >PROCEED TO CHECKOUT</button>
                        </div>
                       
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 center-block" *ngIf="DeliveryStatus"   style="border:1px solid rgba(111,111,111,0.5)">
                
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:51px;border-bottom:1px solid;padding-left: unset ;padding-right: unset;" >
                    <div class="col-lg-5 col-sm-6 col-md-6 col-xs-6" >
                       <h4 style="text-align: center;font-size: 22px;font-family: Ubuntu-regular !important;">Select your Preference</h4>
                    </div>
                    <div class="col-lg-7 col-sm-6 col-md-6 col-xs-6 center-block"  style="padding: unset;">
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-left:unset ;padding-right: unset;">
                            <div class="radio-item" >
                                <input type="radio" id="ritema13" name="ritem1" (click)="Imedia('Immediate')" value="ropt2">
                                <label for="ritema13" style="font-size: 22px;">  Immediate </label>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-left:unset ;padding-right: unset;padding-top: 20px;">
                            <div class="radio-item" >
                                <input type="radio" id="ritema15" name="ritem1"  (click)="Imedia('Later')" value="ropt2">
                                <label for="ritema15" style="font-size: 22px;">  Later <span  style="font-size:16px;font-weight:unset;font-family:Ubuntu-Regular !important">(30 min before resturant closing time)</span> </label>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-left:unset ;padding-right: unset;padding-top: 35px;padding-bottom: 35px;">
                           
                                <label for="ritema7" style="font-size: 22px;padding-left: 25px;color: #666;;"> Select Delivery Time <input type="time" min={{stattime}} max={{endtime}} [(ngModel)]="DeliveryTime" style="border: none;"> </label>
                            
                        </div>
                        <div>
                            <span *ngIf="Alertshow" style="color: red;" >{{AlertMsg}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:51px;padding-bottom: 50px;padding-left: unset ;padding-right: unset;" >
                    <div class="col-lg-5 col-sm-6 col-md-6 col-xs-6" >
                       <!-- <h4 style="text-align: center;font-size: 22px;font-family: Ubuntu-regular !important;">Fill the Followig Fields</h4> -->
                    </div>
                    <div class="col-lg-7 col-sm-6 col-md-6 col-xs-6 center-block"  style="padding: unset;">
                        <!-- <div class="col-lg-12 col-xs-12 col-md-6 col-sm-12" style="padding-left:unset ;padding-right: unset;    padding-top: 10px;">
                          <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6 ">
                              <input type="text" placeholder="First Name" [(ngModel)]="CarryFillFName" class="form-control" style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">

                          </div>
                          <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
                            <input type="text" placeholder="Last Name" class="form-control" [(ngModel)]="CarryFillLName"  style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">
                            
                        </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12 " style="padding-top: 25px;">
                            <input type="text" class="form-control" placeholder="Email Id (Optional)" [(ngModel)]="CarryFillEmail" style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">

                        </div>
                        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top: 25px;">
                            <input type="number" class="form-control" placeholder="Mobile Number" [(ngModel)]="CarryFillMobile" style="height:40px;border:1px solid;font-family: Ubuntu-Medium !important">
                            
                        </div>
                        <div style="padding-top: 15px;">
                        <div class="radio-item " >
                            <input type="radio" id="ritema6" name="ritem3" value="ropt3">
                            <label for="ritema6" style="font-size:18px ;">I Accept <span style="color: #337ab7;font-size:18px ;"  [routerLink]="'/TermsCondition'"> Terms & Condition </span> </label>
                        </div>
                    </div> -->
                    <div class="radio-item " >
                        <input type="radio" id="ritema18" name="ritem18" value="terms">
                        <label for="ritema18" style="font-size:18px ;">I Accept <span style="color: #337ab7;font-size:18px ;"  [routerLink]="'/TermsCondition'"> Terms & Condition </span> </label>
                    </div>
                        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top: 25px;">
                            <button class="btn center-block" style="height: 61px; font-family: Ubuntu-Medium !important; background:#EE562F;color:white;width:100%;height:50px;border-radius:30px;font-size: 20px ;"  (click)="DeliveryChecStatus()" >PROCEED TO CHECKOUT</button>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div class="col-lg-1"></div>
            <div style="padding-left:20px;">
                <div class="col-lg-4 col-sm-12 col-md-4 col-xs-12" style="border:1px solid rgba(111,111,111,0.5);padding:unset;right:30px">

                    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="background:rgba(111,111,111,0.5);padding:10px">
                        <h4 style="padding-left:20px;font-family:Ubuntu-Bold !important ;">Order Details</h4>
                    </div>
                    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding:5px;border-bottom: 1px solid rgba(111,111,111,0.5);">
                        <h4 style="padding-left:20px;font-size: 18px;font-family:Ubuntu-Bold !important;"><i class="fa fa-map-marker " style="padding-right: 10px;"></i> {{location}}</h4>
                    </div>
                    <div class="row" style="padding-left: 15px;padding-right: 14px;">
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px" *ngFor="let item of Addtocart" >
                            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="font-family:Ubuntu-Bold !important;">
                                <h5  style="font-family:Ubuntu-Bold !important;">{{item.itemName}}</h5>
                             <div class="fa fa-pencil">   <input type="text" style="outline: none;border: none;" [(ngModel)]="item.Addon"></div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2" style="font-family:Ubuntu-Bold !important;">
                                <h5  style="font-family:Ubuntu-Bold !important;">x{{item.Quan}}</h5>
                            </div>
                            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;font-family:Ubuntu-Bold !important;text-align: center;">
                                <h5  style="font-family:Ubuntu-Bold !important;">$ {{item.couponType=="P" ? ( item.Quan * (item.price ) | number:'.2') :( item.Quan * (item.price )  | number:'.2' )}}</h5>
                              
                            </div>
                            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="cursor: pointer; padding-top:8px;font-family:Ubuntu-Bold !important;text-align: right;">
                                <i class="fa fa-trash"  (click)="RemoveOpen(Addon,item)"></i>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px">
                            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                                <h5 style="font-family:Ubuntu-Bold !important;">Discount </h5>
                            </div>
                            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                                <h5></h5>
                            </div>
                            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                                <h5 style="font-family:Ubuntu-Bold !important;"> - $ {{Discount | number:'.2'}}</h5>
                            </div>
                            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom: 1px dashed; padding-bottom: 10px;" >
                            <div class="col-lg-2 col-md-2 col-xs-4 col-sm-4">
                                <h5 style="font-family:Ubuntu-Bold !important;">Coupon </h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-4 col-sm-4">
                                <div class="col-lg-9 col-md-9 col-xs-9 col-sm-9">
                                    <input type="text"  class="form-control" [(ngModel)]="Couponcode">
                                </div>
                                <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="background-color: #EE562F;    padding-top: 2px;margin-top: 5px;">
                                    <i  style="color: white;padding-top: 10px;font-family:Ubuntu-Bold !important;    margin-left: -13px;height: 30px;cursor: pointer;" (click)="CouponAvail()">Apply</i>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                                <h5 style="font-family:Ubuntu-Bold !important;">$ {{CouponAmt | number:'.2'}}</h5>
                            </div>
                            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom: 10px;border-bottom: 1px dashed;">
                            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                                <h5 style="font-family:Ubuntu-Bold !important;">SubTotal </h5>
                            </div>
                            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                                <h5></h5>
                            </div>
                            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                                <h5 style="font-family:Ubuntu-Bold !important;">$ {{price | number:'.2'}}</h5>
                            </div>
                            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;">
                            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                                <h5 style="font-family:Ubuntu-Bold !important;">Delivery </h5>
                            </div>
                            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                                <h5></h5>
                            </div>
                            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                                <h5 style="font-family:Ubuntu-Bold !important;">$ {{DeliveryAmt | number:'.2'}}</h5>
                            </div>
                            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom:1px dashed">
                            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                                <h5 style="font-family:Ubuntu-Bold !important;">Tax </h5>
                            </div>
                            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                                <h5></h5>
                            </div>
                            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                                <h5 style="font-family:Ubuntu-Bold !important;">$ {{Taxamt | number:'.2'}}</h5>
                            </div>
                            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                            </div>
                        </div>
                      
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;">
                            <div class="col-lg-9 col-md-9 col-xs-9 col-sm-9">
                                <h5 style="font-family:Ubuntu-Bold !important;color: #EE562F;">Tip Amount (optional) </h5>
                          <table>
                              <tr>
                                  <td style="width: 24%;padding-right: 15px;text-align: center;" *ngFor="let item of TipMaster">
                                      <div  style="background-color: #F4650C;cursor: pointer;" (click)="TippEnter(item.tipsPercentage)">
                                        <p style="color: white;">{{item.tipsPercentage}}%</p>
                                    </div>
                                     
                                  </td>
                                
                              </tr>
                              <tr>
                               
                            </tr>
                          </table>
                            </div>
                           
                            <div class="col-lg-2 col-md-2 col-xs-3 col-sm-3" style="text-align: center;padding: unset;">
<span class="input-euro left">                              
      <input type="number" style="font-family:Ubuntu-Bold !important;color: #EE562F;    width: 150%;  height: 37px;"  [(ngModel)]="TipAmt "  min=0 oninput="validity.valid||(value='');" (change)="TipsPenter()"/>
</span>
                            
</div>
                            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                            </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:15px;padding-bottom:50px ;">
                            
                            <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" >
                                <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="padding: unset;">
                                    <h5 style="font-family:Ubuntu-Bold !important;">Total Due </h5>
                                </div>
                                <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                                    <h5></h5>
                                </div>
                                <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4" style="padding-right: unset;text-align: center;">
                                    <h5 style="color:#20c500 ;font-family:Ubuntu-Bold !important;">$ {{NetBalance | number:'.2'}}</h5>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
        </div>

    </div>

    
  
    <div class="modal fade bs-modal-md" id="myModal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style="padding-top:60px">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <br>
                <div class="bs-example bs-example-tabs">
                    <ul id="myTab" class="nav nav-tabs">
                        <li class="active"><a href="#signin" data-toggle="tab" target="_self">Delivery Address</a></li>

                    </ul>
                </div>
                <div class="modal-body">
                    <div id="myTabContent" class="tab-content">

                        <div class="tab-pane fade active in" id="signin">
                            <form class="form-horizontal">
                                <fieldset>
                                    <!-- Sign In Form -->
                                    <!-- Text input-->
                                    <div class="control-group">

                                        <div class="controls">
                                            <input required="" id="userid" name="userid" type="text" class="form-control " placeholder="Enter your Frist Name ">
                                        </div>
                                    </div>
                                    <!-- Password input-->
                                    <div class="control-group" style="padding-top:20px">

                                        <div class="controls">
                                            <input required="" id="passwordinput" name="passwordinput" class="form-control" type="text" placeholder="Enter Your Last Name">
                                        </div>
                                    </div>
                                    <div class="control-group" style="padding-top:20px">

                                        <div class="controls">
                                            <input required="" id="passwordinput" name="passwordinput" class="form-control" type="text" placeholder="Enter Your Contact Number">
                                        </div>
                                    </div>
                                    <div class="control-group" style="padding-top:20px">

                                        <div class="controls">
                                            <input required="" id="passwordinput" name="passwordinput" class="form-control" type="text" placeholder="Address Line1">
                                        </div>
                                    </div>
                                    <div class="control-group" style="padding-top:20px">

                                        <div class="controls">
                                            <input required="" id="passwordinput" name="passwordinput" class="form-control" type="text" placeholder="Address Line2">
                                        </div>
                                    </div>
                                    <div class="control-group" style="padding-top:20px">

                                        <div class="controls">
                                            <input required="" id="passwordinput" name="passwordinput" class="form-control" type="text" placeholder="City">
                                        </div>
                                    </div>
                                    <div class="control-group" style="padding-top:20px">

                                        <div class="controls">
                                            <input required="" id="passwordinput" name="passwordinput" class="form-control" type="text" placeholder="State">
                                        </div>
                                    </div>
                                    <div class="control-group" style="padding-top:20px">

                                        <div class="controls">
                                            <input required="" id="passwordinput" name="passwordinput" class="form-control" type="text" placeholder="Zip Code">
                                        </div>
                                    </div>
                                    <!-- Button -->
                                    <div class="control-group">
                                        <label class="control-label" for="signin"></label>
                                        <div class="controls" style="    text-align: center;">
                                            <button id="signin" name="signin" class="btn " style="background-color:#F4650C;color:white">Submit</button>
                                        </div>
                                    </div>

                                </fieldset>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal style="padding-top: 25%;">

  <div class="modal-header">

    <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">×</span>

    </button>

  </div>

  <div class="modal-body">

    This is example from ItSolutionStuff.com

  </div>

  <div class="modal-footer">

    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>

  </div>

</ng-template>


  <ng-template #Payout let-modal >

    <div class="modal-header" style="border: none;padding-bottom: unset;">
  <h4 style="font-size: 18px;padding-left: 25px;font-family: Ubuntu-Bold !important;"><i class="fa fa-map-marker">  {{location}}</i> </h4>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body">
 
        <div class="">
            <h4 style="font-family: Ubuntu-Bold !important;padding-left: 25px">Delivery Address</h4>
        </div>
  <div class="" style="padding-top: 10px;padding-left: 25px;padding-bottom: 20px;">
      <span style="font-size: 15px;">{{AddFirstName}}  </span>
  <br/>
  <span  style="font-size: 15px;">{{AddAddLine1}} {{AddLine2}}</span>
  <br/>
  <span  style="font-size: 15px;">{{AddCity}},{{AddState}} {{AddZip}}</span>
  </div>
    <div class="" style="border-top: 1px solid rgba(111,111,111,0.5);"></div>
  

    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding:10px">
        <h4 style="padding-left:20px;font-family:Ubuntu-Bold !important ;">Order Details</h4>
    </div>
    <div class="row" style="padding-left: 15px;padding-right: 14px;">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;" *ngFor="let item of Addtocart">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="font-family:Ubuntu-Bold !important;">
                <h5  style="font-family:Ubuntu-Bold !important;">{{item.itemName}} </h5>
                <span>{{item.Addon}}</span>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2" style="font-family:Ubuntu-Bold !important;">
                <h5  style="font-family:Ubuntu-Bold !important;">x{{item.Quan}}</h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;font-family:Ubuntu-Bold !important;text-align: center;">
                <h5  style="font-family:Ubuntu-Bold !important;">$ {{item.couponType=="P" ? ( item.Quan * (item.price -(item.couponAmt/100)) | number:'.2') :( item.Quan * (item.price -(item.couponAmt))  | number:'.2' )}}</h5>
            </div>
        </div>

       
        <!-- <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Discount </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;"> - $ {{Discount | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div> -->
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom: 1px dashed; padding-bottom: 10px;" >
            <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
                <h5 style="font-family:Ubuntu-Bold !important;">Applied Coupon </h5>
            </div>
           
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{CouponAmt | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom: 10px;border-bottom: 1px dashed;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">SubTotal </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{price | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Delivery </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{DeliveryAmt | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom:1px dashed">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Tax </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{Taxamt | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom: 20px;">
            <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
                <h5 style="font-family:Ubuntu-Bold !important;color: #EE562F;">Tip Amount (Optional) </h5>
                <table>
                    <tr>
                        <td style="width: 24%;padding-right: 15px;text-align: center;" *ngFor="let item of TipMaster">
                            <div  style="background-color: #F4650C;cursor: pointer;" (click)="TippEnter(item.tipsPercentage)">
                              <p style="color: white;">{{item.tipsPercentage}}%</p>
                          </div>
                           
                        </td>
                      
                    </tr>
                    <tr>
                     
                  </tr>
                </table>
            </div>
           
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <span class="input-euro left">                              
                    <input type="number" style="font-family:Ubuntu-Bold !important;color: #EE562F;    width: 150%;  height: 37px;"  [(ngModel)]="TipAmt"  min=0 oninput="validity.valid||(value='');" (change)="TipsPenter()"/>
              </span>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:15px;padding-bottom:10px ;border-top: 1px solid rgba(111,111,111,0.5);border-bottom: 1px solid rgba(111,111,111,0.5)">
            
            <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" >
                <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="padding:unset">
                    <h5 style="font-family:Ubuntu-Bold !important;">Total Due </h5>
                </div>
                <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                    <h5></h5>
                </div>
                <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4" style="padding-right: unset;text-align: center;">
                    <h5 style="color:#20c500 ;font-family:Ubuntu-Bold !important;">$ {{NetBalance | number:'.2'}}</h5>
                </div>
              
            </div>
        </div>
        <div class="col-md-12" >
            <!-- <div class="col-md-6">
                <div class="radio-item" >
                    <input type="radio" id="ritema12" name="ritem12" value="Cash" (click)="DeliDelivery('Cash')">
                    <label for="ritema12" style="font-size: 22px;">  Cash on Delivery </label>
                </div>
            </div> -->
            <div class="col-md-12" style="text-align: center;">
                <div class="radio-item" >
                    <input type="radio" id="ritema13" name="ritem12" value="Online"  (click)="DeliDelivery('Online')">
                    <label for="ritema13" style="font-size: 22px;">  Online Payment </label>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12 " style="text-align: center;padding-top: 20px;padding-bottom: 50px;">
            <button class="btn" style="border-radius: 25px; background-color: #EE562F;color: white;width: 52%;font-size: 20PX;font-family:Ubuntu-Bold !important;" (click)="PayNow()" >PAY NOW</button>
        </div>
    </div>

    </div>
  
   
  
  </ng-template>
  <ng-template #ChangeAddress let-modal >

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">Address</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
 <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
    <span [hidden]="isShownErrorChange" style="color: red;" >{{ChangeAddErrorMsg}}</span>
    <span [hidden]="isShownSuccessChange" style="color: green;" >{{ChangeAddSuccessMsg}}</span>
 </div>
  
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
        <div class="form-group" style="padding-top:10px ;">
            <input type="text" placeholder="Name" [(ngModel)]="Name" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Address Line1" [(ngModel)]="AddAddLine1" class="form-control line" style="font-family: Ubuntu-Medium !important;"  />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Address Line2" [(ngModel)]="AddLine2" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="City" [(ngModel)]="AddCity" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="State" [(ngModel)]="AddState" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Zip" [(ngModel)]="AddZip" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        
      
        <div class="form-group" style=" margin-top: 10px; margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;">
           
            <h3 class="" (click)="ChangeAddressClick()" style="font-family: Ubuntu-Medium !important;text-align: center;margin-top: 10px;color: white;">SUBMIT</h3>
        </div>

      
    </div>
  
    </div>
  
   
  
  </ng-template>


  <ng-template #AddNewAddress let-modal >

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">Address</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
 <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
    <span [hidden]="isShownErrorAddnew" style="color: red;" >{{AddnewErrorMsg}}</span>
    <span [hidden]="isShownSuccessAddnew" style="color: green;" >{{AddNewSuccessMsg}}</span>
 </div>
  
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
        <div class="form-group" style="padding-top:10px ;">
            <input type="text" placeholder="Name" [(ngModel)]="AddNewFirstName" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Address Line1" [(ngModel)]="AddNewLine1" class="form-control line" style="font-family: Ubuntu-Medium !important;"  />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Address Line2" [(ngModel)]="AddNewLine2" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="City" [(ngModel)]="AddnewCity" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="State" [(ngModel)]="AddNewState" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="number" placeholder="Zip" [(ngModel)]="AddNewZip" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        
      
        <div class="form-group" style=" margin-top: 10px; margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;">
           
            <h3 class="" (click)="AddressNewClick()" style="cursor: pointer; font-family: Ubuntu-Medium !important;text-align: center;margin-top: 10px;color: white;">SUBMIT</h3>
        </div>

      
    </div>
  
    </div>
  
   
  
  </ng-template>
  <ng-template #CarryPayout let-modal >

    <div class="modal-header" style="border: none;padding-bottom: unset;">
  <h4 style="font-size: 18px;padding-left: 25px;font-family: Ubuntu-Bold !important;"><i class="fa fa-map-marker">  {{location}}</i> </h4>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body">
 
       
 
    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding:10px">
        <h4 style="padding-left:20px;font-family:Ubuntu-Bold !important ;">Order Details</h4>
    </div>
    <div class="row" style="padding-left: 15px;padding-right: 14px;">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;" *ngFor="let item of Addtocart">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="font-family:Ubuntu-Bold !important;">
                <h5  style="font-family:Ubuntu-Bold !important;">{{item.itemName}} </h5>
                <span>{{item.Addon}}</span>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2" style="font-family:Ubuntu-Bold !important;">
                <h5  style="font-family:Ubuntu-Bold !important;">x{{item.Quan}}</h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;font-family:Ubuntu-Bold !important;text-align: center;">
                <h5  style="font-family:Ubuntu-Bold !important;">$ {{item.couponType=="P" ? ( item.Quan * (item.price -(item.couponAmt/100)) | number:'.2') :( item.Quan * (item.price -(item.couponAmt))  | number:'.2' )}}</h5>
            </div>
        </div>

       
        <!-- <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Discount </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;"> - $ {{Discount | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div> -->
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom: 1px dashed; padding-bottom: 10px;" >
            <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
                <h5 style="font-family:Ubuntu-Bold !important;">Applied Coupon </h5>
            </div>
           
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{CouponAmt | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom: 10px;border-bottom: 1px dashed;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">SubTotal </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{price | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Delivery </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{DeliveryAmt | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom:1px dashed">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Tax </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{Taxamt | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
       
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom: 20px;">
            <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
                <h5 style="font-family:Ubuntu-Bold !important;color: #EE562F;">Tip Amount (Optional) </h5>
                <table>
                    <tr>
                        <td style="width: 24%;padding-right: 15px;text-align: center;" *ngFor="let item of TipMaster">
                            <div  style="background-color: #F4650C;cursor: pointer;" (click)="TippEnter(item.tipsPercentage)">
                              <p style="color: white;">{{item.tipsPercentage}}%</p>
                          </div>
                           
                        </td>
                      
                    </tr>
                    <tr>
                     
                  </tr>
                </table>
            </div>
           
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <span class="input-euro left">                              
                    <input type="number" style="font-family:Ubuntu-Bold !important;color: #EE562F;    width: 150%;  height: 37px;"  [(ngModel)]="TipAmt"  min=0 oninput="validity.valid||(value='');" (change)="TipsPenter()"/>
              </span>
             
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:15px;padding-bottom:10px ;border-top: 1px solid rgba(111,111,111,0.5);border-bottom: 1px solid rgba(111,111,111,0.5)">
            
            <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" >
                <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="padding:unset">
                    <h5 style="font-family:Ubuntu-Bold !important;">Total Due </h5>
                </div>
                <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                    <h5></h5>
                </div>
                <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4" style="padding-right: unset;text-align: center;">
                    <h5 style="color:#20c500 ;font-family:Ubuntu-Bold !important;">$ {{NetBalance | number:'.2'}}</h5>
                </div>
              
            </div>
        </div>
        <div class="col-md-12" style="padding: unset;" >
            <div class="col-md-6" style="padding: unset;" >
                <div class="radio-item" style="padding: unset;">
                    <input type="radio" id="ritema10" name="ritem6" value="Cash" (click)="CarryDelivery('Cash')">
                    <label for="ritema10" style="font-size: 22px;">  Pay and Pickup </label>
                </div>
            </div>
            <div class="col-md-6" style="padding: unset;" >
                <div class="radio-item" style="padding: unset;" >
                    <input type="radio" id="ritema11" name="ritem6" value="Online"  (click)="CarryDelivery('Online')">
                    <label for="ritema11" style="font-size: 22px;">  Online Payment </label>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12 " style="text-align: center;padding-top: 20px;padding-bottom: 50px;">
            <button class="btn" (click)="open(onlinepayment)" style="border-radius: 25px; background-color: #EE562F;color: white;width: 52%;font-size: 20PX;font-family:Ubuntu-Bold !important;" >PAY NOW</button>
        </div>
    </div>

    </div>
  
   
  
  </ng-template>
 
  <ng-template #Login let-modal id="" style="width:90%">

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">

        <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold;">LOGIN</h3>

        <button type="button" class="close" aria-label="Close"
            style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;cursor: pointer;"
            (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">×</span>

        </button>

    </div>

    <div class="modal-body" style="padding:35px;">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;" style="padding-bottom: 10px;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-xs-6" style="text-align: right;">
                <button class="btn " (click)="socialSignIn('facebook')"
                    style="background-color: #3b5998;color:white ;border-radius: 20px;text-align: right;cursor: pointer;"><i
                        class="fa fa-facebook" style="padding-right: 10px;"></i>Facebook</button>
            </div>
            <div class="col-lg-6 col-xs-6 col-sm-6 col-md-6">
                <button class="btn " (click)="socialSignIn('google')"
                    style="background-color: #EE562F;color:white ;border-radius: 20px;text-align: center;cursor: pointer;"><i
                        class="fa fa-google" style="padding-right: 10px;"></i>Google</button>
            </div>

        </div>
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <h4 style="border-bottom: 1px solid black;">
                <span
                    style="    background-color: white;  position: relative; top: 10px;left: 45%;padding: 0 10px; font-weight: bold;">Or
                </span>
            </h4>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;">
            <span [hidden]="isShownErrorLogin" style="color: red;">{{LoginErrorMsg}}</span>
            <span [hidden]="isShownSuccessLogin" style="color: green;">{{LoginSuccessMsg}}</span>
        </div>
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <div class="form-group" style="padding-top:10px ;">
                <input type="text" placeholder="Enter Phone No or Email" class="form-control line"
                    [(ngModel)]="UserId" />
            </div>
            <div class="form-group" style="padding-top:10px ;">

                <input type="password" placeholder="Enter your Password" class="form-control line"
                    [(ngModel)]="Password" />
            </div>
            <div class="row" style="padding-top: 10px;">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" id="Instant" style="display: block;">
                    <div class="input-group" style=" padding-bottom:5px;" draggable="true" ondragstart="return false"
                        ondrop="return false">
                        <div class="input-group-addon"
                            style="background-color:#294f91;cursor:pointer;height:0px;cursor: pointer;"
                            (click)="Captcha()">
                            <span id="refresh2"><img style="width:20px; height:20px;"
                                    src="../assets/Images/icons8-refresh-30.png"></span>

                        </div>

                        <input type="text" class="form-control" [(ngModel)]="LoginCaptha"
                            style="margin-bottom: 0px; margin-top: 0px;     width: 100px; color: black; height: 40px; font-size: 16px; border-radius: 2px; font-weight: bolder; display: inline-block; background-color: rgb(255, 255, 255);"
                            id="mainCaptcha" disabled="" draggable="true" ondragstart="return false"
                            ondrop="return false">

                        <div style="margin-top:10px; color: #D8000C; border-radius: 7px;" id="log"></div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-4 col-sm-4 col-xs-12" id="Instant1" style="display: block;">
                    <div class="form-group box" style="padding-left: 22%;">
                        <input type="text" [(ngModel)]="LoginCaptha1"
                            style="border-top:0px;width: 100%; border-left:0px; border-right:0px; border-radius: 0px; border-bottom: 1px solid ;height: 40px; font-size: 14px; font-weight: bolder; display: inline-block;"
                            id="txtInput" placeholder="Enter Captcha" ng-model="Data3.shCaptcha" name="captcha" value=""
                            class="ng-pristine ng-untouched ng-valid ng-empty">
                    </div>
                </div>
            </div>
            <div class="form-group" style="padding-top:10px ;text-align: right; cursor: pointer; ">
                <a (click)="open1('Login',Forget)" style="color: #294f91;">Forgot Password?</a>

            </div>

            <div class="form-group" (click)="loginClick()"
                style="border-radius: 34px;border: 1px solid;background-color: #EE562F;text-align: center;cursor: pointer;">

                <h3 class="" style="text-align: center;margin-top: 10px;color: white;">LOGIN</h3>
            </div>

            <div class="" style="padding-top:15px ;">
                <h5>Don't have Account ?</h5>
            </div>
            <div class="form-group ColorCha"
                style="border-radius: 34px;border: 1px solid;text-align: center;cursor: pointer;">

                <h3 class="" style="text-align: center;margin-top: 10px;" (click)="open1('Login',SignUp)">SIGNUP</h3>
            </div>
            <div class="form-group" style="text-align: center;">

                <h3 class="" style="text-align: center;margin-top: 50px;font-size: 18PX; cursor: pointer;"
                    (click)="open1('Login',Guest)">Or Continue as a <b style="color: black;font-size: 18PX;">GUEST</b>
                </h3>
            </div>
        </div>

    </div>



</ng-template>

<ng-template #SignUp let-modal>

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">

        <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">SIGNUP</h3>

        <button type="button" class="close" aria-label="Close"
            style="outline: none;opacity: 1;font-size: 34px;padding-top: unset; cursor: pointer;"
            (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">×</span>

        </button>

    </div>

    <div class="modal-body" style="padding:35px;">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;" style="padding-bottom: 10px;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-xs-6" style="text-align: right;">
                <button class="btn " (click)="socialSignIn('facebook')"
                    style="background-color: #3b5998;color:white ;border-radius: 20px;text-align: right;cursor: pointer;"><i
                        class="fa fa-facebook" style="padding-right: 10px;"></i>Facebook</button>
            </div>
            <div class="col-lg-6 col-xs-6 col-sm-6 col-md-6">
                <button class="btn " (click)="socialSignIn('google')"
                    style="background-color: #EE562F;color:white ;border-radius: 20px;text-align: center;cursor: pointer;"><i
                        class="fa fa-google" style="padding-right: 10px;"></i>Google</button>
            </div>

        </div>
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <h4 style="border-bottom: 1px solid black;">
                <span
                    style="    background-color: white;  position: relative; top: 10px;left: 45%;padding: 0 10px; font-weight: bold;">Or
                </span>
            </h4>
        </div>
        
    </div>
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
        <div class="form-group" style="padding-top:10px ;">
            <input type="text" placeholder="First Name" [(ngModel)]="SignUpFirstName" class="form-control line"
                style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">

            <input type="text" placeholder="Last Name" [(ngModel)]="SignUpLastName" class="form-control line"
                style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">

            <input type="text" placeholder="Email ID (Optional) " [(ngModel)]="SignUpEmail" [email]="true"
                class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">

            <input type="text" placeholder="Mobile Number " [(ngModel)]="SignUpMobile" [pattern]="mobNumberPattern"  maxlength="13" (ngModelChange)="onSignUpMobileChange($event)" 
                class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">

            <input type="password" placeholder="Password " [(ngModel)]="SignUpPassword" class="form-control line"
                style="font-family: Ubuntu-Medium !important;" />
            <!-- <div *ngIf="sPass.errors?.pattern">
                    User name not valid.
               </div>  -->
        </div>
        <div class="form-group" style="padding-top:10px ;">

            <input type="password" placeholder="Confirm Password " [(ngModel)]="SignUpConfirmPass"
                class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="row" style="padding-top: 10px;">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" id="Instant" style="display: block;">
                <div class="input-group" style=" padding-bottom:5px;" draggable="true" ondragstart="return false"
                    ondrop="return false">
                    <div class="input-group-addon" style="background-color:#294f91;cursor:pointer;height:0px;"
                        (click)="CaptchaSignUp()">
                        <span id="refresh2"><img style="width:20px; height:20px;"
                                src="../assets/Images/icons8-refresh-30.png"></span>

                    </div>

                    <input type="text" class="form-control" [(ngModel)]="SignUpCaptha"
                        style="margin-bottom: 0px; margin-top: 0px;     width: 100px; color: black; height: 40px; font-size: 16px; border-radius: 2px; font-weight: bolder; display: inline-block; background-color: rgb(255, 255, 255);"
                        id="mainCaptcha" disabled="" draggable="true" ondragstart="return false" ondrop="return false">

                    <div style="margin-top:10px; color: #D8000C; border-radius: 7px;" id="log"></div>
                </div>
            </div>
            <div class="col-lg-8 col-md-4 col-sm-4 col-xs-12" id="Instant1" style="display: block;">
                <div class="form-group box" style="padding-left: 22%;">
                    <input type="text" [(ngModel)]="SignUpCaptha1"
                        style="border-top:0px;width: 100%; border-left:0px; border-right:0px; border-radius: 0px; border-bottom: 1px solid ;height: 40px; font-size: 14px; font-weight: bolder; display: inline-block;"
                        id="txtInput" placeholder="Enter Captcha" ng-model="Data3.shCaptcha" name="captcha" value=""
                        class="ng-pristine ng-untouched ng-valid ng-empty">
                </div>
            </div>

        </div>
        <div class="row" style="padding-top: 10px;">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12" style="text-align:center;">
            <span [hidden]="isShownErrorSignUp" style="color: red;">{{SignUpErrorMsg}}</span>
            <span [hidden]="isShownSuccessSignUp" style="color: green;">{{SignUpSuccessMsg}}</span>
        </div></div>
        <div class="form-group "
            style="margin-top: 10px; border-radius: 34px;border: 1px solid;background-color: #EE562F;text-align: center; cursor: pointer;">

            <h3 class="" style="text-align: center;margin-top: 10px;color: white;font-family: Ubuntu-Bold !important"
                (click)="SignUpClick()">SIGNUP</h3>
        </div>

        <div class="" style="padding-top:15px ;">
            <h5 style="font-size: 14px;">Already have Account ?</h5>

            <div class="form-group ColorCha"
                style="border-radius: 34px;border: 1px solid;text-align: center; cursor: pointer;">

                <h3 class="" style="text-align: center;margin-top: 10px;font-family: Ubuntu-Bold !important"
                    (click)="open2('SignUp',Login)">LOGIN</h3>
            </div>
            <div class="form-group " style="text-align: center;">

                <h3 class="" style="text-align: center;margin-top: 50px;font-size: 18PX; cursor: pointer;"
                    (click)="open1('Login',Guest)">Or Continue as a <b style="color: black;font-size: 18PX;">GUEST</b>
                </h3>
            </div>
        </div>

    </div>



</ng-template>


<ng-template #Guest let-modal>

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">

        <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">GUEST</h3>

        <button type="button" class="close" aria-label="Close"
            style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">×</span>

        </button>

    </div>

    <div class="modal-body" style="padding:35px;">
       

        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <div class="form-group" style="padding-top:10px ;">
                <input type="text" placeholder="First Name" [(ngModel)]="GustFirstName" class="form-control line"
                    style="font-family: Ubuntu-Medium !important;" />
            </div>
            <div class="form-group" style="padding-top:10px ;">

                <input type="text" placeholder="Last Name" [(ngModel)]="GustLastName" class="form-control line"
                    style="font-family: Ubuntu-Medium !important;" />
            </div>
            <div class="form-group" style="padding-top:10px ;">

                <input type="text" placeholder="Email ID (Optional) " [(ngModel)]="GustEmail" class="form-control line"
                    style="font-family: Ubuntu-Medium !important;" />
            </div>
            <div class="form-group" style="padding-top:10px ;">

                <input type="text" placeholder="Mobile Number " [(ngModel)]="GustMobile" class="form-control line" maxlength="13" (ngModelChange)="onSignUpGustMobileChange($event)" 
                    style="font-family: Ubuntu-Medium !important;" />
            </div>
            <div class="row" style="padding-top: 10px;">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" id="Instant" style="display: block;">
                    <div class="input-group" style=" padding-bottom:5px;" draggable="true" ondragstart="return false"
                        ondrop="return false">
                        <div class="input-group-addon" style="background-color:#294f91;cursor:pointer;height:0px;"
                            (click)="CaptchaGust()">
                            <span id="refresh2"><img style="width:20px; height:20px;"
                                    src="../assets/Images/icons8-refresh-30.png"></span>

                        </div>

                        <input type="text" class="form-control" [(ngModel)]="GustCaptha"
                            style="margin-bottom: 0px; margin-top: 0px;     width: 100px; color: black; height: 40px; font-size: 16px; border-radius: 2px; font-weight: bolder; display: inline-block; background-color: rgb(255, 255, 255);"
                            id="mainCaptcha" disabled="" draggable="true" ondragstart="return false"
                            ondrop="return false">

                        <div style="margin-top:10px; color: #D8000C; border-radius: 7px;" id="log"></div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-4 col-sm-4 col-xs-12" id="Instant1" style="display: block;">
                    <div class="form-group box" style="padding-left: 22%;">
                        <input type="text" [(ngModel)]="GustCaptha1"
                            style="border-top:0px;width: 100%; border-left:0px; border-right:0px; border-radius: 0px; border-bottom: 1px solid ;height: 40px; font-size: 14px; font-weight: bolder; display: inline-block;"
                            id="txtInput" placeholder="Enter Captcha" ng-model="Data3.shCaptcha" name="captcha" value=""
                            class="ng-pristine ng-untouched ng-valid ng-empty">
                    </div>
                </div>


            </div>
            <div class="row" style="padding-top: 10px;">
            <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12" style="text-align:center;" >
                <span [hidden]="isShownErrorGuest" style="color: red;">{{GuestErrorMsg}}</span>
                <span [hidden]="isShownSuccessGuest" style="color: green;">{{GuestSuccessMsg}}</span>
            </div></div>

            <div class="form-group"
                style=" margin-top: 10px; margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;">

                <h3 class="" (click)="GustClick()"
                    style="font-family: Ubuntu-Medium !important;text-align: center;margin-top: 10px;color: white;">
                    SUBMIT</h3>
            </div>

            <div class="" style="padding-top:20px ;border-top: 1px solid;font-size: 14px;">
                <h5>Already have an Account ?</h5>
            </div>
            <div class="form-group ColorCha" style="border-radius: 34px;border: 1px solid;text-align: center;">

                <h3 class="" style="font-family: Ubuntu-Bold !important;text-align: center;margin-top: 10px;"
                    (click)="open2('Guest',Login)">LOGIN</h3>
            </div>
            <div class="" style="padding-top:20px ;font-size: 14px">
                <h5>Don't have an Account ?</h5>
            </div>
            <div class="form-group ColorCha" style="border-radius: 34px;border: 1px solid;text-align: center;">

                <h3 class="" style="font-family: Ubuntu-Bold !important;text-align: center;margin-top: 10px;"
                    (click)="open2('Guest',SignUp)">SIGNUP</h3>
            </div>
        </div>

    </div>



</ng-template>

<ng-template #Forget let-modal>

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">

        <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">Forgot Password</h3>

        <button type="button" class="close" aria-label="Close"
            style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;cursor: pointer;"
            (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">×</span>

        </button>

    </div>

    <div class="modal-body" style="padding:35px;">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12" style="text-align:center;">
            <span [hidden]="isShownErrorForgot" style="color: red;">{{ForgotErrorMsg}}</span>
            <span [hidden]="isShownSuccessForgot" style="color: green;">{{ForgotSuccessMsg}}</span>
        </div>

        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <div class="form-group" style="padding-top:10px ;">
                <input type="text" placeholder="Email Or Phone" class="form-control line" [(ngModel)]="ForgotMailId" />
            </div>
            <div class="row" style="padding-top: 10px;">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" id="Instant" style="display: block;">
                    <div class="input-group" style=" padding-bottom:5px;" draggable="true" ondragstart="return false"
                        ondrop="return false">
                        <div class="input-group-addon" style="background-color:#294f91;cursor:pointer;height:0px;"
                            (click)="CaptchaForgot()">
                            <span id="refresh2"><img style="width:20px; height:20px;cursor: pointer;"
                                    src="../assets/Images/icons8-refresh-30.png"></span>

                        </div>

                        <input type="text" [(ngModel)]="ForgotCaptha" class="form-control"
                            style="margin-bottom: 0px; margin-top: 0px;     width: 100px; color: black; height: 40px; font-size: 16px; border-radius: 2px; font-weight: bolder; display: inline-block; background-color: rgb(255, 255, 255);"
                            id="mainCaptcha" disabled="" draggable="true" ondragstart="return false"
                            ondrop="return false">

                        <div style="margin-top:10px; color: #D8000C; border-radius: 7px;" id="log"></div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-4 col-sm-4 col-xs-12" id="Instant1" style="display: block;">
                    <div class="form-group box" style="padding-left: 22%;">
                        <input type="text" [(ngModel)]="ForgotCaptha1"
                            style="border-top:0px;width: 100%; border-left:0px; border-right:0px; border-radius: 0px; border-bottom: 1px solid ;height: 40px; font-size: 14px; font-weight: bolder; display: inline-block;"
                            id="txtInput" placeholder="Enter Captcha" ng-model="Data3.shCaptcha" name="captcha" value=""
                            class="ng-pristine ng-untouched ng-valid ng-empty">
                    </div>
                </div>


            </div>

            <div class="form-group"
                style=" margin-top: 10px;margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;cursor:pointer;">

                <h3 class="" style="text-align: center;margin-top: 10px;color: white;cursor:pointer;"
                    (click)="open1('Forget',Submit)">SUBMIT</h3>
            </div>
        </div>

    </div>



</ng-template>

<ng-template #Submit let-modal>

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">

        <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">SUBMIT CODE</h3>

        <button type="button" class="close" aria-label="Close"
            style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;cursor:pointer;"
            (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">×</span>

        </button>

    </div>

    <div class="modal-body" style="padding:35px;">

        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">

            <span [hidden]="isShownErrorVerCode" style="color: red;">{{VerificationErrorMsg}}</span>
            <span [hidden]="isShownSuccessVerCode" style="color: green;">{{VerificationSuccessMsg}}</span>
        </div>
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <div class="form-group" style="padding-top:10px ;">
                <input type="text" placeholder="Enter Verification Code" class="form-control line"
                    [(ngModel)]="VerificationCode" />
            </div>


            <div class="form-group"
                style="margin-top: 10px; margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;cursor:pointer;">

                <h3 class="" style="text-align: center;margin-top: 10px;color: white;cursor:pointer;"
                    (click)="VerificationSubmit()">SUBMIT</h3>
            </div>
        </div>

    </div>



</ng-template>
<ng-template #onlinepayment let-modal>
    <h1 style="margin: 0;BORDER-RADIUS: 7PX;background-color: #EE562F;color: #FFF;border-bottom: 1px solid #000;padding: 20px 0 5px 5px;"></h1>
        <div class="modal-header" style="border: none;padding:0px 35px;padding-bottom: unset;">
            <button type="button" class="close" aria-label="Close"
                style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;cursor:pointer;"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
    
        </div>
    
        <div class="modal-body" style="padding:0 35px 20px 35px;">
    <h3>Enter The Card Details</h3>
            <div>
                <form name="order" method="POST">
                  <iframe id="tokenframe" name="tokenframe" src="https://boltgw-uat.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true" frameborder="0" scrolling="no" width="200" height="150" line-height="30px"></iframe>
                  <input type="hidden" name="mytoken" id="mytoken"  [(ngModel)]="mtoken"/>
                  </form>
                  <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;"
                  (click)="CarryPayNow()">Pay</button>
              </div>
    
        </div>
    
    
    
    </ng-template>

<ng-template #Addon let-modal id="" style="width:90%">

    <div class="modal-header" style="border: none;padding-bottom: unset;background-color: #EE562F;border-radius: 20px;">
      
  
    </div>
  
    <div class="modal-body" style="padding:25px;padding-top: unset;">
  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;"  style="padding-bottom: 10px;" >
    <fieldset>
      <div style="padding-top: 10px;">
          <h3 style="text-align: center;">Are You Want to delete on Item(s)</h3>
      </div>
        <!-- Button -->
        <div class="row" style="padding-top:10px ;">
           
           
                <div class="col-md-6" style="text-align: center;">
                    <button  class="btn " style="width: 50%; background-color:#EE562F;color:white" (click)="RemoveCart()" >OK</button>
                </div>
                <div class="col-md-6" style="text-align: center;">
                    <button  class="btn " style="width: 50%; background-color:#EE562F;color:white" (click)="modal.dismiss('Cross click')" >Cancel</button>
                </div>
            </div>
    
        

    </fieldset>
  
    </div>
    </div>

  
   
  
  </ng-template>