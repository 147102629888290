
    <main class="body-content">

        <!-- Navigation Bar -->
        <nav class="navbar ms-navbar" style=" margin: unset;">
            <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
            </div>
            <div class="logo-sn logo-sm ms-d-block-sm">
              <a class="pl-0 ml-0 text-center navbar-brand mr-0" href="index.html"><img src="../../assets/Images/icon/Logo (2).png" alt="logo"> </a>
            </div>
           
            <!-- <div class="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" data-toggle="slideDown" data-target="#ms-nav-options"> <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
            </div> -->
          </nav>

          <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">
             
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">View Order</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
               
            </div>
          
    <!--/span-->
        </div>

          <div class="ms-content-wrapper">
            <div class="row">
      
              <div class="col-md-8">
      
                <div class="ms-panel">
                  <div class="ms-panel-body">
                    <div class="table-responsive">
                      <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="row"><div class="col-sm-12 col-md-7">
                              <div class="dataTables_length" id="data-table-5_length">
                                <label style="padding-right: 25px;">View Order</label>
                                <input type="text"  matInput (keyup)="applyFilter($event.target.value)"   placeholder="Search" style="border: unset;background:  #ececec;height: 37px;    width: 65%;"> <i class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 80%; top: 3px;"></i>
                            </div>

                          </div>
                          <div class="col-sm-12 col-md-5">
                            <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                                <div class="col-md-8">
                                    <select style="text-align: left;height: 26px;width: 64%;    border-radius: 4px;"  [(ngModel)]="Monthdate" (change)="Datechose(Monthdate)">
                                        <option value="" disabled selected>Search By</option>
                                        <option value="Yesterday">Yesterday</option>
                                        <option value="Week">Week</option>
                                        <option value="Month">Month</option>
                                    </select>
                                </div>     
                               
                                    <div class="col-md-4">
                                        <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" style="padding:unset;margin: unset; margin-left: 25px;"  (click)="exportexcel()" style="cursor: pointer;">
                                    </div>
                                    <!-- <button class="btn" style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;" (click)="exportexcel()" >Export</button> -->
                                 
                                </div>
                              </div>
                            </div>



                                <div class="mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                                  <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSort >
                                   
                                    <!-- Position Column -->
                                    <ng-container matColumnDef="orderId" >
                                      <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> ORDER ID </th>
                                      <td mat-cell *matCellDef="let element" (click)="OrderView(element.orderId)"> {{element.orderId}} </td>
                                    </ng-container>
                                
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="orderDate">
                                      <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> ORDER DATE & TIME </th>
                                      <td mat-cell *matCellDef="let element" (click)="OrderView(element.orderId)"> {{element.orderDate | date:'MM-dd-yyyy HH:mm'}} </td>
                                    </ng-container>
                                
                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="deliveryId" >
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;"> DELIVERY TYPE </th>
                                      <td mat-cell *matCellDef="let element" (click)="OrderView(element.orderId)"> {{element.deliveryId}} </td>
                                    </ng-container>
                                
                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="orderAmount" >
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> PRICE </th>
                                      <td mat-cell *matCellDef="let element" (click)="OrderView(element.orderId)"> $ {{element.orderAmount | number:'.2'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Status" >
                                      <th mat-header-cell *matHeaderCellDef  style="color: white !important;text-align: center;"> STATUS </th>
                                      <td mat-cell *matCellDef="let element"> 
                                        <select id="Membert" [(ngModel)]="element.type" class="form-control" (change)="OrderStatus(element.type,element.orderId)">
                                      
                                          <option  value="Ordered">Ordered</option>
                                          <option  value="Delivered">Delivered</option>
                                          <option  value="Cancel">Cancel</option>
                                      </select>
                                     </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="ACTION" >
                                      <th mat-header-cell *matHeaderCellDef style="color: white !important;text-align: center;"> ACTION </th>
                                      <td mat-cell *matCellDef="let element" style="text-align: center;"> 
                                        
                                        <a class="quick-view" href="javascript:;" data-toggle="tooltip" data-original-title="" title="">
                                          <i class="fa fa-eye" (click)="open(OrderDetail,element.orderId)" ></i></a> 
                                      </td>
                                    </ng-container> -->
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                  </table>
                                
                                  <mat-paginator [pageSizeOptions]="[ 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>
                                </div>
                                 <!-- <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>  -->
                                                                  </div>
                    </div>
                  </div>
                </div>
      
              </div>
     
       
          <div class="col-md-4 " id="OrderDetail" style="border:1px solid rgba(111,111,111,0.5);padding:unset;background-color: white;">

              <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding:10px">
                  <h4 style="padding-left:20px;font-family:Ubuntu-Bold !important ;">Order Details</h4>
              </div>
              <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding:5px;border-bottom: 1px solid rgba(111,111,111,0.5);">
                  <h4 style="padding-left:20px;font-size: 18px;font-family:Ubuntu-Bold !important;"><i class="fa fa-map-marker " style="padding-right: 10px;"></i> {{ResName}}</h4>
              </div>
              <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;">
                <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                    <h5 style="font-family:Ubuntu-Bold !important;">Name: </h5>
                </div>
               
                <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5" style="padding-right: unset;text-align: left;">
                    <h5 style="font-family:Ubuntu-Bold !important;"> {{Name }}</h5>
                </div>
                <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                </div>
            </div>
            <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;" >
              <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                  <h5 style="font-family:Ubuntu-Bold !important;">Mobile: </h5>
              </div>
             
              <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5" style="padding-right: unset;text-align: left;">
                  <h5 style="font-family:Ubuntu-Bold !important;"> {{MobileNo }}</h5>
              </div>
              <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

              </div>
          </div>
          <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;" *ngIf="DeliveryAddress" >
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Address: </h5>
            </div>
            
            <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5" style="padding-right: unset;text-align: left;">
                <h5 style="font-family:Ubuntu-Bold !important;"> {{Address }}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-top: 1px solid rgba(111,111,111,0.5);" >
          <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
              <h5 style="font-family:Ubuntu-Bold !important;">Order ID: </h5>
          </div>
          
          <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5" style="padding-right: unset;text-align: left;">
              <h5 style="font-family:Ubuntu-Bold !important;"> {{OrderId }}</h5>
          </div>
          <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

          </div>
      </div>
      <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;" >
        <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
            <h5 style="font-family:Ubuntu-Bold !important;">Order Date & Time : </h5>
        </div>
       
        <div class="col-lg-55 col-md-5 col-xs-5 col-sm-5" style="padding-right: unset;text-align: left;">
            <h5 style="font-family:Ubuntu-Bold !important;"> {{OrderDate | date:'MM/dd/yyyy HH:mm'}}</h5>
        </div>
        <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

        </div>
    </div>
    <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;" >
      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
          <h5 style="font-family:Ubuntu-Bold !important;">Delivery Type </h5>
      </div>
     
      <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5" style="padding-right: unset;text-align: left;">
          <h5 style="font-family:Ubuntu-Bold !important;"> {{DeliveryType}}</h5>
      </div>
      <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

      </div>
  </div>
  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom: 1px solid rgba(111,111,111,0.5);" >
    <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
        <h5 style="font-family:Ubuntu-Bold !important;">Status : </h5>
    </div>
    
    <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5" style="padding-right: unset;text-align: left;">
        <h5 style="font-family:Ubuntu-Bold !important;"> {{Status}}</h5>
    </div>
    <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

    </div>
</div>
              <div class="" >
                  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;"  *ngFor="let item of OrderGRID; let i = index" >
                      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="font-family:Ubuntu-Bold !important;">
                          <h5  style="font-family:Ubuntu-Bold !important;">{{item.name}}</h5>
                         <div class="fa fa-pencil"> <input type="text" style="outline: none;border: none;" disabled [(ngModel)]="item.splItem"></div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2 " style="font-family:Ubuntu-Bold !important;">
                          <h5  style="font-family:Ubuntu-Bold !important;">x{{item.qty}}</h5>
                      </div>
                      <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;font-family:Ubuntu-Bold !important;text-align: center;">
                          <h5  style="font-family:Ubuntu-Bold !important;">${{item.qty * item.itemPrice}}</h5>
                        
                      </div>
                     
                  </div>
                 
                 
                  
                  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:5px;border-top: 1px solid rgba(111,111,111,0.5);">
                      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                          <h5 style="font-family:Ubuntu-Bold !important;">Delivery </h5>
                      </div>
                      <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                          <h5></h5>
                      </div>
                      <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                          <h5 style="font-family:Ubuntu-Bold !important;">$ {{DeliveryAmt | number:'.2'}}</h5>
                      </div>
                      <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                      </div>
                  </div>
                  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:5px;padding-bottom: 10px;border-bottom: 1px dashed;">
                    <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                        <h5 style="font-family:Ubuntu-Bold !important;">Discount </h5>
                    </div>
                    <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                        <h5></h5>
                    </div>
                    <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                        <h5 style="font-family:Ubuntu-Bold !important;">$ {{DiscountAmt | number:'.2'}}</h5>
                    </div>
                    <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">
  
                    </div>
                </div>
                  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:5px;border-bottom:1px dashed">
                      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                          <h5 style="font-family:Ubuntu-Bold !important;">Tax </h5>
                      </div>
                      <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                          <h5></h5>
                      </div>
                      <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                          <h5 style="font-family:Ubuntu-Bold !important;">$ {{tax | number:'.2'}}</h5>
                      </div>
                      <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                      </div>
                  </div>
                  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:5px;padding-bottom: 10px;border-bottom: 1px dashed;">
                    <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                        <h5 style="font-family:Ubuntu-Bold !important;">Tips </h5>
                    </div>
                    <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                        <h5></h5>
                    </div>
                    <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                        <h5 style="font-family:Ubuntu-Bold !important;">$ {{Tips | number:'.2'}}</h5>
                    </div>
                    <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

                    </div>
                </div>
               
                
                  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom:20px ;">
                      
                      <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" >
                          <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="padding: unset;">
                              <h5 style="font-family:Ubuntu-Bold !important;">Grand Total </h5>
                          </div>
                          <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                              <h5></h5>
                          </div>
                          <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4" style="padding-right: unset;text-align: center;">
                              <h5 style="color:#20c500 ;font-family:Ubuntu-Bold !important;">$ {{OrderTotal | number:'.2'}}</h5>
                          </div>
                        
                      </div>
                  </div>
<div class="col-md-12">
  <div class="col-md-1">

  </div>
  <div class="col-md-10" >
    <button id="print"  class="btn btn-default btn-outline" type="button" click="printer()" data-key="egetnjolnhc0hc9j" style="width: 100%;background-color: #EE562F;color: white;"> <span style="color: white;"><i class="fa fa-print" style="color: white;"></i>Print</span></button>
  </div>
  <div class="col-md-1"></div>
</div>

              </div>
          </div>
     
      
            </div>
          </div>
    
      </main>
      <ng-template #OrderDetail let-modal >

        <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
      
          <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;"><b>Order Details</b></h3>
      
          <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
      
            <span aria-hidden="true">×</span>
      
          </button>
      
        </div>
      
        <div class="modal-body" style="padding:35px;">
     
            <div class="printview-order-inner">
                <h3 class="text-info"><b>Customer Details</b></h3> 
                <table class="table table-grid table-bordered">
                    <tbody>
                        <tr>
                            <td style="width:44%">
                                <b><p>Name:</p></b>
                            </td>
                            <td>
                                <p>{{Name}}</p>
                            </td> 
                        </tr>
                        <tr>
                            <td>
                                <b><p>Mobile:</p></b>
                            </td>
                            <td>
                                <p>{{MobileNo}}</p>
                            </td> 
                        </tr>
                                <tr>
                            <td *ngIf="DeliveryAddress">
                                <b><p>Address:</p></b>
                            </td>
                            <td  *ngIf="DeliveryAddress">
                                <p>
                                   {{Address}} </p>
                            </td> 
                        </tr>
                
                    </tbody>
                </table>
                <h3 class="text-info"><b>Order Details</b></h3> 
                <table class="table table-grid table-bordered">
                    <tbody>
                        <tr>
                            <td style="width:44%">
                                <b><p>Order ID:</p></b>
                            </td>
                            <td>
                                <p>{{OrderId}}</p>
                            </td> 
                        </tr>
                        <tr>
                            <td>
                                <b><p>Order Date & Time</p></b>
                            </td>
                            <td>
                                <p>{{OrderDate | date:'MM/dd/yyyy HH:mm'}}</p>
                            </td> 
                        </tr>
                        <tr>
                            <td>
                                <b><p>Delivery Type</p></b>
                            </td>
                            <td>
                                <p>{{DeliveryType}}</p>
                            </td> 
                        </tr>
                        <tr>
                            <td>
                                <b><p>Status :</p></b>
                            </td>
                            <td>
                                <p>{{Status}} </p>
                            </td> 
                        </tr>
                    </tbody>
                </table>
              
            </div>
            <div class="overallTable">
            <table class="table table-grid table-bordered" >
                <thead>
                    <tr><th class="text-center">S.No.</th>
                        <th class="text-center">Item Name</th>
                        <th class="text-center">Qty</th>
                        <th class="text-center">Price ($)</th>
                        <th class="text-center">Total ($)</th>
                    </tr>
                </thead>
                <tbody   >
                                <tr  *ngFor="let itm of OrderGRID; let i = index">
                        <td class="s_no">{{i+1}}</td>
                        <td>{{itm.name}}</td>
                        <td>{{itm.qty}}</td>
                        <td>${{itm.qty * itm.itemPrice}}</td>
                        <td class="ing">
                            ${{itm.qty * itm.itemPrice}}
                        </td>
                    </tr>
                               
                                        <tr class="total_box">
                        <td colspan="2"></td>
                        <td colspan="3">
                            <ul class="tbl_view">
                             
                                <li class="order-td">
                                    <span class="td">Tax</span>
                                    <span class="td lbl"><b class="pull-right">$ {{tax}}</b></span>
                                </li>
                               <li class="order-td">
                                    <span class="td">Tips</span>
                                    <span class="td lbl"><b class="pull-right">$ {{Tips}}</b></span>
                                </li>
                                <li class="order-td">
                                  <span class="td">Discount</span>
                                  <span class="td lbl"><b class="pull-right">$ {{DiscountAmt}}</b></span>
                              </li>
                              <li class="order-td">
                                <span class="td">Delivery Amount</span>
                                <span class="td lbl"><b class="pull-right">$ {{DiscountAmt}}</b></span>
                            </li>
                                <li class="order-td">
                                    <span class="td">Grand Total</span>
                                    <span class="td lbl"><b class="pull-right">$ {{OrderTotal}}</b></span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        
        </div>
        <div class="modal-footer">
            <button id="print"  class="btn btn-default btn-outline" type="button" data-key="egetnjolnhc0hc9j"> <span><i class="fa fa-print"></i>Print</span></button>
            <button class="btn btn-danger" id="cancel-order-modal" data-dismiss="modal">Cancel</button>
        </div>
       
      
      </ng-template>
    
    
