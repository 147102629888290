import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { THIS_EXPR, nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { tick } from '@angular/core/testing';
import  AOS from 'aos';
import{ HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { StoragedetectComponent } from '../storagedetect/storagedetect.component';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent implements OnInit {
  Special:boolean =true;
  Soup:boolean=true;
  DISHColor:boolean =true;
  DrinkColor:boolean =true;
  Lunch:boolean =true;
  Appetizers=true;
  Supreme=true;
  HouseSpl=true;
  Especial=true;
  closeResult:string;
  Quesad =true;
  Carne =true;
  More=true;
  Filter=true;
  Load=true;
  AddonText:string;
  SplArray:[];
  FilterData=[] as any;
  ItemsArrayMenu:[];
  addtocartitem=[] as any;
  addonitem:[];
  AllArray=[] as any;
  LunchArray:[];
  AppArray=[] as any;
  HouseArray=[] as any;
  SoupArray=[] as any;
   data=[] as any;
   pricefilter=[] as any;
   Pricesec=[] as any;
   FromPrize : Variable;
   ToPrize :Variable;
   categoryName :string;
   SupremeArray=[] as any;
   PoultryArray=[] as any;
   RemainAllArray=[] as any;
   Poul=true;
   Pork=true;
   Vegetarian=true;
   FromSea=true;
   Combin=true;
   ACarte=true;
   Dessert=true;
   Little=true;
   DessertArray= [] as any;
      discoun=[] as any;
   PorkArray=[] as any;
   QualitiesArray =[] as any;
   menuid:string;
   menuname:string;
  constructor(private modalService: NgbModal, private route: ActivatedRoute,private http:HttpClient,private _storageService: StoragedetectComponent) { 
    this.menuid=this.route.snapshot.params.id;
    this.menuname=this.route.snapshot.params.text;
  }
  Allcilick(itrm)
  {
    debugger;
    if(itrm.viewAll =="less"){
      itrm.viewAll="All";
    }
    else{
      itrm.viewAll="less";
    }

  }
  LoadDetail(){
    this.More =this.More ? false :true;
  }
  DessertsAll(){
    this.Dessert =this.Dessert ? false :true; 
  }
  LittleAll(){
    this.Little =this.Little ? false :true;
  }
  ACarteless(){
    this.ACarte=this.ACarte ? false:true;
  }
  CombinationsAll(){
    this.Combin =this.Combin ? false :true; 
  }
  FromSeaAll(){
    this.FromSea =this.FromSea ? false :true;
  }
  VegetarianAll(){
    this.Vegetarian =this.Vegetarian ? false :true;
  }
  PorkAll(){
    this.Pork=this.Pork ? false :true;
  }
  PoulAll(){
    this.Poul=this.Poul ? false :true;
  }
  LoadMore(){
    this.Load =this.Load ? false :true;
  }
  CarneAll(){
    this.Carne =this.Carne ? false :true;
  }
  SupremeAll(){
    this.Supreme =this.Supreme ?false:true;
  }
  QuesadAll(){
    this.Quesad = this.Quesad ? false :true;
  }
  EspecialAll(){
    this.Especial =this.Especial ? false:true;
  }
  Appetizer(){
   this.Appetizers =this.Appetizers ? false:true; 
  }
  HouseSplless(){
    this.HouseSpl = this.HouseSpl ?false:true;
  }
  menuopen(menu) {
    debugger;
    var x = document.getElementById(menu);

    if (x.style.display == "block") {
        x.style.display = "none";
      
    }
    else {
        x.style.display = "block";
    }
 }
// ShowHideSubMenu(menu) {
//   debugger;
//   var x = document.getElementById(menu);

//   if (x.style.display == "block") {
//       x.style.display = "none";
//       x.style.height = "0px";
//       var minus = document.getElementById("minus");
//       document.getElementById("minus").className = "glyphicon glyphicon-plus pull-right";


//   }
//   else {
//       x.style.display = "block";
//       x.style.height = "53px";
//       document.getElementById("minus").className = "glyphicon glyphicon-minus pull-right";

//   }
// }




ShowHidePrice (menu) {
  debugger;
  var x = document.getElementById(menu);

  if (x.style.display == "block") {
      x.style.display = "none";
      document.getElementById("priceplus").className = "glyphicon glyphicon-plus pull-right";
  }
  else {
      x.style.display = "block";
      document.getElementById("priceplus").className = "glyphicon glyphicon-minus pull-right";
  }
}
ShowHideCategory(menu) {
  debugger;
  var x = document.getElementById(menu);

  if (x.style.display == "block") {
      x.style.display = "none";
      document.getElementById("Categoryplus").className = "glyphicon glyphicon-plus pull-right";
  }
  else {
      x.style.display = "block";
      document.getElementById("Categoryplus").className = "glyphicon glyphicon-minus pull-right";

  }
}
ShowHidePayment (menu) {
  debugger;
  var x = document.getElementById(menu);

  if (x.style.display == "block") {
      x.style.display = "none";
      document.getElementById("Paymentplus").className = "glyphicon glyphicon-plus pull-right";
  }
  else {
      x.style.display = "block";
      document.getElementById("Paymentplus").className = "glyphicon glyphicon-minus pull-right";
  }
}
  Dish(){
    debugger;
    this.DISHColor =true;
    this.DrinkColor=true;
  }

  Drink(){
    debugger;
    this.DrinkColor=false;
    this.DISHColor =false;
  }
  Lunchless(){
    debugger;
    this.Lunch = this.Lunch ? false :true;
  }
  All(){
    debugger;
this.Special=this.Special ? false : true;

  }
  Soupless(){
    this.Soup= this.Soup ? false : true;
  }
  SpecialLess(){
    this.Special= this.Special ? false : true;
  }

  Fav(item:any){
 debugger;
 var tes = item;
var UserId=sessionStorage.getItem('CustomerId');
   //var UserId= localStorage.getItem('UserId');
   var Res= localStorage.getItem('ResturntId');
   var GustId= sessionStorage.getItem('GuesId');
   if(UserId ==null || UserId =="" ){
     if(GustId != null && GustId != ""){
      
     }else{
      alert("Please Sign in to Add Favourites");
      return false;
     }
   }
   debugger;
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
let body = new HttpParams();

    var value=this.http.get(environment.apiUrl +'Fav/Add?MenuVariance_ID='+item.menuvariance["0"].menuVariance1+'&UserId='+GustId+'&CustomerDetails_ID='+UserId+'&Qty='+item.quan+'&Restaurant_ID='+Res).toPromise().then((data:any)=>{
debugger;

    }).catch((data: any) => {
debugger;
var vDataError = JSON.stringify(data.error.text);
var vErrorMsg = vDataError.replace('"', '');
var vErrorMsg1 = vErrorMsg.replace('"', '');
if(vErrorMsg1 == "Success"){
  alert("Selected Item(s) added to Favourites");
  this.check();
}else if(vErrorMsg1 =="Update"){
  alert("Selected Item(s) removed from Favourites");
  this.check();
}
this.FirstArray();
//this.Apptizer();
//this.LUNCH();
//this.House();
//this.Soupsalad();
//this.PorkGet();
//this.SupremeGet();
//this.QualitiesGet();
//this.PoultryGet();
//this.DessertGet();
    });
  }
  debugger;
  Apptizer(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Apptizier?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.AppArray=data;
      }
     
      });
      
  }

  PorkGet(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Pork?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.PorkArray=data;
      }
     
      });
      
  }
  DessertGet(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Desserts?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.DessertArray=data;
      }
     
      });
      
  }
  
  PoultryGet(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Poultry?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.PoultryArray=data;
      }
     
      });
      
  }
 SupremeGet(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Supreme?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.SupremeArray=data;
      }
     
      });
      
  }
  QualitiesGet(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Qualities?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.QualitiesArray=data;
      }
     
      });
      
  }
  LUNCH(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Lunch?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.LunchArray=data;
      }
     
      });
      
  }
  House(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/HouseSpl?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.HouseArray=data;
      }
     
      });
  }
  Soupsalad(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Soupsalads?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.SoupArray=data;
      }
     
      });
  }
  check(){
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/SplArray?Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.SplArray=data;
      }
     
      });
  }
  Addvariance(item:any,itemvar){
    debugger;
    var demote =item.menuvariance["0"].price1;
    for(let i=0;i<item.menuvariance.length;i++ ){
     debugger;
      if(item.menuvariance[i].menuVariance==itemvar){
        item.menuvariance["0"].price1=item.menuvariance[i].price;
        item.menuvariance["0"].imageUrl1=item.menuvariance[i].imageUrl;
        item.menuvariance["0"].description1=item.menuvariance[i].description;
        item.menuvariance["0"].menuvarianceName1=item.menuvariance[i].menuvarianceName;
        item.menuvariance["0"].menuVariance1=item.menuvariance[i].menuVariance;
      }
    }
  
  }
  Addtocart(item:any){
    debugger;

  }
  FirstArray(){
    var resid=  localStorage.getItem('ResturntId');
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    var value= this.http.get(environment.apiUrl + 'Menuitem/Allitemstest?Cus_Id='+UserId +'&GustId='+GustId +'&res='+resid).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        this.AllArray=data;
      }
     
      });
      var UserId=sessionStorage.getItem('CustomerId');
      var GustId= sessionStorage.getItem('GuesId');
      var value= this.http.get(environment.apiUrl + 'Menuitem/Remainingitemstest?Cus_Id='+UserId +'&GustId='+GustId +'&res='+resid).toPromise().then(
        (data:any)=> {
          debugger;
        if(data.length!=0 ){
          this.RemainAllArray=data;
        }
       
        });
  }
  ngOnInit(): void {
 debugger;

if( this.menuid != "" && this.menuid != null){
  this.Filter =true;
  if(Number (this.menuid) > Number(6))
  {
    this.debugger;
    this.More=true; 
   // window.open("/#" + this.menuname, "_self");
   // window.location.href="/"+this.menuname;
   self.location.href="/#"+this.menuname;
  }
  else{
    //window.location.href="/#"+this.menuname;
   // location.assign("/#" + this.menuname);
  // parent.location.hash = "/#" + this.menuname;
 // window.open("/#" + this.menuname, "_self");
  // window.open("/#" + this.menuname);

  self.location.href="/#"+this.menuname;
  }
}
this._storageService.watchStorage().subscribe((data:string) => {
  debugger;
  this.FilterMenu();
  this.FirstArray();
 })
if(this.menuname != "" && this.menuname != undefined){
 debugger;
 this.More=false; 
}

    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
   // this.Apptizer();
    //this.QualitiesGet();
   // this.PorkGet();
  // this.check();
  // this.House();
  // this.LUNCH();
   // this.Soupsalad();
    //  this.SupremeGet();
     // this.DessertGet();
       //    this.PoultryGet();
           var resid=  localStorage.getItem('ResturntId');
  this.FilterMenu();
  this.FirstArray();

//     var vmenuShow = document.getElementById("menuShow");
// vmenuShow.style.display = 'none';
var vmenuShow1 = document.getElementById("Filter");
vmenuShow1.style.display = 'none';
var vCategoryShow = document.getElementById("menuCategory");
vCategoryShow.style.display = 'none';
var vmenuShow = document.getElementById("PriceShow");
vmenuShow.style.display = 'none';
var vmenuShow = document.getElementById("Payment");
vmenuShow.style.display = 'none';

$(".Dish").css("background-color", "#F4650C");
$(".Dish").css("border-radius", "32px");
$(".Dish").css("color", "white");
$(".Dish").click(function () {
    $(this).css("background-color", "#F4650C");
    $(this).css("border-radius", "32px");
    $(this).css("color", "White");
    $(".drnk").css("background-color", "");
    $(".drnk").css("color", "black");
});
$(".drnk").click(function () {
    debugger;
    $(this).css("background-color", "#F4650C");
    $(this).css("border-radius", "32px");
    $(this).css("color", "White");
    $(".Dish").css("background-color", "");
    $(".Dish").css("color", "black");
});

$("#CategoryMenulist").change(function(){
  debugger;

});

AOS.init();

  }
  handleoffer($event,offer){
    debugger;
    var UserId=sessionStorage.getItem('CustomerId');
    var GustId= sessionStorage.getItem('GuesId');
    if ($event.target.checked === true) {



      if( offer== "Discount" )
      {

if( this.FilterData != 0){
for(let i=0;i< this.FilterData ;i++){
  if(this.FilterData[i].couponAmt  != 0){
    if(this.discoun.length != 0){
      this.discoun.push({"couponAmt":this.FilterData[i].couponAmt,"couponAmt1":this.FilterData[i].couponAmt1, "couponType":this.FilterData[i].couponType, "description":this.FilterData[i].description,"discount":this.FilterData[i].discount,"favId":this.FilterData[i].favId, "imageUrl":this.FilterData[i].imageUrl,"itemName":this.FilterData[i].itemName,"menuItemId":this.FilterData[i].menuItemId,"menuVariance":this.FilterData[i].menuVariance,"newPrice":this.FilterData[i].newPrice,"price":this.FilterData[i].price,"quan":this.FilterData[i].quan});
    }
else{
this.discoun=this.FilterData(i,1);
}
  }
}
this.FilterData = this.discoun;
}
else{
  var value= this.http.get(environment.apiUrl + 'Filter/FOffer?Type='+offer + '&Cus_Id='+UserId +'&GustId='+GustId).toPromise().then(
    (data:any)=> {
      debugger;
    if(data.length!=0 ){
      this.Filter=false;
      this.FilterData=data;
    }
    else{
    };
    });
}

        
      }
      else{
        this.FilterData=[];
      }
     
     
     
    }
    else{
      this.FilterData=this.pricefilter;
    }
  }
  handleSelected($event,item) {
    debugger;
    if ($event.target.checked === true) {
      this.FromPrize =null ;
this.ToPrize =null;
if(this.FilterData.length !=0 ){

  if(this.Pricesec.length != 0){
    this.Pricesec=[];
    this.FilterData=this.pricefilter;
  }
}
   var tet=item.categoryName;
   var UserId=sessionStorage.getItem('CustomerId');
   var GustId= sessionStorage.getItem('GuesId');
   var value= this.http.get(environment.apiUrl + 'Filter/FCategory?Category='+item.categoryName +'&Cus_Id='+ UserId+'&GustId='+GustId).toPromise().then(
    (data:any)=> {
      debugger;
      var testde =[];
    if(data.length!=0 ){
      this.Filter=false;
      var localget = localStorage.getItem('Filtercat');
      if( this.FilterData == null){
        this.FilterData=data;
        localStorage.setItem('Filtercat',JSON.stringify(data));
       
      }
      else{

        testde =this.FilterData.concat(data);
       
        this.FilterData=testde ;
      }
     
    }
    else{
    };
    });
 }
 else{
  var tet=item.categoryName;
  var UserId=sessionStorage.getItem('CustomerId');
  var GustId= sessionStorage.getItem('GuesId');
  var value= this.http.get(environment.apiUrl + 'Filter/FCategory?Category='+item.categoryName +'&Cus_Id='+ UserId+'&GustId='+GustId).toPromise().then(
   (data:any)=> {
     debugger;
     var testde =[];
   if(data.length!=0 ){
     this.Filter=false;
     var localget = localStorage.getItem('Filtercat');
     if( this.FilterData == null){
       this.FilterData=data;
       localStorage.setItem('Filtercat',JSON.stringify(data));
      
     }
     else{
      for (var i = 0; i < this.FilterData.length ; i++) {
        for(var  j=0;j < data.length;j++){
          var filvar = this.FilterData[i].menuVariance ;
          var dava = data[j].menuVariance;
          if (this.FilterData[i].menuVariance == data[j].menuVariance) {
           debugger;
            this.FilterData.splice(i, 1);
            i=i-1;
            break;
           
          }
        }
        
    }
    this.FilterData= this.FilterData;
     }
    
   }
   else{
   };
   });
 }
}

FilterMenu(){
  var resid=  localStorage.getItem('ResturntId');
  var value= this.http.get(environment.apiUrl + 'Menu/Sub?RestaruntId='+resid).toPromise().then(
    (data:any)=> {
      debugger;
    if(data.length!=0 ){
      this.ItemsArrayMenu=data;
    }
    else{
    };
    });
}

PriceList(){
  debugger;
var from = this.FromPrize;
var to =this.ToPrize;
if(from ==null){
  alert("please enter from Prize");
  return false
}
if(Number(from) > Number(to)){
  alert("Please enter greater than from value to");
  return false; 
}
$("#MinIndex").val(Number(from));
$("#Maxindex").val(Number(to));
this.debugger;

var demo=[] as any;

if(this.FilterData.length !=0 ){

  if(this.Pricesec.length != 0){
    this.Pricesec=[];
    this.FilterData=this.pricefilter;
  }
  else{
   
    this.pricefilter=this.FilterData; 
  }

for(let i=0; i<this.FilterData.length;i++ ){
  debugger;
  var tesr1=this.FilterData[i].price;
  if(Number(this.FilterData[i].price) >= Number(from) && Number(this.FilterData[i].price) <=Number(to)){
    debugger;
    if(this.Pricesec ==null || this.Pricesec.length ==0){
     this.Pricesec = this.FilterData.slice(i,1);
    //this.Pricesec.push(this.FilterData.slice(i,1));  
  }
    else{
     
      this.Pricesec.push({"couponAmt":this.FilterData[i].couponAmt,"couponAmt1":this.FilterData[i].couponAmt1, "couponType":this.FilterData[i].couponType, "description":this.FilterData[i].description,"discount":this.FilterData[i].discount,"favId":this.FilterData[i].favId, "imageUrl":this.FilterData[i].imageUrl,"itemName":this.FilterData[i].itemName,"menuItemId":this.FilterData[i].menuItemId,"menuVariance":this.FilterData[i].menuVariance,"newPrice":this.FilterData[i].newPrice,"price":this.FilterData[i].price,"quan":this.FilterData[i].quan});
  //   var  demo1=(this.FilterData.splice(i,1));
     //  this.Pricesec.concat((demo1));
    }
   
  }
}
this.FilterData=this.Pricesec;
}
else{
  var UserId=sessionStorage.getItem('CustomerId');
  var GustId= sessionStorage.getItem('GuesId');
  var value= this.http.get(environment.apiUrl + 'Filter/FPrize?Fprize='+from +'&TPrize='+ to + '&Cus_Id='+UserId +'&GustId='+GustId ).toPromise().then(
    (data:any)=> {
      debugger;
    if(data.length!=0 ){
      this.Filter=false;
      this.FilterData=data;
    }
    else{
    };
    });
}


}
  customOptions: OwlOptions = {
    loop: false,
    nav: true,
    margin:10,
    stagePadding:10,
    dots:true,
    navText: [
      " <img src='../../assets/Images/icon/left-slider.png' style='position:absolute;top:41%;left:-30px' />",
      " <img src='../../assets/Images/icon/right-slider.png' style='position:absolute;top:41%;left:-30px'/>"
  ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
  }

  
  DirinkAndDish: OwlOptions = {
    loop: false,
    nav: true,
    margin:10,
    stagePadding:10,
    dots:true,
    navText: [
      " <img src='../../assets/Images/icon/left-slider.png' style='position:absolute;top:41%;left:-30px' />",
      " <img src='../../assets/Images/icon/right-slider.png' style='position:absolute;top:41%;left:-30px'/>"
  ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
      940: {
        items: 7
      }
    },
  }
  AddonCartitems(Addon:any){
  debugger;
 
  this.modalService.dismissAll(Addon);
  var tes =Addon.adddon;
     this.addtocartitem=Addon;
var Quan=Addon.quan;

    this.data=JSON.parse(localStorage.getItem('CartItem' ));
  var check=null;

  var test =Addon.menuvariance["0"].imageUrl1;
 
  if(this.data != null){
    
    if(this.data.length !=0){
      for (var i =   this.data.length - 1; i >= 0; i--) {
        var tes=Addon.menuvariance["0"].salestax;
        if (  this.data[i].MenuVarianceId == Addon.menuvariance["0"].menuVariance1) {
          check="entry";
          Quan= Quan+this.data[i].Quan;
          this.data.splice(i, 1);
          this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ?  "-" +Addon.menuvariance["0"].menuvarianceName1 :"") ,couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan});   
      //    this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price: (this.addtocartitem.price),orginalpric:this.addtocartitem.price});
        }
         
    }
    if(check != "entry"){
      this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName  +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ?  "-" +Addon.menuvariance["0"].menuvarianceName1 :""),couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan});  
      //  this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price});
    }
  

  }
  else{
    this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName  +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ?  "-" +Addon.menuvariance["0"].menuvarianceName1 :""),couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan});  
    //this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price});
  }

  }
  
    else{
    //  this.data=[{Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price}];
    this.data=[{Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName  +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ? "-" +Addon.menuvariance["0"].menuvarianceName1 :""),couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan}];  
  }

   
  var resid=   localStorage.getItem('ResturntId');
localStorage.clear();

localStorage.setItem('ResturntId',resid);
this._storageService.setItem('CartItem',JSON.stringify(this.data));

  //  localStorage.setItem('CartItem',JSON.stringify(this.data) );
  //  localStorage.setItem('Addon',tes);
   // localStorage.setItem('ResturntId',resid);
    alert("Selected Items added to Cart");
    this.FirstArray();
  //  window.location.href="";
   // return false;
  }

  AddonCartitemsFilter(Addon:any){
    debugger;
   
    this.modalService.dismissAll(Addon);
    var tes =Addon.adddon;
       this.addtocartitem=Addon;
  var Quan=Addon.quan;
  
      this.data=JSON.parse(localStorage.getItem('CartItem' ));
    var check=null;
  
//    var test =Addon.menuvariance["0"].imageUrl1;
   
    if(this.data != null){
      
      if(this.data.length !=0){
        for (var i =   this.data.length - 1; i >= 0; i--) {
          if (  this.data[i].MenuVarianceId == Addon.menuvariance) {
            check="entry";
            Quan= Quan+this.data[i].Quan;
            this.data.splice(i, 1);
        //    this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName +"-" + Addon.menuvariance["0"].menuvarianceName1,couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1});   
           this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price: (this.addtocartitem.price),orginalpric:this.addtocartitem.price,sTax:this.addtocartitem.salestax,hTax:this.addtocartitem.hospitalitytax,oTax:this.addtocartitem.othertax,discountQuan:this.addtocartitem.discountQuan});
          }
           
      }
      if(check != "entry"){
       // this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName +"-" + Addon.menuvariance["0"].menuvarianceName1,couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1});  
          this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price,sTax:this.addtocartitem.salestax,hTax:this.addtocartitem.hospitalitytax,oTax:this.addtocartitem.othertax,discountQuan:this.addtocartitem.discountQuan});
      }
    
  
    }
    else{
     // this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName +"-" + Addon.menuvariance["0"].menuvarianceName1,couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1});  
      this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price,sTax:this.addtocartitem.salestax,hTax:this.addtocartitem.hospitalitytax,oTax:this.addtocartitem.othertax,discountQuan:this.addtocartitem.discountQuan});
    }
  
    }
    
      else{
      this.data=[{Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price,sTax:this.addtocartitem.salestax,hTax:this.addtocartitem.hospitalitytax,oTax:this.addtocartitem.othertax,discountQuan:this.addtocartitem.discountQuan}];
    //  this.data=[{Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName +"-" + Addon.menuvariance["0"].menuvarianceName1,couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1}];  
    }
  
     
    var resid=   localStorage.getItem('ResturntId');
  localStorage.clear();
  localStorage.setItem('ResturntId',resid);
this._storageService.setItem('CartItem',JSON.stringify(this.data));
    //  localStorage.setItem('CartItem',JSON.stringify(this.data) );
      //localStorage.setItem('Addon',tes);
      //localStorage.setItem('ResturntId',resid);
      alert("Selected Items added to Cart");
     // window.location.href="";
     // return false;
    }
  open(content,item:any) {
    debugger;
    this.addtocartitem=item;
      this.modalService.open(content,{ windowClass: 'custom-class'} ).result.then((result) => {
    
        this.closeResult = `Closed with: ${result}`;
    
      }, (reason) => {
    
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    
      });
    }
    
    private getDismissReason(reason: any): string {
    
      if (reason === ModalDismissReasons.ESC) {
    
        return 'by pressing ESC';
    
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    
        return 'by clicking on a backdrop';
    
      } else {
    
        return  `with: ${reason}`;
    
      }
    
    }
    
    Adddown(min,item) {
      debugger;
      // var Mynumber = parseInt((document.getElementById("AddNumber") as HTMLInputElement).value) - 1;
      // ((document.getElementById("AddNumber") as HTMLInputElement).value) = String(Mynumber);
      // if (parseInt((document.getElementById("AddNumber") as HTMLInputElement).value) <= parseInt(min)) {
      //   (document.getElementById("AddNumber") as HTMLInputElement).value = min;
      // }
      if(item.quan !=1){
      //  item.price= item.price -item.orginalpric;
        item.quan=item.quan -1;
      }
     
    }
    Addup(max,item) {
      debugger;
      // var Mynumber = parseInt((document.getElementById("AddNumber") as HTMLInputElement).value) + 1;
      // ((document.getElementById("AddNumber") as HTMLInputElement).value) = String(Mynumber);
      // if (Number(Mynumber) >= Number(max)) {
      //   (document.getElementById("AddNumber") as HTMLInputElement).value = max;
      // }
     // item.price= item.price + item.orginalpric;
    item.quan=item.quan +1;
    }
    AddcartQuant(item){
      debugger;
      if(item.quan != 0){
        item.quan= item.quan ;
      }
      else{
        item.quan=1;      
      }
    }
}
