<div class="container" style="padding-top:40px;">  
    <div class="row">  
      <div class="col-lg-4">
  
      </div>
      <form >
      <div class="col-lg-4">
        <div class="col-lg-12">
          <div class="col-lg-4">
            <h4>UserName</h4>
          </div>
          <div class="col-lg-7">
           <input type="text" class="form-control" [(ngModel)]="UserName"  name="UserName" required>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4">
            <h4>Password</h4>
          </div>
          <div class="col-lg-7">
           <input type="password" class="form-control"  [(ngModel)]="Password" name="Password" required>
          </div>
        </div>
        <div class="col-lg-12">
         <button class="btn center-block" style="color: blue;" (click)="loinUser()" value="Submit">Submit</button>
          
        </div>
       <h4 style="color: green;padding-top:30px;">{{Success}}</h4>
       <h4 style="color: red;padding-top:30px;">{{Fail}}</h4>
        <div class="btn" routerLink='/Login'>Register ?</div>
      </div>
    </form>
      <div class="col-lg-4">
        
      </div>
    </div>
  </div>
  
  <div class="col-lg-12" style="padding-bottom:60px;">
    <table border="1" Width="100%" style="text-align: center;">
      <thead>
        <tr style="text-align: center;">
          <th style="text-align: center;">
            Product Id
          </th>
          <th style="text-align: center;">Title</th>
        <th style="text-align: center;">Description</th>
        </tr>
      </thead>
      <tbody *ngFor="let it of ItemsArray">
        <tr  *ngFor="let item of it">
       <td>
         {{item.productId}}
       </td>
       <td>
         {{item.title}}
       </td>
        <td>
          {{item.description}}
        </td>
        </tr>
      </tbody>
    </table>
  </div>
