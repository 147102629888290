import { Component, OnInit,NgModule } from '@angular/core';
import{ HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  
})
export class LoginComponent implements OnInit {
 public UserName:'';
 public Password:'';
 public Success:string;
 public Fail:string;
  ItemsArray:[];
  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    debugger;
    
  }




  loinUser(){
    debugger;
    var re = environment.apiUrl + 'IndexPage/GetMemberDetailsByEmailId?EmailId='+this.UserName+ '&Password='+this.Password;
    var value= this.http.get(environment.apiUrl + 'IndexPage/GetMemberDetailsByEmailId?EmailId='+this.UserName+ '&Password='+this.Password).toPromise().then(
      (data:any)=> {
        debugger;
      if(data.length!=0 ){
        
       alert("Success");
       this.UserName='';
       this.Password='';
       this.Success="Login SuccessFully";
       window.location.href='/Login'
      }
      else{
        alert("failed");
  this.Fail="Login Failed";
      }
        debugger;
  
      });
  
    }
  
}
