import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-admin-sub-category',
  templateUrl: './admin-sub-category.component.html',
  styleUrls: ['./admin-sub-category.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminSubCategoryComponent implements OnInit {
  Drop = true;
  Resturants = [];
  Categorys = [];
  categoryId: string;
  restaurantId: string;
  Status: boolean = false;
  StatusGrid: boolean = false;
  isShownActive: Boolean = true;
  public searchText: string;
  EditedSubCat = [] as any;
  EditedSubCatId: string;
  isShownError: boolean = true;
  isShownSuccess: boolean = true;
  ErrorMsg: string;
  SuccessMsg: string;
  SubCategoryName: string;
  isShownSaveBtn: boolean = false;
  isShownUpdateBtn: boolean = true;

  displayedColumns: string[] = ['sno','restaurantName', 'categoryName', 'subCategoryName', 'status'];
  dataSource: MatTableDataSource<any[]>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.loadScript('../../assets/framework.js');
    this.loadScript('../../assets/datatables.min.js');
    this.BindRestaurantDDL();
    this.BindCategoryDDL();
  }
  Show() {
    this.Drop = false;
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
    this.BindSubCategoryGrid("Active");
  }
  BindSubCategoryGrid(value1) {
    var value = this.http.get(environment.apiUrl + 'AdminSubCategory/GetSubCategoryForGrid?sStatus=' + value1).toPromise().then(
      (data: any) => {
        debugger;
        //if (data.length != 0) {
          //this.items = data;          
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        // }
        // else {
        // };
      });
  }
  BindRestaurantDDL() {
    var value = this.http.get(environment.apiUrl + 'AdminCopoun/GetResturantForDDL').toPromise().then(
      (data: any) => {
        debugger;
        if (data.length != 0) {
          //
          this.Resturants = data;
          this.restaurantId = "0";
        }
        else {
        };
      });
  }
  BindCategoryDDL() {
    var value = this.http.get(environment.apiUrl + 'AdminCopoun/GetCategoryForDDL').toPromise().then(
      (data: any) => {
        debugger;
        if (data.length != 0) {
          this.Categorys = data;
          this.categoryId = "0";
        }
        else {
        };
      });
  }

  InsertSubCategory() {
    this.isShownError = true;
    this.isShownSuccess = true;
    this.ErrorMsg = "";
    this.SuccessMsg = "";

    var vRestaurant_ID = this.restaurantId;
    var vSubCategoryName = this.SubCategoryName;
    var vCatId=this.categoryId;
    if (vRestaurant_ID == undefined || vRestaurant_ID == "" || vRestaurant_ID == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select any one Resturant.";
      return false;
    }
    if (vCatId == undefined || vCatId == "" || vCatId == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select the Category.";
      return false;
    }
    if (vSubCategoryName == undefined || vSubCategoryName == "") {
      this.isShownError = false;
      this.ErrorMsg = "Please Enter the Sub Category.";
      return false;
    }
    let params = new HttpParams();
    params = params.append('iRestaurant_ID', this.restaurantId);
    params = params.append('iCategoryId', this.categoryId);
    params = params.append('sSubCategoryName', this.SubCategoryName);
    var value = this.http.get(environment.apiUrl + 'AdminSubCategory/InsertSubCategory', { params: params }).toPromise().then(
      (data: any) => {
        debugger;
      }).catch((data: any) => {
        debugger;
        var vDataError = JSON.stringify(data.error.text);
        var vErrorMsg = vDataError.replace('"', '');
        var vErrorMsg1 = vErrorMsg.replace('"', '');//Exist
        if (vErrorMsg1 == "Failed") {
          this.isShownError = false;
          this.ErrorMsg = "Insert Failed, Please Try Again.";
          return false;
        }
        else if (vErrorMsg1 == "Exist") {
          this.isShownError = false;
          this.ErrorMsg = "Copoun Code Already Exist.";
          return false;
        }
        else if (vErrorMsg1 == "Sucess") {
          this.BindSubCategoryGrid("Active");
          this.restaurantId = "0";
          this.categoryId="0";
          this.SubCategoryName = "";
          this.isShownSuccess = false;
          this.SuccessMsg = "Sub Category Added.";
          return false;
        }
      });

  }
  EditSubCategory(item: any) {
    this.isShownSaveBtn = true;
    this.isShownUpdateBtn = false;
    this.isShownActive=false;
    this.EditedSubCat = item;
    var vCategoryId = this.EditedSubCat.categoryId;
    var vRestaurant_ID = this.EditedSubCat.restaurantId;
    var vSubCategoryName = this.EditedSubCat.subCategoryName;
    var vSubCategoryID = this.EditedSubCat.subCategoryId;
    this.EditedSubCatId=vSubCategoryID;

    this.restaurantId = vRestaurant_ID;
    this.categoryId=vCategoryId;
    this.SubCategoryName = vSubCategoryName;
    var vStatus = this.EditedSubCat.status;
    if (vStatus == "Active")
      this.Status = false;
    else
      this.Status = true;

      this.Drop = false;
  }

  UpdateSubCategory() {
    this.isShownError = true;
    this.isShownSuccess = true;
    this.ErrorMsg = "";
    this.SuccessMsg = "";

    var vRestaurant_ID = this.restaurantId;
    var vSubCategoryName = this.SubCategoryName;
    var vCategoryId=this.categoryId;
    if (vRestaurant_ID == undefined || vRestaurant_ID == "" || vRestaurant_ID == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select any one Resturant.";
      return false;
    }
    if (vCategoryId == undefined || vCategoryId == "" || vCategoryId == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select any one Category.";
      return false;
    }
    if (vSubCategoryName == undefined || vSubCategoryName == null) {
      this.isShownError = false;
      this.ErrorMsg = "Please Enter The Sub Category Name.";
      return false;
    }

    var vStatus = "Active";
    if (this.Status == true)
      vStatus = "InActive";
    else
      vStatus = "Active";

    let params = new HttpParams();
    params = params.append('iRestaurant_ID', this.restaurantId);
    params = params.append('sSubCategoryName', this.SubCategoryName);
    params = params.append('iCategoryId', this.categoryId);
    params = params.append('iSubCategoryId', this.EditedSubCatId);
    params = params.append('sStatus', vStatus);
    var value = this.http.get(environment.apiUrl + 'AdminSubCategory/UpdteSubCategory', { params: params }).toPromise().then(
      (data: any) => {
        debugger;

      }).catch((data: any) => {
        debugger;
        var vDataError = JSON.stringify(data.error.text);
        var vErrorMsg = vDataError.replace('"', '');
        var vErrorMsg1 = vErrorMsg.replace('"', '');//Exist
        if (vErrorMsg1 == "Failed") {
          this.isShownError = false;
          this.ErrorMsg = "Update Failed, Please Try Again.";
          return false;
        }
        else if (vErrorMsg1 == "Sucess") {
          this.BindSubCategoryGrid("Active");
          this.EditedSubCatId="0";
          this.restaurantId = "0";
          this.categoryId="0";
          this.SubCategoryName = "";
          this.isShownSuccess = false;
          this.SuccessMsg = "Sub Category Updated.";
          this.isShownSaveBtn = false;
          this.isShownUpdateBtn = true;
          this.isShownActive=true;
          return false;
        }
      });

  }

  Clear(){
    this.isShownError = true;
    this.isShownSuccess = true;
    this.ErrorMsg = "";
    this.SuccessMsg = "";
    this.isShownSaveBtn = false;
    this.isShownUpdateBtn = true;
    this.isShownActive=true;

    this.restaurantId = "0";
    this.categoryId="0";
    this.SubCategoryName = "";
  }
  toggleEditable(event) {
    debugger;
    if (event.target.checked) {
      this.Status = true;
    }
    else {
      this.Status = false;
    }
  }
  ActiveCheckboxChange(event) {
    if (event.target.checked) {
      this.StatusGrid = true;
    }
    else {
      this.StatusGrid = false;
    }
    var vStatus = "Active";
    if (this.StatusGrid == true)
      vStatus = "Active";
    else
      vStatus = "InActive";

    this.BindSubCategoryGrid(vStatus);
  }
  applyFilter(filterValue: string) {
    debugger;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  exportexcel(): void {
    debugger;
    /* table id is passed over here */
    const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, workSheet, 'Category');

    /* save to file */
    XLSX.writeFile(wb, "Category.xlsx");

  }

}
