
<main class="body-content">

    <!-- Navigation Bar -->
    <nav class="navbar ms-navbar" style=" margin: unset;">
        <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
                class="ms-toggler-bar bg-primary"></span>
            <span class="ms-toggler-bar bg-primary"></span>
            <span class="ms-toggler-bar bg-primary"></span>
        </div>
     
    </nav>

    <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

        <div class="col-md-5 align-self-center">
            <h4 class="text-themecolor">User Rights</h4>
        </div>
        <div class="col-md-7 align-self-center text-right">
            <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
                <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
                    (click)="Show()"><i class="fa fa-plus-circle"></i> Add UserRights</button>
            </div>
        </div>
        <div *ngIf="!Drop " class="col-md-12" id="Menus" style="    margin-top: 20px;">
            <div class="col-md-12" id="Menus">
                <div class="">
                    <button id="Menu" tabindex="92" class="accordion" style="display: block;">UserRights
                        Details</button>
                </div>


            </div>
            <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Restaurant</label>
                        <select class="form-control" [(ngModel)]="restaurantId">
                            <option value="0">Select</option>
                            <option *ngFor="let rst of Resturants" value={{rst.restaurantId}}>{{rst.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">User Role</label>
                        <select class="form-control" [(ngModel)]="UserRolesId">
                            <option value="0">Select</option>
                            <option *ngFor="let ur of UserRoles" value={{ur.userRoleId}}>{{ur.user}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <!--/span-->
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Menu Item</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="MenuRead" (change)="MenuReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="MenuWrite" (change)="MenuWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">View Order</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="ViewOrderRead" (change)="ViewOrderReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="ViewOrderWrite" (change)="ViewOrderWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Waiter</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="WaiterRead"  (change)="ViewWaiterReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="WaiterWrite" (change)="ViewWaiterWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Table</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="TableRead" (change)="ViewTableReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="TableWrite" (change)="ViewTableWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" style="padding-top: 25px;">
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Category Master</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="CategoryRead" (change)="CategoryReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="CategoryWrite" (change)="CategoryWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">SubCategory Master</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="SubCategoryRead" (change)="SubCategoryReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="SubCategoryWrite" (change)="SubCategoryWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Discount Master</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="DiscountRead" (change)="DiscountReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="DiscountWrite" (change)="DiscountWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Coupon Master</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="CouponRead" (change)="CouponReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="CouponWrite" (change)="CouponWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12"  style="padding-top: 25px;">
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Restaurant Working Hours</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="ResWorkHoursRead" (change)="ResWorkHoursReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="ResWorkHoursWrite" (change)="ResWorkHoursWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Restaurant Holiday</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="ResHolidayRead" (change)="ResHolidayReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="ResHolidayWrite" (change)="ResHolidayWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Tips</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="TipsRead"  (change)="TipsReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="TipsWrite" (change)="TipsWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Tax</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="TaxRead" (change)="TaxReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="TaxWrite" (change)="TaxWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12"  style="padding-top: 25px;"> 
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Rating</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="RatingRead" (change)="RatingReadChange($event)"> Read
                            <input type="checkbox" [(ngModel)]="RatingWrite" (change)="RatingWriteChange($event)"> Write
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="control-label">Reports</label>
                        <div class="col-md-12" style="padding-left: 0px;">
                            <input type="checkbox" [(ngModel)]="ReportsRead" (change)="ReportsReadChange($event)"> Read
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" style="padding-bottom: 25px;">

                <div class="col-md-6">



                    <div class="col-md-3" style="padding-top: 22px;">
                        <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;" (click)="SaveUSerRights()">SAVE</button>

                    </div>
                    <div class="col-md-3" style="padding-top: 22px;">
                        <button class="btn "
                            style="background: white; color: black;;border-radius: 4px;border-color: #ED562E;" (click)="clear()">CLEAR</button>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
                <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
                <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
            </div>
        </div>
        <!--/span-->
    </div>

    <!-- <div class="ms-content-wrapper">
    <div class="row">

      <div class="col-md-12">

        <div class="ms-panel">
          <div class="ms-panel-body">
            <div class="table-responsive">
              <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="data-table-5_length">
                      <label style="padding-right: 25px;">Search</label>
                      <input type="text" placeholder="Search"
                        style="border: unset;background:  #ececec;height: 37px;width: 72%;"  [(ngModel)]="searchText" (keyup)="applyFilter($event.target.value)" > <i
                        class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 79%; top: 3px;"></i>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                     
                      <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" (click)="exportexcel()" style="cursor: pointer;">
                      
                      <label style="padding-left: 50px;">Show</label>
                      <label class="switch" style="margin-left: 10px;">
                        <input type="checkbox" checked (change)="ActiveCheckboxChange($event)">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                    <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSortmat-table [dataSource]="dataSource" #table style="width: 100%;" matSort>
                      <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef style="color: white !important;background-color: #ED562E">
                          S.NO </th>
                        <td mat-cell *matCellDef="let item; let j = index">
                          {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                      </ng-container>
                      <ng-container matColumnDef="restaurantName" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> RESTAURANT NAME </th>
                        <td mat-cell *matCellDef="let element" (click)="EditCategory(element)"> {{element.restaurantName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="categoryName" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> CATEGORY </th>
                        <td mat-cell *matCellDef="let element" (click)="EditCategory(element)"> {{element.categoryName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="status" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> STATUS </th>
                        <td mat-cell *matCellDef="let element" (click)="EditCategory(element)"> {{element.status}} </td>
                      </ng-container>
                      
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div> -->

</main>