<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<script src="../../assets/framework.js"></script>


<main class="body-content">

  <!-- Navigation Bar -->
  <nav class="navbar ms-navbar" style=" margin: unset;">
    <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
        class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
    </div>
 
  </nav>

  <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">Item Settings</h4>
    </div>
    <!-- <div class="col-md-7 align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
        <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
          (click)="Show()"><i class="fa fa-plus-circle"></i> Add Settings</button>
      </div>
    </div> -->
    <div style="    margin-top: 20px;">
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Menu Item Settings</button>
        </div>


      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Restaurant</label>
              <select class="form-control" [(ngModel)]="restaurantId" (ngModelChange)="onResturantChange($event)"> 
                <option value="0">Select Restaurant</option>
                <option *ngFor="let rst of Resturants" value={{rst.restaurantId}}>{{rst.name}}</option>
              </select>
            </div>
          </div>

        <!-- <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Type</label>
            <select class="form-control" [(ngModel)]="type">
              <option value="0">Coupon</option>
              <option value="1">Discount</option>
            </select>
          </div>
        </div> -->
        <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Coupon</label>
              <select class="form-control" [(ngModel)]="copounDetailsId">
                <option value="0">Select Coupon</option>
                <option *ngFor="let cpn of CopounDetails" value={{cpn.copounDetailsId}}>{{cpn.copounCode}}</option>
              </select>
            </div>
        </div>
            <div class="col-md-3">
            <div class="form-group">
                <label class="control-label">Discount</label>
                <select class="form-control" [(ngModel)]="discountId">
                  <option value="0">Select Discount</option>
                  <option *ngFor="let dsc of Discounts" value={{dsc.discountId}}>{{dsc.description}}</option>
                </select>
              </div>
          </div>
    </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        
         <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Category</label>
            <select class="form-control" [(ngModel)]="categoryId" (ngModelChange)="onCatChange($event)">
              <option value="0">Select Category</option>
              <option *ngFor="let cat of Category" value={{cat.categoryId}}>{{cat.categoryName}}</option>
            </select> </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">SubCategory</label>
            <select class="form-control" [(ngModel)]="subCategoryId" (ngModelChange)="onSubCatChange($event)" >
              <option value="0">All</option>
              <option *ngFor="let scat of SubCategory" value={{scat.subCategoryId}}>{{scat.subCategoryName}}</option>
            </select> </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Menu Item</label>
            <select class="form-control" [(ngModel)]="MenuItemId" >
              <option value="0">All</option>
              <option *ngFor="let itm of MenuItem" value={{itm.menuItemId}}>{{itm.itemName}}</option>
            </select> </div>
        </div> 
      </div>

      <div class="col-md-12" style="padding-bottom: 25px;">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
          <div class="col-md-3" style="padding-top: 39px;">
          </div>
          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;" (click)="Insert()">SAVE</button>
          </div>
          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: white; color: black;border-color: #ED562E;border-radius: 4px" (click)="OnClear()">CLEAR</button>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
        <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
      </div>
    </div>
    <!--/span-->
  </div>
  

</main>
