<title>Review</title>
<div class="container-fluid" style="padding:50px;">

    <div>
        
<div class="Banner" >
    <div *ngFor='let item of Profilr'>
        <div class="col-lg-2 col-sm-12 col-xs-12 col-md-12"  *ngIf='item.profileImage != "" && item.profileImage != null ? false :true'>
            <img src="../../assets/Images/icon/avatar.jpg"  class="avatar">
    
        </div><div class="col-lg-3 col-sm-12 col-xs-12 col-md-12"  *ngIf='item.profileImage != "" && item.profileImage != null ? true :false'>
            <img src="{{item.profileImage}}"  class="avatar">
    
        </div>
    </div>
   
    <div class="col-lg-10 col-xs-12 col-md-12 col-sm-12" style="top: 270px;">
        <div class="col-lg-3 col-xs-3 col-md-3 col-sm-3" style="text-align: center;" >
            <h4 style="font-family:Ubuntu-Bold !important;color: white;font-size: 28px;"> {{Name}}  {{LastName}}</h4>
        </div>
        <div class="col-lg-3 col-xs-3 col-md-3 col-sm-3" style="border-left: 1px solid white;text-align: center;">
         <h4 style="font-family:Ubuntu-Regular !important;color: white;font-size: 28px;">  {{Mobileno}} </h4>
     </div>
     <div class="col-lg-4 col-xs-3 col-md-3 col-sm-3" style="border-left: 1px solid white;text-align: center;">
         <h4 style="font-family:Ubuntu-Regular !important;color: white;font-size: 28px;">{{EmailId}} </h4>
     </div>
     <div class="col-lg-2 col-xs-3 col-md-3 col-sm-3" *ngIf='GustStrn == null || GustStrn == "" ? true : false'>
         <button class="btn" style="background-color: #EE562F; color: white;font-size: 20px;font-family:Ubuntu-Bold !important ;" (click)="open(CustomerDetails)">Edit Profile</button>
     </div>
     </div>
</div>

    </div>
</div>

<div class="container-fluid" style="padding:50px;padding-left: 100px;">
    <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6" style="padding-right: unset;">
<h3 style="font-family: Ubuntu-Medium !important;font-size: 25px;">My Account</h3>

<div style="padding-top: 20px;">
    <h3 style="    padding-top: 5px;height: 31px;padding-left: 15px; font-family: Ubuntu-Regular !important;font-size: 20px;outline: none;" [routerLink]="'/MyOrder'">My Order</h3>
</div>

<div style="padding-top: 5px">
<h3 style="font-family: Ubuntu-Regular !important;font-size: 20px;padding-left: 15px;outline: none" [routerLink]="'/OrderDetail'">Order Details</h3>
</div>
<div style="padding-top: 5px;">
<h3 style="padding-top: 5px;font-family: Ubuntu-Regular !important;font-size: 20px;padding-left: 15px;height: 31px;background-color: silver;border-left: 3px solid #EE562F;color: #ee562f;outline: none" [routerLink]="'/Review'">Review</h3>
</div>

<div style="padding-top: 5px;">
<h3 style="font-family: Ubuntu-Regular !important;font-size: 20px;padding-left: 15px;outline: none" [routerLink]="'/MyAddress'">My Address</h3>
</div>

        </div>
        <div class="col-lg-10 colmd-10 col-xs-6 col-sm-6" style="padding-right: unset;  padding-left: unset;border:1px solid rgba(111,111,111,0.5)">
            
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="border-bottom: 1px solid rgba(111,111,111,0.5);">
                    <div class="col-lg-2 col-md-3 col-xs-6 col-sm-6" id="Reviw" style="padding: unset;">
                        <h2  style="text-align: center;font-family: Ubuntu-Medium !important;font-size: 29px;" id="Rev">Review</h2>
                    </div>
                    <div class="col-lg-2 col-md-2 col-xs-6 col-sm-6" id="His"> 
                        <h2 style="text-align: center;font-family: Ubuntu-Medium !important;cursor: pointer;font-size: 29px" id="History">History</h2>
                    </div>
                </div>
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" id="Histoy" style="padding: 72px;padding-bottom: 30px !important;padding-left: unset !important ;">
                  
                    <div class="col-lg-6 col-xs-12 col-sm-12 col-md-6 "  *ngFor='let items of Reviewdata' >
                        <div class="col-lg-1"></div>
                     <div class="col-lg-11 col-md-11 col-xs-11 col-sm-11" style="padding:unset; border:1px solid rgba(111,111,111,0.5);">
                        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="border-bottom: 1px solid rgba(111,111,111,0.5);    padding: unset;">
                        <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12" style="padding:11px" >
                            <img src={{items.imageUrl}} style="height: 130px;width: 130px;border-radius: 20px;" class="center-block" />
                        </div>
                        <div class="col-lg-7 col-md-7 col-xs-6 col-sm-6" style="padding: 20px;">
                            <h4 style="font-family:Ubuntu-Medium ;margin-top: unset;font-size: 21px;" >{{items.itemName}}</h4>
                        <p style="color: #555;margin-bottom: unset;font-size: 18px;"> {{items.location}}</p>
                        <p style="color: #555;font-size: 18px;">Delivered on {{items.deliveryDate}}  </p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding: 12px;padding-right: unset;">
                        <span style="font-size: 20px;font-family:Ubuntu-Medium !important; padding-right: 20px;padding-left: 20px;color: #555;"> Add Your Review</span>
                        <span><i class="fa fa-star fa-2x" style="color:silver;padding: 4px;" (click)= "Ratings('1',items.menuVarianceId)" ></i></span>
                        <span><i class="fa fa-star  fa-2x" style="color: silver;padding: 4px" (click)= "Ratings('2',items.menuVarianceId)"></i></span>
                        <span><i class="fa fa-star fa-2x" style="color: silver;padding: 4px" (click)= "Ratings('3',items.menuVarianceId)"></i></span>
                        <span><i class="fa fa-star  fa-2x" style="color:silver;padding: 4px" (click)= "Ratings('4',items.menuVarianceId)"></i></span>
                        <span><i class="fa fa-star  fa-2x" style="color:silver;padding: 4px" (click)= "Ratings('5',items.menuVarianceId)"></i></span>
                    </div>
                    </div>
        <div class="col-lg-1"></div>
       
        
                </div>
            </div>
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" id="Revs" style="padding-top: 72px;padding-bottom: 72px;">
                   
                    
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="padding: 20px;" *ngFor="let item of ReviewHistory"  >
                       
                        <div class="col-lg-12 col-xs-12 col-sm-12 colxs-12" style="padding: 10px;border:1px solid rgba(111,111,111,0.5);border-radius:8px">
                         <div class="col-lg-2 col-md-2 col-xs-2 col-md-2" *ngIf='item.profileUrl == "" ? true:false' >
                             <img src="../../assets/Images/icon/avatar.jpg" class="center-block" style="width: 60px; height: 60px;border-radius: 34px;border: 1px solid silver;">
                         </div>
                         <div class="col-lg-2 col-md-2 col-xs-2 col-md-2" *ngIf='item.profileUrl == "" ? false:true' >
                             <img src={{item.profileUrl}} class="center-block" style="width: 60px; height: 60px;border-radius: 34px;border: 1px solid silver;">
                         </div>
                         <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                             <p style="margin-bottom: unset;margin-top: 10px;font-family:Ubuntu-Medium !important;">{{item.name}}</p>
                             <p>Rated On {{item.ratedOn | date:'dd/MM/yyyy'}}</p>
                         </div>
                         <div class="col-lg-2 col-md-2 col-xs-2 col-md-2" >
                             <img src={{item.imageUrl}} class="center-block" style="width: 60px; height: 60px">
                         </div>
                         <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"   style="    padding: unset;">
                             <p style="margin-bottom: unset;margin-top: 10px;font-family:Ubuntu-Medium !important;">{{item.itemName}}</p>
                             <span><i class="fa fa-star" [ngStyle]="{'color':item.rating >= 1 ? '#EE562F' : 'silver' }"  style=" font-size: 1.5em;"></i></span>
                             <span><i class="fa fa-star  " [ngStyle]="{'color':item.rating >= 2 ? '#EE562F' : 'silver' }"  style="padding: 4px;font-size: 1.5em"></i></span>
                             <span><i class="fa fa-star" [ngStyle]="{'color':item.rating >= 3 ? '#EE562F' : 'silver' }"  style="padding: 4px;font-size: 1.5em"></i></span>
                             <span><i class="fa fa-star " [ngStyle]="{'color':item.rating >= 4 ? '#EE562F' : 'silver' }"  style="padding: 4px;font-size: 1.5em"></i></span>
                             <span><i class="fa fa-star" [ngStyle]="{'color':item.rating >= 5 ? '#EE562F' : 'silver' }" style="padding: 4px;font-size: 1.5em"></i></span>
                         </div>
                     </div>
                     </div>
                    
                </div>
            
    </div>
</div>


<ng-template #CustomerDetails let-modal >
    
    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">Profile Details</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
 <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
    <span [hidden]="isShownErrorAddress" style="color: red;" >{{AddressErrorMsg}}</span>
    <span [hidden]="isShownSuccessAddress" style="color: green;" >{{AddressSuccessMsg}}</span>
 </div>
  
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
        <div class="form-group" style="padding-top:10px ;">
            <input type="text" placeholder="First Name" [(ngModel)]="Name" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Last Name" [(ngModel)]="LastName" class="form-control line" style="font-family: Ubuntu-Medium !important;"  />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Email ID " [(ngModel)]="EmailId" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Mobile Number " [(ngModel)]="Mobileno" maxlength="13" (ngModelChange)="onprofileMobileChange($event)" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="file"  [(ngModel)]="Filepng" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        
        <div class="form-group" style=" margin-top: 10px; margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;">
           
            <h3 class="" (click)="UpdateaddressClick()" style="font-family: Ubuntu-Medium !important;text-align: center;margin-top: 10px;color: white;">SUBMIT</h3>
        </div>

      
    </div>
  
    </div>
  
   
  
  </ng-template>
