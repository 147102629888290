<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<script src="../../assets/framework.js"></script>

<div class="ms-aside-overlay ms-overlay-left ms-toggler" data-target="#ms-side-nav" data-toggle="slideLeft"></div>
  <div class="ms-aside-overlay ms-overlay-right ms-toggler" data-target="#ms-recent-activity" data-toggle="slideRight"></div>
  <aside id="ms-side-nav" class="side-nav fixed ms-aside-scrollable ms-aside-left ps ps--active-y">

    <!-- Logo -->
    <div class="logo-sn ms-d-block-lg">
      <a class="pl-0 ml-0 text-center" > <img src="../../assets/Images/icon/Logo (2).png" alt="logo"> </a>
    </div>
  
     <!-- Navigation -->
    <ul class="accordion ms-main-aside fs-14" id="side-nav-accordion" style="padding-top: 15px;">
      <!-- Dashboard -->
      <li class="menu-item">
          <a href="#"  [routerLink]="'/AdminDashboard'" >
             <span><img src="../../assets/Images/icon/Home.png" style="    padding-right: 11px;"> Home </span>
           </a>
         
      </li>
      <!-- /Dashboard -->
      <!-- product -->
  
      <li class="menu-item">
          <a href="#" [routerLink]="'/AdminHome'">
               <span> <img src="../../assets/Images/icon/Menu.png" style="    padding-right: 11px;"> Menu </span>
           </a>
        
      </li>
      <li class="menu-item">
        <a href="#"  [routerLink]="'/AdminViewOrder'">
             <span> <img src="../../assets/Images/icon/Orders.png" style="    padding-right: 11px;">View Order </span>
         </a>
      
    </li>
      <li class="menu-item">
                <a href="" [routerLink]="'/AdminWaiter'">
                 <span> <img src="../../assets/Images/icon/Waiter.png" style="    padding-right: 11px;">Waiter</span>
                </a>
              </li>
  <li class="menu-item">
                      <a  [routerLink]="'/AdminTable'">
                        <span> <img src="../../assets/Images/icon/Table.png" style="    padding-right: 11px;">Table</span>
                      </a>
                    </li>
                    <li class="menu-item">
                        <a  class="has-chevron" href="javascript:void(0);" data-toggle="collapse" data-target="#product" >
                             <span>  <img src="../../assets/Images/icon/Reports.png" style="   padding-right: 11px;">Master </span>
                        </a>
                        <ul id="product" class="collapse">
                          <li> <a [routerLink]="'/AdminCategory'">Add Category</a>
                          </li>
                          <li> <a [routerLink]="'/AdminSubCategory'">Add Subcategory</a>
                          </li>
                          <li> <a [routerLink]="'/AdminOffer'">  Discounts / Offers</a>
                          </li>
                          <li> <a [routerLink]="'/AdminCoupon'">  Coupons</a>
                          </li>
                          <li> <a [routerLink]="'/Adminworking'">Restaurant Working Hours</a>
                          </li>
                          <li> <a [routerLink]="'/AdminHoliday'">  Restaurant Holiday</a>
                          </li>
                          <li> <a [routerLink]="'/AdminPrinter'">  Printer Config</a>
                          </li>
                        </ul>
                      </li>
                    <li class="menu-item">
                        <a  [routerLink]="'/AdminTips'">
                          <span> <img src="../../assets/Images/icon/Tips.png" style="    padding-right: 11px;">Tips</span>
                        </a>
                      </li>
                      <li class="menu-item">
                        <a href="" [routerLink]="'/AdminTax'">
                          <span> <img src="../../assets/Images/icon/Tax.png" style="    padding-right: 11px;">Tax</span>
                        </a>
                      </li>
                    <li class="menu-item">
                        <a href="#"  [routerLink]="'/AdminRating'" >
                             <span> <img src="../../assets/Images/icon/Rating.png" style="    padding-right: 11px;">Rating </span>
                         </a>
                   
                    </li>
           
            <li class="menu-item">
                <a href="#"  [routerLink]="'/AdminReports'"  >
                     <span>  <img src="../../assets/Images/icon/Reports.png" style="    padding-right: 11px;">Reports </span>
                 </a>
              
            </li>
  
            
            <li class="menu-item" >
              <a href="" [routerLink]="'/Adminprofile'" >
                    <span> <img src="../../assets/Images/icon/Profileupdate.png" style="    padding-right: 11px;">Profile Update</span>
              </a>
            </li>
        <!-- /Advanced UI Elements -->
        <li class="menu-item">
            <a  [routerLink]="'/Admin'">
              <span><img src="../../assets/Images/icon/Logout.png" style="    padding-right: 11px;">Logout</span>
            </a>
        </li>
    </ul>
    <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; height: 390px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 131px;"></div></div>
    </aside>
    <main class="body-content">

        <!-- Navigation Bar -->
        <nav class="navbar ms-navbar" style=" margin: unset;">
            <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
            </div>
            <div class="logo-sn logo-sm ms-d-block-sm">
              <a class="pl-0 ml-0 text-center navbar-brand mr-0" href="index.html"><img src="../../assets/Images/icon/Logo (2).png" alt="logo"> </a>
            </div>
            <ul class="ms-nav-list ms-inline mb-0" id="ms-nav-options">
              <li class="ms-nav-item ms-search-form pb-0 py-0">
                <form class="ms-form" method="post">
                  <div class="ms-form-group my-0 mb-0 has-icon fs-14">
                    <input type="search" class="ms-form-input" name="search" placeholder="Search here..." value=""> <i class="fa fa-search text-disabled"></i>
                  </div>
                </form>
              </li>
              <li class="ms-nav-item dropdown"> <a href="#" class="text-disabled ms-has-notification" id="mailDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-envelope FA-2X" style="color: black"></i></a> <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="mailDropdown">
                  <li class="dropdown-menu-header">
                    <h6 class="dropdown-header ms-inline m-0"><span class="text-disabled">Mail</span></h6><span class="badge badge-pill badge-success">3 New</span>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li class="ms-scrollable ms-dropdown-list ps">
                    <a class="media p-2" href="#">
                      <div class="ms-chat-status ms-status-offline ms-chat-img mr-2 align-self-center">
                       
                      </div>
                      <div class="media-body"> <span>Hey man, looking forward to your new project.</span>
                        <p class="fs-10 my-1 text-disabled"><i class="material-icons">access_time</i> 30 seconds ago</p>
                      </div>
                    </a>
                    <a class="media p-2" href="#">
                      <div class="ms-chat-status ms-status-online ms-chat-img mr-2 align-self-center">
                      </div>
                      <div class="media-body"> <span>Dear John, I was told you bought Costic! Send me your feedback</span>
                        <p class="fs-10 my-1 text-disabled"><i class="material-icons">access_time</i> 28 minutes ago</p>
                      </div>
                    </a>
                    <a class="media p-2" href="#">
                      <div class="ms-chat-status ms-status-offline ms-chat-img mr-2 align-self-center">
                      </div>
                      <div class="media-body"> <span>How many people are we inviting to the dashboard?</span>
                        <p class="fs-10 my-1 text-disabled"><i class="material-icons">access_time</i> 6 hours ago</p>
                      </div>
                    </a>
                  <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></li>
                  <li class="dropdown-divider"></li>
                  <li class="dropdown-menu-footer text-center"> <a href="pages/apps/email.html">Go to Inbox</a>
                  </li>
                </ul>
              </li>
              <li class="ms-nav-item dropdown"> <a href="#" class="text-disabled ms-has-notification" id="notificationDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-bell FA-2X" style="color: black"></i></a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="notificationDropdown">
                  <li class="dropdown-menu-header">
                    <h6 class="dropdown-header ms-inline m-0"><span class="text-disabled">Notifications</span></h6><span class="badge badge-pill badge-info">4 New</span>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li class="ms-scrollable ms-dropdown-list ps">
                    <a class="media p-2" href="#">
                      <div class="media-body"> <span>12 ways to improve your crypto dashboard</span>
                        <p class="fs-10 my-1 text-disabled"><i class="material-icons">access_time</i> 30 seconds ago</p>
                      </div>
                    </a>
                    <a class="media p-2" href="#">
                      <div class="media-body"> <span>You have newly registered users</span>
                        <p class="fs-10 my-1 text-disabled"><i class="material-icons">access_time</i> 45 minutes ago</p>
                      </div>
                    </a>
                    <a class="media p-2" href="#">
                      <div class="media-body"> <span>Your account was logged in from an unauthorized IP</span>
                        <p class="fs-10 my-1 text-disabled"><i class="material-icons">access_time</i> 2 hours ago</p>
                      </div>
                    </a>
                    <a class="media p-2" href="#">
                      <div class="media-body"> <span>An application form has been submitted</span>
                        <p class="fs-10 my-1 text-disabled"><i class="material-icons">access_time</i> 1 day ago</p>
                      </div>
                    </a>
                  <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></li>
                  <li class="dropdown-divider"></li>
                  <li class="dropdown-menu-footer text-center"> <a href="#">View all Notifications</a>
                  </li>
                </ul>
              </li>
      
              <li class="ms-nav-item ms-nav-user dropdown">
                <a href="#" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img class="ms-user-img ms-img-round float-right" src="../../assets/Images/icon/avatar.jpg" alt="people">
                </a>
                <ul class="dropdown-menu dropdown-menu-right user-dropdown" aria-labelledby="userDropdown">
                  <li class="dropdown-menu-header">
                    <h6 class="dropdown-header ms-inline m-0"><span class="text-disabled">Welcome, Anny Farisha</span></h6>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li class="ms-dropdown-list">
                    <a class="media fs-14 p-2" href="pages/prebuilt-pages/user-profile.html"> <span><i class="flaticon-user mr-2"></i> Profile</span>
                    </a>
                    <a class="media fs-14 p-2" href="pages/apps/email.html"> <span><i class="flaticon-mail mr-2"></i> Inbox</span> <span class="badge badge-pill badge-info">3</span>
                    </a>
                    <a class="media fs-14 p-2" href="pages/prebuilt-pages/user-profile.html"> <span><i class="flaticon-gear mr-2"></i> Account Settings</span>
                    </a>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li class="dropdown-menu-footer">
                    <a class="media fs-14 p-2" href="pages/prebuilt-pages/lock-screen.html"> <span><i class="flaticon-security mr-2"></i> Lock</span>
                    </a>
                  </li>
                  <li class="dropdown-menu-footer">
                    <a class="media fs-14 p-2" href="pages/prebuilt-pages/default-login.html"> <span><i class="flaticon-shut-down mr-2"></i> Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" data-toggle="slideDown" data-target="#ms-nav-options"> <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
            </div>
          </nav>

          <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;padding-top: 9px;">
             
            
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Holiday Details</h4>
          </div>
          <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
                  <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;" (click)="Show()"><i class="fa fa-plus-circle"></i> Add Holiday</button>
              </div>
          </div>
            <div *ngIf="!Drop" style="    margin-top: 20px;"  id="Menus">
<div class="col-md-12" id="Menus">
    <div class="">
        <button id="Menu" tabindex="92" class="accordion" style="display: block;">Holiday Details</button>
    </div>

  
</div>
<div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
    <div class="col-md-12">
       
          <div class="col-md-3 ">
            <div class="form-group">
                <label class="control-label">Resturant</label>
             <select class="form-control"  [(ngModel)]="Res_Id">
                 <option disabled selected value=0>Select Resturant</option>
                 <option *ngFor="let res of ResArray" value={{res.restaurantId}}>{{res.name}}</option>

             </select>
          </div>
        </div>
        
    </div>
    <div class="col-md-12">
       
        <div class="col-md-3 ">
          <div class="form-group">
              <label class="control-label"> Month</label>
              <select class="form-control" [(ngModel)]="Month" (change) ="Dayrange(Month)" ngDefaultControl>
                <option disabled selected value=0>Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
            </select>
        </div>

       
      </div>
      <div class="col-md-3 ">
        <div class="form-group">
            <label class="control-label" >Day</label>
            <select class="form-control" [(ngModel)]="Datelist"  ngDefaultControl >
              <option disabled selected value=0>Select Day</option>
          <option *ngFor="let dat of Day" value={{dat.Date}} >{{dat.Date}}</option>
          </select>
      </div>
    </div>
  </div>

  <div class="col-md-12" style="padding-bottom: 25px;">
    <div class="col-md-6">
        <div class="form-group">
            <label class="control-label">Description</label>
         <input type="text" class="form-control" style="height: 50px;" [(ngModel)]="Description">
    </div>
    </div>
    <div class="col-md-6">
        <div class="col-md-3" style="padding-top: 39px;"  *ngIf="Activebutton">
            <input type="checkbox" checked (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
        </div>
        <div class="col-md-3" style="padding-top: 39px;"   *ngIf="Activebutton1">
          <input type="checkbox" (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
      </div>

        <div class="col-md-3"  style="padding-top: 22px;" *ngIf="Saveshow">
            <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Save()">SAVE</button>
        </div>
        <div class="col-md-3"  style="padding-top: 22px;" *ngIf="!Saveshow">
          <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Update()">Update</button>
      </div>
        <div class="col-md-3"  style="padding-top: 22px;">
            <button class="btn " style="background: blue; color: white;;border-radius: 4px" (click)="clear()">CLEAR</button>
        </div>
    </div>
</div>
<div class="col-md-12">
  <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
  <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
</div>
</div>



</div>
</div>
    <!--/span-->
      

          <div class="ms-content-wrapper">
            <div class="row">
      
              <div class="col-md-12">
      
                <div class="ms-panel">
                  <div class="ms-panel-body">
                    <div class="table-responsive">
                      <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="row"><div class="col-sm-12 col-md-6">
                              <div class="dataTables_length" id="data-table-5_length">
                                <label style="padding-right: 25px;">Holiday Details</label>
                                <input type="text" matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" style="border: unset;background:  #ececec;height: 37px;width: 76%;"> <i class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                            </div></div><div class="col-sm-12 col-md-6">
                              <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                                    
                                    <button class="btn" style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;" (click)="exportexcel()" >Export</button>
                            <label style="padding-left: 50px;">Show</label>
                            <label class="switch" style="margin-left: 10px;">
                                <input type="checkbox" checked (change)="ActiveCheckboxChange($event)">
                                <span class="slider round"></span>
                              </label>
                                </div></div>
                              </div>
                              <div class="mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                                <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSort >
                                 
                                  <!-- Position Column -->
                                  <ng-container matColumnDef="sno" >
                                    <th mat-header-cell *matHeaderCellDef  style="color: white !important;"> S.NO </th>
                                    <td mat-cell  *matCellDef="let item; let j = index"> {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                                  </ng-container>
                              
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> Restaurant Name </th>
                                    <td mat-cell *matCellDef="let element" (click)="HolidayEdit(element.holidayMasterId)"> <a>{{element.name}}</a>  </td>
                                  </ng-container>
                              
                                  <!-- Weight Column -->
                                  <ng-container matColumnDef="dateTo" >
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;">Month </th>
                                    <td mat-cell *matCellDef="let element"  (click)="HolidayEdit(element.holidayMasterId)"> {{element.hmonth}} </td>
                                  </ng-container>
                              
                                  <!-- Symbol Column -->
                                  <ng-container matColumnDef="dateFrom" >
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> Date </th>
                                    <td mat-cell *matCellDef="let element"  (click)="HolidayEdit(element.holidayMasterId)">  {{element.hday}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="description" >
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> Description </th>
                                    <td mat-cell *matCellDef="let element"  (click)="HolidayEdit(element.holidayMasterId)">  {{element.description}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="Status" >
                                    <th mat-header-cell *matHeaderCellDef  style="color: white !important;text-align: center;"> Active </th>
                                    <td mat-cell *matCellDef="let element"> 
                                      <label class="switch" style="margin-left: 10px;">
                                        <input type="checkbox" checked *ngIf="element.active=='Active' ? true :false">
                                        <input type="checkbox"  *ngIf="element.active=='Active' ? false :true">
                                        <span class="slider round"></span>
                                      </label>
                                   </td>
                                  </ng-container>
                                
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                              
                                <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>
                              </div>

                                                     
                                                                  </div>
                                                                  </div>
                    </div>
                  </div>
                </div>
      
              </div>
      
            </div>
     
    
      </main>
  
