<title>Privacy</title>
<div style="padding: 10px 20px 0px 60px ;"><a href="/"><img src="../../assets/Images/Homeicon.png">Home </a> &nbsp;/&nbsp;<span
        style="font-family: Ubuntu-Regular !important;">Privacy Polices</span></div>
<div class="container" style="padding-top: 30px;">
    <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;">Zapata's Privacy Polices</h4>
    <br>
    <p style="font-size: 16px; text-align:justify ;">We stress the importance of privacy and are committed to earning
        your trust by adopting high standards for the protection of your personal information.
    </p><br>
    <p style="font-size: 16px; text-align:justify ;">This policy applies to our website(s) and outlines the type of
        personal information we collect and receive, the circumstances in which we collect or receive personal
        information, the policies and procedures we have established outlining its use and storage, and for sharing
        certain types of personal information in certain limited circumstances, the procedures you should follow if you
        have any questions or requests in respect of your personal information or our policies and procedures and the
        person to whom such questions or requests should be directed, and the means by which to communicate with that
        person.
    </p><br>
    <p style="font-size: 16px; text-align:justify ;">We do not control and are not responsible for the policies or
        actions of third parties linked from this site and you should check directly with them since their policies and
        terms will govern how they handle and treat personal information.</p>
    <p style="font-size: 16px; text-align:justify ;">
        In this policy, 'personal information' or 'personally identifiable information' means information about you that
        is unique and would actually or potentially identify you as an individual, like your name, address, e-mail
        address or phone number, and that is not otherwise publicly available and is not part of your work
        identification and includes information you provide to us when you use our website to make or modify a
        reservation, establish a profile, communicate personal preferences, participate in, respond to or take advantage
        of special offers, or which you otherwise provide to us in the course of using of our website. Aggregate
        information or statistics, even if compiled or derived from your personal information and then aggregated and
        mingled with information of others, is not personal information since it does not identify you or any specific
        personally identifiable information about you. We use aggregate or statistical information to better serve our
        guests and customers generally, to enhance the performance of our website and to generally improve how we do
        business.
    </p>
    <p style="font-size: 16px; text-align:justify ;">
        We will provide you with opportunities to 'unsubscribe' and to correct or update your personal information and
        described these opportunities below, but you are responsible for ensuring the information you provide to us and
        that we maintain is complete, accurate and up to date. We cannot and will not be liable to you or any other
        party if, for any reason, you do not provide us with complete, accurate and current information or you fail to
        update such information in a timely manner.
    </p><br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">HOW YOU MAY PROVIDE US YOUR PERSONAL INFORMATION
        USING OUR WEBSITE</h4>

    <ol type="1">
        <li>By making a reservation or by using the website.</li>
        <li>By communicating with us regarding service delivery including any profiles, preferences or special requests
            you may make.</li>
        <li>By participating in a marketing initiative, survey or responding to special offers we may send you from time
            to time.</li>
        <li>By registering or otherwise entering information on or through our website.</li>
    </ol>
    <p style="font-size: 16px; text-align:justify ;">Below we will outline the type of information normally collected in
        each of these circumstances, the reasons for doing so, how we intend to use it and store it.</p>
        <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">MAKING A RESERVATION/COMPLETING FORMS</h4>
    <p style="font-size: 16px; text-align:justify ;">When making a reservation or using forms on the website it is
        necessary to have information in order to identify you, contact you and to process your purchase and requests.
        This usually includes your name, address, phone number, e-mail address, credit card number and expiration date,
        and language preference.</p>
    <p style="font-size: 16px; text-align:justify ;">We may use the information you provide to send you offers and
        information about the hotel’s services. Except as we have described in this policy, your personal information
        will not be given to these third parties.</p><br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">
        WE OFFER YOU THE ABILITY TO RECEIVE TARGETED, TIMELY NOTIFICATION OF TIME SENSITIVE OFFERS
    </h4>
    <p style="font-size: 16px; text-align:justify ;">To sign up for this service we require you to provide us with your
        e-mail address, first name, last name, and zip code. You may unsubscribe at any time using the link provided on
        every offer we send you. Of course, if you change your mind, you may subscribe again at any time in the future.
    </p><br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">BY VISITING OUR WEBSITE</h4>
    <p style="font-size: 16px; text-align:justify ;">Our website does not collect personally identifiable information
        from you or your computer when you simply visit (e.g., browse) and unless you actually provide us with
        personally identifiable information, we will not know or collect your name, your e-mail address or any other
        personally identifiable information about you. When you a request or visit a page on our website, we do log
        certain communications, technical and operational information and aggregate it with other similar information in
        order to make our website function correctly, do capacity and other technical planning and generally make our
        website function properly and improve it where we can. We also use this information to better understand how
        visitors use our website and how we can better tailor our website, its contents and functionality to meet your
        needs.</p><br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">WHY WE COLLECT PERSONAL INFORMATION</h4>
    <ol type="1">
        <li>To establish, maintain and honor our relationship with you and to provide you with our services and those of
            our third party suppliers and promotional partners.</li>
        <li>To understand and better attempt to fulfill your needs and preferences in providing services from us.</li>
        <li>To develop, enhance, market or provide products and services or offers we believe, based on the information
            you provide us, you may be interested in receiving.</li>
        <li>To manage and develop our business and operations and help us improve our services for you.</li>
        <li>To meet legal and regulatory requirements.</li>
    </ol>
    <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;"> WHEN DO WE DISCLOSE PERSONAL INFORMATION</h4>
    <ol type="1">
        <li>Personal information may be shared internally, with corporate offices or sister properties so that they may
            provide the services you have reserved.</li>
        <li>Personal information may be shared with a person who, in the reasonable judgment of the hotel, is acting on
            your behalf of or as your agent - for example, a travel agent, secretary or corporate travel department,
            making a reservation for you.</li>
        <li>Personal information may be shared with a third party involved in processing transactions you request and
            supplying you with services.</li>
        <li>Personal information may be shared with a third party to perform functions, process transactions or provide
            goods or services to you, such as reservations handling, data processing or storage, surveys or research, to
            evaluate a customer's credit worthiness or in order to collect a customer's account.</li>
        <li>Personal information may be shared with a public authority or official or those acting under government or
            judicial authority to protect the assets and operations of the hotel or if, in the reasonable judgment of
            the hotel, it appears that there may be danger to life, property or public safety which could be avoided or
            minimized by disclosure of the information, or which disclosure is required by law or regulation.</li>

    </ol>
    <br>
    <h4 style="font-size: 22px;font-family:Ubuntu-Medium !important ;">PRINCIPLES</h4>
    <ol type="1">
        <li>We will not collect, use or disclose your personal information for any other purpose than those identified
            above, except with your consent.

        </li>
        <li>We will safeguard your personal information from disclosure other than as identified above or with your
            consent.</li>
        <li>We will take steps to protect the confidentiality of your personal information when dealing with third
            parties, consistent with our policy and the laws and regulations that apply.</li>
        <li>We will strive to keep your personal information accurate and up to date and we will provide you with
            reasonable opportunities to access, correct and update your personal information.
            You are always free to refuse to provide personal information to us, recognizing that in some cases this may
            limit or make it impossible for us to agree to provide you with the services or goods you may request.
        </li>
    </ol>
    <p style="font-size: 16px; text-align:justify ;">
        You may also withdraw your consent with respect to the use of your personal information for marketing purposes
        at any time, subject to legal or contractual restrictions and reasonable notice, when you access or visit our
        website or by separately e-mailing us utilizing the contact information provided on this site using
        "Unsubscribe" as the subject line, and providing us sufficient personal identifiers so we can act effectively on
        your request.
    </p>
    <p style="font-size: 16px; text-align:justify ;">
        If you have questions or concerns about our privacy practices or how we collect, handle or use your personal
        information, please contact us via the contact information provided on this site.
    </p>
</div><br>