import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import{ HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { StoragedetectComponent } from '../storagedetect/storagedetect.component';
@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css'],
  
  encapsulation: ViewEncapsulation.None
})
export class SearchresultComponent implements OnInit {
  
  MoreDetails=true;
  SplArray:[];
  Search:string;
  addtocartitem=[] as any;
  closeResult:string;
  AddonText:string;
  data=[] as any;
  constructor(private modalService: NgbModal, private route: ActivatedRoute,private http:HttpClient,private _storageService: StoragedetectComponent)  {
    this.Search=this.route.snapshot.params.searchtext;
   }
  LoadDetail(){
    this.MoreDetails=false;
  }

  AddonCartitems(Addon:any){
    debugger;
   
    this.modalService.dismissAll(Addon);
    var tes =Addon.adddon;
       this.addtocartitem=Addon;
  var Quan=Addon.quan;
  
      this.data=JSON.parse(localStorage.getItem('CartItem' ));
    var check=null;
  
    var test =Addon.menuvariance["0"].imageUrl1;
   
    if(this.data != null){
      
      if(this.data.length !=0){
        for (var i =   this.data.length - 1; i >= 0; i--) {
          var tes=Addon.menuvariance["0"].salestax;
          if (  this.data[i].MenuVarianceId == Addon.menuvariance["0"].menuVariance1) {
            check="entry";
            Quan= Quan+this.data[i].Quan;
            this.data.splice(i, 1);
            this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ?  "-" +Addon.menuvariance["0"].menuvarianceName1 :"") ,couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan});   
        //    this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price: (this.addtocartitem.price),orginalpric:this.addtocartitem.price});
          }
           
      }
      if(check != "entry"){
        this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName  +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ?  "-" +Addon.menuvariance["0"].menuvarianceName1 :""),couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan});  
        //  this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price});
      }
    
  
    }
    else{
      this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName  +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ?  "-" +Addon.menuvariance["0"].menuvarianceName1 :""),couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan});  
      //this.data.push({Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price});
    }
  
    }
    
      else{
      //  this.data=[{Addon:Addon.adddon,Quan:Quan, MenuVarianceId:this.addtocartitem.menuVariance, ImageUrl:this.addtocartitem.imageUrl,itemName:this.addtocartitem.itemName,couponAmt:this.addtocartitem.couponAmt,couponType:this.addtocartitem.couponType,price:this.addtocartitem.price,orginalpric:this.addtocartitem.price}];
      this.data=[{Addon:Addon.adddon,Quan:Quan, MenuVarianceId:Addon.menuvariance["0"].menuVariance1, ImageUrl:Addon.menuvariance["0"].imageUrl1,itemName:Addon.itemName  +" " +(Addon.menuvariance["0"].menuvarianceName1 != null && Addon.menuvariance["0"].menuvarianceName1 != "" ? "-" +Addon.menuvariance["0"].menuvarianceName1 :""),couponAmt:this.addtocartitem.discountAmt,couponType:this.addtocartitem.discountType,price:Addon.menuvariance["0"].price1,orginalpric:Addon.menuvariance["0"].price1,sTax:Addon.salestax,hTax:Addon.hospitalitytax,oTax:Addon.othertax,discountQuan:Addon.discountQuan}];  
    }
  
     
    var resid=   localStorage.getItem('ResturntId');
  localStorage.clear();
  localStorage.setItem('ResturntId',resid);
  this._storageService.setItem('CartItem',JSON.stringify(this.data));
    //  localStorage.setItem('CartItem',JSON.stringify(this.data) );
      localStorage.setItem('Addon',tes);
      this.check();
      alert("Selected Items added to Cart");
    //  window.location.href="";
     // return false;
    }
    Addvariance(item:any,itemvar){
      debugger;
      var demote =item.menuvariance["0"].price1;
      for(let i=0;i<item.menuvariance.length;i++ ){
       debugger;
        if(item.menuvariance[i].menuVariance==itemvar){
          item.menuvariance["0"].price1=item.menuvariance[i].price;
          item.menuvariance["0"].imageUrl1=item.menuvariance[i].imageUrl;
          item.menuvariance["0"].description1=item.menuvariance[i].description;
          item.menuvariance["0"].menuvarianceName1=item.menuvariance[i].menuvarianceName;
          item.menuvariance["0"].menuVariance1=item.menuvariance[i].menuVariance;
        }
      }
    
    }
  Fav(item:any){
    debugger;
    var tes = item;
       var UserId= sessionStorage.getItem('CustomerId');
       var Res=localStorage.getItem('ResturntId');
       var GustId= sessionStorage.getItem('GuesId');
       if(UserId ==null || UserId =="" ){
        if(GustId != null && GustId != ""){
         
        }else{
         alert("Please Sign in to Add Favuroite");
         return false;
        }
      }
       debugger;
       
        var value=this.http.get(environment.apiUrl +'Fav/Add?MenuVariance_ID='+item.menuVariance+'&UserId='+GustId+'&CustomerDetails_ID='+UserId+'&Qty='+item.quan+'&Restaurant_ID='+Res).toPromise().then((data:any)=>{
    debugger;
 
        }).catch((data: any) => {
          debugger;
          var vDataError = JSON.stringify(data.error.text);
          var vErrorMsg = vDataError.replace('"', '');
          var vErrorMsg1 = vErrorMsg.replace('"', '');
          if(vErrorMsg1 == "Success"){
            alert("Selected Item(s) added to Favourites");
            this.check();
          }else if(vErrorMsg1 =="Update"){
            alert("Selected Item(s) removed from Favourites");
            this.check();
          }
          
              });
      }
      debugger;
    
      check(){
        var Res= localStorage.getItem('ResturntId');
 var UserId=sessionStorage.getItem('CustomerId');
 var GustId= sessionStorage.getItem('GuesId');
    var value=this.http.get(environment.apiUrl +'MenuItem/Searchresult?search='+this.Search +'&ResId='+Res +'&User='+UserId +'&Gust='+GustId).toPromise().then((data:any)=>{
debugger;  
if(data.length!=0 ){
  this.SplArray=data;
}
else{
  alert("No Product in Search Result");
};
    });
      }
  ngOnInit(): void {
    var Res= localStorage.getItem('ResturntId');
 var UserId=sessionStorage.getItem('CustomerId');
 var GustId= sessionStorage.getItem('GuesId');
    var value=this.http.get(environment.apiUrl +'MenuItem/Searchresult?search='+this.Search +'&ResId='+Res +'&User='+UserId +'&Gust='+GustId).toPromise().then((data:any)=>{
debugger;  
if(data.length!=0 ){
  this.SplArray=data;
}
else{
  alert("No Product in Search Result");
};
    });
    var vmenuShow1 = document.getElementById("Filter");
vmenuShow1.style.display = 'none';
var vCategoryShow = document.getElementById("menuCategory");
vCategoryShow.style.display = 'none';
var vmenuShow = document.getElementById("PriceShow");
vmenuShow.style.display = 'none';
var vmenuShow = document.getElementById("Payment");
vmenuShow.style.display = 'none';
  }
  ShowHidePrice (menu) {
    debugger;
    var x = document.getElementById(menu);
  
    if (x.style.display == "block") {
        x.style.display = "none";
        document.getElementById("priceplus").className = "glyphicon glyphicon-plus pull-right";
    }
    else {
        x.style.display = "block";
        document.getElementById("priceplus").className = "glyphicon glyphicon-minus pull-right";
    }
  }
  menuopen(menu) {
    debugger;
    var x = document.getElementById(menu);

    if (x.style.display == "block") {
        x.style.display = "none";
      
    }
    else {
        x.style.display = "block";
    }
 }
  ShowHideCategory(menu) {
    debugger;
    var x = document.getElementById(menu);
  
    if (x.style.display == "block") {
        x.style.display = "none";
        document.getElementById("Categoryplus").className = "glyphicon glyphicon-plus pull-right";
    }
    else {
        x.style.display = "block";
        document.getElementById("Categoryplus").className = "glyphicon glyphicon-minus pull-right";
  
    }
  }
  ShowHidePayment (menu) {
    debugger;
    var x = document.getElementById(menu);
  
    if (x.style.display == "block") {
        x.style.display = "none";
        document.getElementById("Paymentplus").className = "glyphicon glyphicon-plus pull-right";
    }
    else {
        x.style.display = "block";
        document.getElementById("Paymentplus").className = "glyphicon glyphicon-minus pull-right";
    }
  }

  open(content,item:any) {
    debugger;
    this.addtocartitem=item;
      this.modalService.open(content,{ windowClass: 'custom-class'} ).result.then((result) => {
    
        this.closeResult = `Closed with: ${result}`;
    
      }, (reason) => {
    
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    
      });
    }
    
    private getDismissReason(reason: any): string {
    
      if (reason === ModalDismissReasons.ESC) {
    
        return 'by pressing ESC';
    
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    
        return 'by clicking on a backdrop';
    
      } else {
    
        return  `with: ${reason}`;
    
      }
    
    }
    Adddown(min,item) {
      debugger;
      // var Mynumber = parseInt((document.getElementById("AddNumber") as HTMLInputElement).value) - 1;
      // ((document.getElementById("AddNumber") as HTMLInputElement).value) = String(Mynumber);
      // if (parseInt((document.getElementById("AddNumber") as HTMLInputElement).value) <= parseInt(min)) {
      //   (document.getElementById("AddNumber") as HTMLInputElement).value = min;
      // }
      if(item.quan !=1){
      //  item.price= item.price -item.orginalpric;
        item.quan=item.quan -1;
      }
     
    }
    Addup(max,item) {
      debugger;
      // var Mynumber = parseInt((document.getElementById("AddNumber") as HTMLInputElement).value) + 1;
      // ((document.getElementById("AddNumber") as HTMLInputElement).value) = String(Mynumber);
      // if (Number(Mynumber) >= Number(max)) {
      //   (document.getElementById("AddNumber") as HTMLInputElement).value = max;
      // }
     // item.price= item.price + item.orginalpric;
    item.quan=item.quan +1;
    }
    AddcartQuant(item){
      debugger;
      if(item.quan != 0){
        item.quan= item.quan ;
      }
      else{
        item.quan= 1 ;
      }
      
    }
}
