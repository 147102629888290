

    <main class="body-content">

        <!-- Navigation Bar -->
        <nav class="navbar ms-navbar" style=" margin: unset;">
            <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
            </div>
            
          </nav>

          <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">
             
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">Table</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
                <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
                    <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;" (click)="Show()"><i class="fa fa-plus-circle"></i> Add Table</button>
                </div>
            </div>
            <div *ngIf="!Drop " style="    margin-top: 20px;">
<div class="col-md-12" id="Menus">
    <div class="">
        <button id="Menu" tabindex="92" class="accordion" style="display: block;">Table Details</button>
    </div>

  
</div>
<div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
    <div class="col-md-3">
        <div class="form-group">
            <label class="control-label">Restaurant</label>
            <select class="form-control"  [(ngModel)]="Res_Id">
              <option disabled selected value=0>Select Resturant</option>
              <option *ngFor="let res of ResArray" value={{res.restaurantId}}>{{res.name}}</option>
       
          </select>
    </div>
    </div>
    
    <!--/span-->
    <div class="col-md-3">
        <div class="form-group">
            <label class="control-label">Table No</label>
        <input type="number" class="form-control" [(ngModel)]="TableNo">   
        </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
          <label class="control-label">Table Seats</label>
      <input type="number" class="form-control" [(ngModel)]="TableSeat">   
      </div>
  </div>
</div>


<div class="col-md-12" style="padding-bottom: 25px;">
    <div class="col-md-6">
        <div class="form-group">
            <label class="control-label">Description</label>
         <input type="text" class="form-control" style="height: 50px;" [(ngModel)]="Description">
    </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-3" style="padding-top: 39px;"  *ngIf="Activebutton">
        <input type="checkbox" checked (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
      </div>
      <div class="col-md-3" style="padding-top: 39px;"   *ngIf="Activebutton1">
      <input type="checkbox" (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
      </div>
      
      <div class="col-md-3"  style="padding-top: 22px;" *ngIf="Saveshow">
        <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Save()">SAVE</button>
      </div>
      <div class="col-md-3"  style="padding-top: 22px;" *ngIf="!Saveshow">
      <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Update()">Update</button>
      </div>
      <div class="col-md-3"  style="padding-top: 22px;">
        <button class="btn " style="background: blue; color: white;;border-radius: 4px" (click)="clear()">CLEAR</button>
      </div>
      </div>
</div>
<div class="col-md-12">
  <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
  <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
  </div>
</div>
    <!--/span-->
        </div>
        <div class="ms-content-wrapper">
            <div class="row">
      
              <div class="col-md-12">
      
                <div class="ms-panel">
                  <div class="ms-panel-body">
                    <div class="table-responsive">
                      <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="row">
                            <div class="col-sm-12 col-md-6">
                              <div class="dataTables_length" id="data-table-5_length">
                                <label style="padding-right: 25px;">Search</label>
                                <input type="text" matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" style="border: unset;background: #ececec;height: 37px;width: 86%;"> <i class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                            </div></div>
                            <div class="col-sm-12 col-md-6"><div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                                  
                                    <button class="btn" style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;" (click)="exportexcel()">Export</button>
                            <label style="padding-left: 50px;">Show</label>
                            <label class="switch" style="margin-left: 10px;">
                                <input type="checkbox" checked  (change)="ActiveCheckboxChange($event)">
                                <span class="slider round"></span>
                              </label>
                                </div>
                              </div>
                              </div>
                                <div class="mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                                  <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSort >
                                   
                                    <!-- Position Column -->
                                    <ng-container matColumnDef="sno" >
                                      <th mat-header-cell *matHeaderCellDef  style="color: white !important;"> S.NO </th>
                                      <td mat-cell *matCellDef="let element;let i=index"> {{i +1}} </td>
                                    </ng-container>
                                
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="name">
                                      <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> Restaurant Name </th>
                                      <td mat-cell *matCellDef="let element" (click)="tableEdit(element.tableId)"> {{element.name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="tableNo">
                                      <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> Table No </th>
                                      <td mat-cell *matCellDef="let element" (click)="tableEdit(element.tableId)"> {{element.tableNo}} </td>
                                    </ng-container>
                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="noofSeat" >
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;">Table Seat </th>
                                      <td mat-cell *matCellDef="let element"  (click)="tableEdit(element.tableId)"> {{element.noofSeat}} </td>
                                    </ng-container>
                                
                                    <!-- Symbol Column -->
                                   
                                    <ng-container matColumnDef="description" >
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> Description </th>
                                      <td mat-cell *matCellDef="let element"  (click)="tableEdit(element.tableId)">  {{element.description}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Status" >
                                      <th mat-header-cell *matHeaderCellDef  style="color: white !important;text-align: center;"> Active </th>
                                      <td mat-cell *matCellDef="let element"> 
                                        <label class="switch" style="margin-left: 10px;">
                                          <input type="checkbox" checked *ngIf="element.active=='Active' ? true :false">
                                          <input type="checkbox"  *ngIf="element.active=='Active' ? false :true">
                                          <span class="slider round"></span>
                                        </label>
                                     </td>
                                    </ng-container>
                                  
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                  </table>
                                
                                  <mat-paginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>
                                </div>
                                                  </div>
                                                    
                                                                  </div>
                    </div>
                  </div>
                </div>
      
              </div>
      
            </div>
         
    
      </main>
  
