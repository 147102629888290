<title>Order Details</title>
<div class="container-fluid" style="padding:50px;">

    <div>
        
<div class="Banner" >
    <div *ngFor='let item of Profilr'>
        <div class="col-lg-2 col-sm-12 col-xs-12 col-md-12"  *ngIf='item.profileImage != "" && item.profileImage != null ? false :true'>
            <img src="../../assets/Images/icon/avatar.jpg"  class="avatar">
    
        </div><div class="col-lg-3 col-sm-12 col-xs-12 col-md-12"  *ngIf='item.profileImage != "" && item.profileImage != null ? true :false'>
            <img src="{{item.profileImage}}"  class="avatar">
    
        </div>
    </div>
    <div class="col-lg-10 col-xs-12 col-md-12 col-sm-12" style="top: 270px;">
       <div class="col-lg-3 col-xs-3 col-md-3 col-sm-3" style="text-align: center;" >
           <h4 style="font-family:Ubuntu-Bold !important;color: white;font-size: 28px;">  {{Name}} {{LastName}}</h4>
       </div>
       <div class="col-lg-3 col-xs-3 col-md-3 col-sm-3" style="border-left: 1px solid white;text-align: center;">
        <h4 style="font-family:Ubuntu-Regular !important;color: white;font-size: 28px;"> {{Mobileno}} </h4>
    </div>
    <div class="col-lg-4 col-xs-3 col-md-3 col-sm-3" style="border-left: 1px solid white;text-align: center;">
        <h4 style="font-family:Ubuntu-Regular !important;color: white;font-size: 28px;"> {{EmailId}} </h4>
    </div>
    <div class="col-lg-2 col-xs-3 col-md-3 col-sm-3" *ngIf='GustStrn == null || GustStrn == "" ? true : false' >
        <button class="btn" style="background-color: #EE562F; color: white;font-size: 20px;font-family:Ubuntu-Bold !important ;" (click)="open(CustomerDetails)">Edit Profile</button>
    </div>
    </div>
</div>

    </div>
</div>

<div class="container-fluid" style="padding:50px;padding-left: 100px;">
    <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6" style="padding-right: unset;">
<h3 style="font-family: Ubuntu-Medium !important;font-size: 25px;">My Account</h3>

<div style="padding-top: 20px;">
    <h3 style="   height: 31px;padding-left: 15px;outline: none; font-family: Ubuntu-Regular !important;font-size: 20px;" [routerLink]="'/MyOrder'">My Order</h3>
</div>

<div style="padding-top: 5px;">
<h3 style=" padding-top: 5px;padding-bottom: 5px; font-family: Ubuntu-Regular !important;font-size: 20px;padding-left: 15px;outline: none; background-color:  #ececec;border-left: 3px solid #EE562F;color: #ee562f" [routerLink]="'/OrderDetail'">Order Details</h3>
</div>
<div style="padding-top: 5px;">
<h3 style="font-family: Ubuntu-Regular !important;font-size: 20px;padding-left: 15px;outline: none;" [routerLink]="'/Review'">Review</h3>
</div>

<div style="padding-top: 5px;">
<h3 style="font-family: Ubuntu-Regular !important;font-size: 20px;padding-left: 15px;outline: none;" [routerLink]="'/MyAddress'">My Address</h3>
</div>

        </div>
        <div class="col-lg-10 colmd-10 col-xs-6 col-sm-6" style="padding-left: unset;">
<table style="width: 100%; border: 1px solid rgba(111, 111, 111, 0.5);" >
    <thead style="padding-top: 20px;">
        <th style="text-align: center;">
            <h3 style="margin-bottom: 30px;">My Order</h3>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th>
        </th>
        
    </thead>
    <thead style="text-align: center; border-bottom: 1px solid;padding-bottom: 10px;">
<th style="text-align: center;    margin-bottom: 28px;">
<p>ORDER NUMBER</p>
</th >
<th style="text-align: center;">
    <p>ORDER DATE & TIME</p>
    </th>
    <th style="text-align: center;">
        <p>ITEM COUNT </p>
        </th>
        <th style="text-align: center;">
            <p>AMOUNT PAID </p>
            </th>
            <th style="text-align: center;">
                <p>ORDER STATUS </p>
                </th>
                
    </thead>
    <tbody>
        <tr style="text-align: center;border-bottom:1px solid rgba(111, 111, 111, 0.5) ;" *ngFor='let item of ReOrder' (click)="open1(Payout,item.order)">
            <td>
                <p>{{item.orderId}}</p>
            </td>
            <td>
                <p>{{item.orderDate}}</p>
            </td>
            <td>
                <p>{{item.itemName}}({{item.qty}})</p>
            </td>
            <td>
                <p>$ {{item.amountPaid}}</p>
            </td>
            <td>
                <p>{{item.type}}</p>
            </td>
          
        </tr>
     
    </tbody>
</table>
        </div>
    </div>
</div>


<ng-template #Payout let-modal >

    <div class="modal-header" style="border: none;padding-bottom: unset;">
  <h4 style="font-size: 18px;padding-left: 25px;font-family: Ubuntu-Bold !important;">Order Details </h4>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true" style="font-size: 34px;">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body">
 
        <div class="">
            <h4 style="font-family: Ubuntu-Bold !important;padding-left: 25px;color: #555;">{{Res_Name}}</h4>
        </div>
  <div class="" style="padding-top: 5px;padding-left: 25px;padding-bottom: 58px;">

    <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
        <span style="font-size: 15px;color: #555;">Order Id </span>
    </div>
    <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
        <span style="color: #555;">{{OrderId}}</span>
    </div>
    <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
       
            <span  style="font-size: 15px;color: #555;">Ordered Date & Time </span>
    </div>
    <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
     
  <span style="color: #555;font-size: 15px;">{{OrderDate}}</span>
 
    </div>
  </div>
  <span></span>
    <div class="" style="border-top: 1px solid rgba(111,111,111,0.5);"></div>
  <div class="col-lg-12 col-md-12">
    <div class="row" style="padding-left: 15px;padding-right: 14px;">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:30px" *ngFor='let item of ItemPrice' >
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6" style="font-family:Ubuntu-Bold !important;">
                <h5  style="font-family:Ubuntu-Bold !important;">{{item.itemName}} </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2" style="font-family:Ubuntu-Bold !important;">
                <h5  style="font-family:Ubuntu-Bold !important;">x{{item.qty}}</h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;font-family:Ubuntu-Bold !important;text-align: center;">
                <h5  style="font-family:Ubuntu-Bold !important;">$ {{item.iTEMprice}}</h5>
            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Discount </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;"> - $ {{CouponAmt | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom: 1px dashed; padding-bottom: 10px;" >
            <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
                <h5 style="font-family:Ubuntu-Bold !important;">Coupon </h5>
            </div>
           
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{Coup | number:'.2'}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom: 10px;border-bottom: 1px dashed;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Sub Total </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;color: #EE562F;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{SubTotal}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Delivery </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{DeliveryAmount}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;border-bottom:1px dashed">
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <h5 style="font-family:Ubuntu-Bold !important;">Tax </h5>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <h5></h5>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="font-family:Ubuntu-Bold !important;">$ {{TaxAmt}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:15px;">
            
            <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12"  style=" padding: unset;">
                <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                    <h5 style="font-family:Ubuntu-Bold !important;">Order Total </h5>
                </div>
                <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                    <h5></h5>
                </div>
                <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4" style="padding-right: unset;text-align: center;">
                    <h5 style="color:#20c500 ;font-family:Ubuntu-Bold !important;">$ {{OrderTotal}}</h5>
                </div>
               
            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:10px;padding-bottom: 20px;padding-left: unset;">
            <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
                <h5 style="font-family:Ubuntu-Bold !important;color: #EE562F;padding-left: 20px;">Tip Added</h5>
       
            </div>
           
            <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding-right: unset;text-align: center;">
                <h5 style="color:#EE562F;font-family:Ubuntu-Bold !important;">$ {{TipsAmt}}</h5>
            </div>
            <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1" style="padding-top:8px">

            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:15px;padding-bottom:10px ;border-top: 1px solid rgba(111,111,111,0.5);border-bottom: 1px solid rgba(111,111,111,0.5)">
            
            <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style=" padding: unset;" >
                <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                    <h5 style="font-family:Ubuntu-Bold !important;">Net Pay </h5>
                </div>
                <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                    <h5></h5>
                </div>
                <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4" style="padding-right: unset;text-align: center;">
                    <h5 style="color:#20c500 ;font-family:Ubuntu-Bold !important;">$ {{NetPay}}</h5>
                </div>
              
            </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-top:15px;padding-bottom:10px ;border-bottom: 1px solid rgba(111,111,111,0.5)">
            
          <h5 style="font-size: 21px !important;font-family:Ubuntu-Bold !important ;">Delivery Address</h5>
         
          <h5 style="font-size: 18px;padding-top: 10px;font-family:Ubuntu-Medium !important ;">{{delFirstName}}</h5>
          
          <h5  style="font-size: 18px;font-family:Ubuntu-Medium !important ;">{{delAddress1}}, {{delAddrees2}}</h5>
         
          <h5 style="font-size: 18px;font-family:Ubuntu-Medium !important ;">{{delCity}} {{delstate}} {{delZip}}</h5>
           
            <h5 style="font-size: 21px !important;font-family:Ubuntu-Bold !important ;padding-top: 20px;">Mobile Number</h5>
           
            <h5  style="font-size: 18px;font-family:Ubuntu-Medium !important ;padding-top: 10px;">{{delMobile}}</h5>

        </div>

        
       
    </div>
  </div>
   

    </div>
  
   
  
  </ng-template>

  <ng-template #CustomerDetails let-modal >
    
    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">Profile Details</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
 <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
    <span [hidden]="isShownErrorAddress" style="color: red;" >{{AddressErrorMsg}}</span>
    <span [hidden]="isShownSuccessAddress" style="color: green;" >{{AddressSuccessMsg}}</span>
 </div>
  
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
        <div class="form-group" style="padding-top:10px ;">
            <input type="text" placeholder="First Name" [(ngModel)]="Name" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Last Name" [(ngModel)]="LastName" class="form-control line" style="font-family: Ubuntu-Medium !important;"  />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Email ID " [(ngModel)]="EmailId" class="form-control line"  style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="text" placeholder="Mobile Number " [(ngModel)]="Mobileno" maxlength="13" (ngModelChange)="onprofileMobileChange($event)" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        <div class="form-group" style="padding-top:10px ;">
           
            <input type="file"  [(ngModel)]="Filepng" class="form-control line" style="font-family: Ubuntu-Medium !important;" />
        </div>
        
        <div class="form-group" style=" margin-top: 10px; margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;">
           
            <h3 class="" (click)="UpdateaddressClick()" style="cursor: pointer; font-family: Ubuntu-Medium !important;text-align: center;margin-top: 10px;color: white;">SUBMIT</h3>
        </div>

      
    </div>
  
    </div>
  
   
  
  </ng-template>