

    <main class="body-content">

        <!-- Navigation Bar -->
        <nav class="navbar ms-navbar" style=" margin: unset;">
            <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
            </div>
          
          </nav>

          <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;padding-top: 9px;">
             
            <div class="col-md-1 align-self-center">
                <h4 class="text-themecolor">User</h4>
              

            </div>
            <div class="col-md-4 align-self-center ">
               
                    <div >
                        <div class="col-md-4">
                            <label class="" style="padding-top: 5px;"> Restaurant</label>
                        </div>
                        <div class="col-md-8">
                          <select class="form-control"  [(ngModel)]="Res_Id">
                            <option disabled selected value=0>Select Resturant</option>
                            <option *ngFor="let res of ResArray" value={{res.restaurantId}}>{{res.name}}</option>
                     
                        </select>
                        </div>
                      
                </div>
            </div>
            <div class="col-md-7 align-self-center text-right">
                <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
                    <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;" (click)="Show()"><i class="fa fa-plus-circle"></i> Add Waiter</button>
                </div>
            </div>
            <div *ngIf="!Drop " style="    margin-top: 20px;">
<div class="col-md-12" id="Menus">
    <div class="">
        <button id="Menu" tabindex="92" class="accordion" style="display: block;">User Details</button>
    </div>

  
</div>
<div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
    <div class="col-md-6">
       
          <div class="col-md-6 ">
            <div class="form-group">
                <label class="control-label">User Name</label>
              <input type="text" class="form-control " placeholder="First Name" [(ngModel)]="FName" >
          </div>
        </div>
          <div class="col-md-6 ">
            <div class="form-group" style="padding-top: 36px;">
                <label class="control-label"></label>
              <input type="text" class="form-control " placeholder="Last Name" [(ngModel)]="LName">
          </div>
    </div>
    </div>
    
    <!--/span-->
    <div class="col-md-6">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">SSN</label>
            <input type="text" class="form-control" placeholder="SSN" [(ngModel)]="SSNNo">    
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Mobile Number</label>
            <input type="text" class="form-control" placeholder="Mobile Number" [(ngModel)]="MobileNo"  maxlength="13" (ngModelChange)="onMobileChange($event)" >    
            </div>
        </div>
       
    </div>
    
</div>

<div class="col-md-12" id="Menus">
    <div class="">
        <button id="Menu" tabindex="92" class="accordion" style="display: block;">Address Details</button>
    </div>

  
</div>
<div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
  
<div class="col-md-4">
    <div class="form-group">
        <label class="control-label">Address Line1</label>
      <input type="text" class="form-control"  placeholder="Address Line1" [(ngModel)]="Address1">
</div>
</div>
<div class="col-md-4">
    <div class="form-group">
        <label class="control-label">Address Line2</label>
      <input type="text" class="form-control"  placeholder="Address Line2" [(ngModel)]="Address2" >
</div>
</div>
<div class="col-md-3">
    <div class="form-group">
        <label class="control-label">City</label>
        <div class="">
            <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
            <input type="text" class=" form-control" placeholder="City" [(ngModel)]="City"> </div>
    </div> 
</div>

<div class="col-md-3" style="padding-top: 30px;">
    <div class="form-group">
        <label class="control-label">State</label>
        <div class="">
            <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
            <input type="text" class=" form-control" placeholder="State" [(ngModel)]="State"> </div>
    </div> 
</div>
<div class="col-md-3"  style="padding-top: 30px;">
    <div class="form-group">
        <label class="control-label">ZIP</label>
        <div class="">
            <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
            <input type="number" class=" form-control" placeholder="ZIP" [(ngModel)]="Zip"> </div>
    </div> 
</div>
  
</div>
<div style="padding-bottom: 30px;">
<div class="col-md-12" id="Menus">
    <div class="col-md-6" style="padding:unset" >
        <button id="Menu" tabindex="92" class="accordion" style="display: block;">Others</button>
    </div>
    <div class="col-md-6" style="padding:unset">
        <button id="Menu" tabindex="92" class="accordion" style="display: block;">Set Username and Password</button>
    </div>
</div>
<div class="col-md-6" style="border-right: 1px solid #444;">
<div class="col-md-12">
    <div class="form-group">
        <label class="control-label">Document</label>
        <div class="">
            <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
            <input type="file" #file   (change)="uploadFile(file.files)"  class=" form-control" style="    border: none;   outline: none;  box-shadow: none;" > </div>
    </div> 
    <div *ngFor="let item of Image">
      <a href="{{item.documentUrl}}" target="_blank"></a>
    </div>
</div>
<div class="col-md-12">
    <div class="form-group">
        <label class="control-label">Description</label>
        <div class="">
            <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
            <input type="text" class=" form-control" style="  height: 80px;" placeholder="Enter Description here" [(ngModel)]="Description" > </div>
    </div> 
</div>
</div>
<div class="col-md-6">
<div class="col-md-6">
    <div class="form-group">
        <label class="control-label">Username</label>
        <div class="">
            <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
            <input type="text" class=" form-control" placeholder="Username" [(ngModel)]="UserName" > </div>
    </div> 
</div>
<div class="col-md-6">
    <div class="form-group">
        <label class="control-label">Password</label>
        <div class="">
            <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
            <input type="password" class=" form-control" placeholder="Password" [(ngModel)]="Password" > </div>
    </div> 
</div>
<div class="col-md-12" style="padding-top: 20px;padding-bottom: 20px;">
  <div  *ngIf="Activebutton">
    <input type="checkbox"  checked (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
  </div>
 <div *ngIf="Activebutton1">
  <input type="checkbox"   (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
 </div>
  
</div>
<div class="col-md-12">
    <div class="col-md-6"  *ngIf="Saveshow">
      <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Save()">SAVE</button>
    </div>
    <div class="col-md-6"  *ngIf="!Saveshow">
      <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Update()">Update</button>
    </div>
    <div class="col-md-6">
        <button class="btn" class="form-control" style="border: 1px solid #444;color:black ;border-radius: 5px" (click)="clear()">CLEAR</button>
    </div>
</div>
<div class="col-md-12">
  <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
  <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
  </div>
</div>
</div>
</div>
    <!--/span-->
        </div>

          <div class="ms-content-wrapper">
            <div class="row">
      
              <div class="col-md-12">
      
                <div class="ms-panel">
                  <div class="ms-panel-body">
                    <div class="table-responsive">
                      <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="row">
                            <div class="col-sm-12 col-md-6">
                              <div class="dataTables_length" id="data-table-5_length">
                                <label style="padding-right: 25px;">User Details</label>
                                <input type="text" placeholder="Search" matInput (keyup)="applyFilter($event.target.value)" style="border: unset;background:  #ececec;height: 37px;width: 76%;"> <i class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                            </div></div><div class="col-sm-12 col-md-6"><div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                                    
                                    <button class="btn " (click)="exportexcel()" style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;" >Export</button>
                            <label style="padding-left: 50px;">Show</label>
                            <label class="switch" style="margin-left: 10px;">
                                <input type="checkbox" checked (change)="ActiveCheckboxChange($event)">
                                <span class="slider round"></span>
                              </label>
                                </div>
                              </div>
                            </div>
                            <div class="mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                              <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSort >
                               
                                <!-- Position Column -->
                                <ng-container matColumnDef="sno" >
                                  <th mat-header-cell *matHeaderCellDef  style="color: white !important;"> S.NO </th>
                                  <td mat-cell  *matCellDef="let item; let j = index"> {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                                </ng-container>
                            
                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> Restaurant Name </th>
                                  <td mat-cell *matCellDef="let element" (click)="WaiterEdit(element.userId)"> {{element.name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="userName">
                                  <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> Waiter Name </th>
                                  <td mat-cell *matCellDef="let element" (click)="WaiterEdit(element.userId)"> {{element.userName}} </td>
                                </ng-container>
                                <!-- Weight Column -->
                                <ng-container matColumnDef="mobileNo" >
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;">Mobile No </th>
                                  <td mat-cell *matCellDef="let element"  (click)="WaiterEdit(element.userId)"> {{element.mobileNo}} </td>
                                </ng-container>
                            
                           
                                <ng-container matColumnDef="description" >
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> Description </th>
                                  <td mat-cell *matCellDef="let element"  (click)="WaiterEdit(element.userId)">  {{element.description}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Status" >
                                  <th mat-header-cell *matHeaderCellDef  style="color: white !important;text-align: center;"> Active </th>
                                  <td mat-cell *matCellDef="let element"> 
                                    <label class="switch" style="margin-left: 10px;">
                                      <input type="checkbox" checked *ngIf="element.active=='Active' ? true :false">
                                      <input type="checkbox"  *ngIf="element.active=='Active' ? false :true">
                                      <span class="slider round"></span>
                                    </label>
                                 </td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                              </table>
                            
                              <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>
                            </div>   
                                                                </div>
                                                                </div>
                                                              
                    </div>
                  </div>
                </div>
      
              </div>
      
            </div>
    
      </main>
  
