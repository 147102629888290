
<main class="body-content">

  <!-- Navigation Bar -->
  <nav class="navbar ms-navbar" style=" margin: unset;">
    <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
        class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
    </div>
  
  </nav>

  <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">Discount / Offers</h4>
    </div>
    <div class="col-md-7 align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
        <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
          (click)="Show()"><i class="fa fa-plus-circle"></i> Add Discount</button>
      </div>
    </div>
    <div *ngIf="!Drop " style="    margin-top: 20px;">
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Discount Details</button>
        </div>


      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Restaurant</label>
            <select class="form-control" [(ngModel)]="restaurantId">
              <option value="0">Select Restaurant</option>
              <option *ngFor="let rst of Resturants" value={{rst.restaurantId}}>{{rst.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Discount Type</label>
            <select class="form-control" [(ngModel)]="DiscountType">
              <option value="P">Percentage</option>
              <option value="D">Amount</option>
            </select> </div>
        </div>
        <!--/span-->
        <!-- <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Category</label>
            <select class="form-control" [(ngModel)]="categoryId" (ngModelChange)="onCatChange($event)">
              <option value="0">Select Category</option>
              <option *ngFor="let cat of Category" value={{cat.categoryId}}>{{cat.categoryName}}</option>
            </select> </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">SubCategory</label>
            <select class="form-control" [(ngModel)]="subCategoryId" (ngModelChange)="onSubCatChange($event)">
              <option value="0">Select SubCategory</option>
              <option *ngFor="let scat of SubCategory" value={{scat.subCategoryId}}>{{scat.subCategoryName}}</option>
            </select> </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Menu Item</label>
            <select class="form-control" [(ngModel)]="MenuItemId">
              <option value="0">Select Item</option>
              <option *ngFor="let itm of MenuItem" value={{itm.menuItemId}}>{{itm.itemName}}</option>
            </select> </div>
        </div> -->
      </div>
      <div class="col-md-12" style="padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Discount %</label>
            <input type="text" class="form-control" [(ngModel)]="Discount">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Minimum Quantity</label>
            <input type="text" class="form-control" [(ngModel)]="MinQty">
          </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Valitity From</label>
            <input type="date" class="form-control" [(ngModel)]="ValitityFrom">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Valitity To</label>
            <input type="date" class="form-control" [(ngModel)]="ValitityTo">
          </div>
        </div>
      </div>

      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Others</button>
        </div>
      </div>

      <div class="col-md-12" style="padding-bottom: 25px;">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Description</label>
            <input type="text" class="form-control" style="height: 50px;" [(ngModel)]="Description">
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-3" style="padding-top: 39px;"  [(ngModel)]="Status" (change)="toggleEditable($event)" [hidden]="isShownActive">
            <input type="checkbox"> Set As In Active
          </div>


          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;"
              (click)="SubmitDiscount()" [hidden]="isShownSaveBtn">SAVE</button>
              <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;" 
              (click)="UpdateDiscount()" [hidden]="isShownUpdateBtn">UPDATE</button>
          </div>
          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: white; color: black;;border-radius: 4px;border-color: #ED562E;"
              (click)="OnClear()">CLEAR</button>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
        <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
      </div>
    </div>
    <!--/span-->
  </div>
  <div class="ms-content-wrapper">
    <div class="row">

      <div class="col-md-12">

        <div class="ms-panel">
          <div class="ms-panel-body">
            <div class="table-responsive">
              <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="data-table-5_length">
                      <label style="padding-right: 25px;">Search</label>
                      <input type="text" placeholder="Search"
                        style="border: unset;background:  #ececec;height: 37px;width: 84%;"> <i
                        class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                      <!-- <select style="text-align: left;height: 26px;width: 27%;    border-radius: 4px;">
                        <option>Search By</option>
                      </select> -->
                      <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" (click)="exportexcel()" style="cursor: pointer;">
                      <!-- <button class="btn"
                        style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;"
                        (click)="exportexcel()">Export</button> -->
                      <label style="padding-left: 50px;">Show</label>
                      <label class="switch" style="margin-left: 10px;">
                        <input type="checkbox" checked (change)="ActiveCheckboxChange($event)">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mat-elevation-z8"  style="box-shadow: unset;padding-top: 30px ;" #TABLE>

                    <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSortmat-table [dataSource]="dataSource" #table style="width: 100%;" matSort>
                      <ng-container matColumnDef="restaurantName" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> RESTAURANT NAME </th>
                        <td mat-cell *matCellDef="let element" (click)="DiscountEdit(element)"> {{element.restaurantName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="minQuantiy" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> MIN QTY </th>
                        <td mat-cell *matCellDef="let element" (click)="DiscountEdit(element)"> {{element.minQuantiy}} </td>
                      </ng-container>
                      <ng-container matColumnDef="discount" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> Discount </th>
                        <td mat-cell *matCellDef="let element" (click)="DiscountEdit(element)"> {{element.discount}} </td>
                      </ng-container>
                      <ng-container matColumnDef="validityFrom" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> VALIDITY FROM </th>
                        <td mat-cell *matCellDef="let element" (click)="DiscountEdit(element)"> {{element.validityFrom}} </td>
                      </ng-container>
                      <ng-container matColumnDef="validityTo" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> VALIDITY TO </th>
                        <td mat-cell *matCellDef="let element" (click)="DiscountEdit(element)"> {{element.validityTo}} </td>
                      </ng-container>
                      <ng-container matColumnDef="status" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> STATUS </th>
                        <td mat-cell *matCellDef="let element" (click)="DiscountEdit(element)"> {{element.status}} </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>

                    <!-- <table id="data-table-5" class="table w-100 thead-primary dataTable no-footer" role="grid"
                      aria-describedby="data-table-5_info" style="width: 1099px;">
                      <thead>
                        <tr role="row">
                          <th class="sorting_asc" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-sort="ascending" aria-label="Product ID: activate to sort column descending"
                            style="width: 165px;">CATEGORY</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Product Name: activate to sort column ascending" style="width: 141px;">ITEM</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Quantity: activate to sort column ascending" style="width: 141px;">DISCOUNT</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="2"
                            style="text-align: center;" aria-label="Status: activate to sort column ascending"
                            style="width: 170pxpx;text-align: center;">VALIDITY </th>

                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Price: activate to sort column ascending" style="width: 102px;">ACTIVE</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th style="width: 150px;text-align: center;">From</th>
                          <th style="width: 150px;text-align: center;">To</th>
                          <th></th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr role="row" class="odd" *ngFor="let itm of items | grdFilter: {categoryName: searchText, itemName:searchText, copounCode: searchText, validityFrom: searchText, validityTo: searchText}; let i=index;">
                          <td class="sorting_1" (click)="DiscountEdit(itm)" style="cursor: pointer;">{{itm.categoryName}}</td>
                          <td (click)="DiscountEdit(itm)" style="cursor: pointer;">{{itm.itemName}}</td>
                          <td (click)="DiscountEdit(itm)" style="cursor: pointer;">{{itm.discount}}</td>
                          <td style="text-align: center;cursor: pointer;" (click)="DiscountEdit(itm)">{{itm.validityFrom}}</td>
                          <td style="text-align: center;cursor: pointer;" (click)="DiscountEdit(itm)">{{itm.validityTo}}</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="data-table-5_info" role="status" aria-live="polite">Showing 1 to 10
                      of 36 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                    
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</main>