import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-admin-menucommon',
  templateUrl: './admin-menucommon.component.html',
  styleUrls: ['./admin-menucommon.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminMenucommonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript('../../assets/framework.js');
    this.loadScript('../../assets/datatables.min.js');
   
   $(".menu-item1").click(function(){
    debugger;
$(this).find('ul').toggleClass('show');
  });
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
