import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whishlist',
  templateUrl: './whishlist.component.html',
  styleUrls: ['./whishlist.component.css']
})
export class WhishlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
