
<main class="body-content">

  <!-- Navigation Bar -->
  <nav class="navbar ms-navbar" style=" margin: unset;">
    <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
        class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
    </div>
   
  </nav>

  <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">Tax Details</h4>
    </div>
    <div class="col-md-7 align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
        <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
          (click)="Show()"><i class="fa fa-plus-circle"></i> Add Tax</button>
      </div>
    </div>
    <div *ngIf="!Drop" style="    margin-top: 20px;">
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Tax Details</button>
        </div>


      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Restaurant Name</label>
            <select class="form-control" [(ngModel)]="restaurantId" (ngModelChange)="onResturantChange($event)">
              <option value="0">Select Restaurant</option>
              <option *ngFor="let rst of Resturants" value={{rst.restaurantId}}>{{rst.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Category</label>
            <select class="form-control" [(ngModel)]="categoryId" (ngModelChange)="onCatChange($event)">
              <option value="0">Select Category</option>
              <option *ngFor="let cat of Category" value={{cat.categoryId}}>{{cat.categoryName}}</option>
            </select> </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">SubCategory</label>
            <select class="form-control" [(ngModel)]="subCategoryId" (ngModelChange)="onSubCatChange($event)">
              <option value="0">All</option>
              <option *ngFor="let scat of SubCategory" value={{scat.subCategoryId}}>{{scat.subCategoryName}}</option>
            </select> </div>
        </div>
        <!--/span-->
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Item</label>
            <select class="form-control" [(ngModel)]="MenuItemId">
              <option value="0">All</option>
              <option *ngFor="let itm of MenuItem" value={{itm.menuItemId}}>{{itm.itemName}}</option>
            </select> </div>
        </div>

      </div>
      <div class="col-md-12" style="padding-bottom: 30px;">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Sales Tax</label>
            <input type="number" class="form-control" [(ngModel)]="SalesTax">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Hospitality Tax</label>
            <input type="number" class="form-control" [(ngModel)]="HospitalityTax">
          </div>
        </div>
        <!--/span-->
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Others</label>
            <input type="number" class="form-control" [(ngModel)]="OtherTax"> </div>
        </div>
      </div>

      <div class="col-md-12 ">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Description</label>
            <input type="text" class="form-control" style="height:100px" [(ngModel)]="Description">
          </div>
        </div>
        <div class="col-md-6" style="padding-top: 5%;"  [hidden]="isShownActive">
          <input type="checkbox"> Set As InActive
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 30px;">
        <div class="col-md-6">

        </div>
        <div class="col-md-6">
          <div class="col-md-3">
            <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;"
              (click)="SaveTax()" [hidden]="isShownSaveBtn">SAVE</button>
            <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;" (click)="UpdateTax()"
              [hidden]="isShownUpdateBtn">UPDATE</button>
          </div>
          <div class="col-md-3">
            <button class="btn " style=" color: black;border-radius: 4px;border: 1px solid #ED562E;"
              (click)="Clear()">CLEAR</button>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
        <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
      </div>
    </div>
    <!--/span-->
  </div>

  <div class="ms-content-wrapper">
    <div class="row">

      <div class="col-md-12">

        <div class="ms-panel">
          <div class="ms-panel-body">
            <div class="table-responsive">
              <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="data-table-5_length">
                      <label style="padding-right: 25px;">Search</label>
                      <input type="text" placeholder="Search"
                        style="border: unset;background:  #ececec;height: 37px;width: 84%;" [(ngModel)]="searchText"
                        (keyup)="applyFilter($event.target.value)"> <i class="fa fa-search fa-2x"
                        style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                      <!-- <select style="text-align: left;height: 26px;width: 27%;    border-radius: 4px;">
                                        <option>Search By</option>
                                    </select> -->
                                    <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" (click)="exportexcel()" style="cursor: pointer;">
                      <!-- <button class="btn"
                        style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;"
                        (click)="exportexcel()">Export</button> -->
                      <label style="padding-left: 50px;">Show</label>
                      <label class="switch" style="margin-left: 10px;">
                        <input type="checkbox" checked>
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                    <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSortmat-table
                      [dataSource]="dataSource" #table style="width: 100%;" matSort>
                      <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef style="color: white !important;background-color: #ED562E">
                          S.NO </th>
                        <td mat-cell *matCellDef="let item; let j = index">
                          {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                      </ng-container>
                      <ng-container matColumnDef="restaurantName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> RESTAURANT NAME </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.restaurantName}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="categoryName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> CATEGORY </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.categoryName}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="subCategoryName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> SUB CATEGORY </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.subCategoryName}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="ItemName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> ITEM NAME </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.ItemName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="salesTax">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> SALES TAX </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.salesTax}} </td>
                      </ng-container>
                      <ng-container matColumnDef="hospitalityTax">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> HOSPITALITY TAX </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.hospitalityTax}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="others">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> OTHERS </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.others}} </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> STATUS </th>
                        <td mat-cell *matCellDef="let element" (click)="TaxEdit(element)"> {{element.status}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons>
                    </mat-paginator>

                    <!-- <table id="data-table-5" class="table w-100 thead-primary dataTable no-footer" role="grid"
                      aria-describedby="data-table-5_info" style="width: 1099px;">
                      <thead>
                        <tr role="row">
                          <th class="sorting_asc" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-sort="ascending" aria-label="Product ID: activate to sort column descending"
                            style="width: 165px;">CATEGORY</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Product Name: activate to sort column ascending" style="width: 141px;">ITEM</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Quantity: activate to sort column ascending" style="width: 141px;">SALES TAX
                          </th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Status: activate to sort column ascending" style="width: 156px;">HOSPITALITY TAX
                          </th>

                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Price: activate to sort column ascending" style="width: 102px;">ACTIVE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role="row" class="odd">
                          <td class="sorting_1">01475</td>
                          <td>French Fries</td>
                          <td>6741</td>
                          <td>In Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="even">
                          <td class="sorting_1">02351</td>
                          <td>French Crostini</td>
                          <td>8899</td>
                          <td>In Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">12121</td>
                          <td>Noodels</td>
                          <td>6224</td>
                          <td>In Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="even">
                          <td class="sorting_1">12145</td>
                          <td>Garlic Bread</td>
                          <td>1965</td>
                          <td>Out Of Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">14451</td>
                          <td>pizza</td>
                          <td>5384</td>
                          <td>Out Of Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="even">
                          <td class="sorting_1">14526</td>
                          <td>Lemon Rice</td>
                          <td>2860</td>
                          <td>In Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">15451</td>
                          <td>Fried Rice</td>
                          <td>8240</td>
                          <td>In Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="even">
                          <td class="sorting_1">15485</td>
                          <td>Baked Nachos</td>
                          <td>3023</td>
                          <td>In Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">22366</td>
                          <td>Multigrain Hot Cereal</td>
                          <td>2558</td>
                          <td>Out Of Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                        <tr role="row" class="even">
                          <td class="sorting_1">25252</td>
                          <td> Sandwich</td>
                          <td>4804</td>
                          <td>In Stock</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="data-table-5_info" role="status" aria-live="polite">Showing 1 to 10
                      of 36 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div class="dataTables_paginate paging_simple_numbers" id="data-table-5_paginate">
                      <ul class="pagination has-gap">
                        <li class="paginate_button page-item previous disabled" id="data-table-5_previous">
                          <a href="" aria-controls="data-table-5" data-dt-idx="0" tabindex="0"
                            class="page-link">Previous</a></li>
                        <li class="paginate_button page-item active"><a href="" aria-controls="data-table-5"
                            data-dt-idx="1" tabindex="0" class="page-link">1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="" aria-controls="data-table-5" data-dt-idx="2"
                            tabindex="0" class="page-link">2</a></li>
                        <li class="paginate_button page-item "><a href="" aria-controls="data-table-5" data-dt-idx="3"
                            tabindex="0" class="page-link">3</a></li>
                        <li class="paginate_button page-item ">
                          <a href="#" aria-controls="data-table-5" data-dt-idx="4" tabindex="0" class="page-link">4</a>
                        </li>
                        <li class="paginate_button page-item next" id="data-table-5_next"><a href="#"
                            aria-controls="data-table-5" data-dt-idx="5" tabindex="0" class="page-link">Next</a></li>
                      </ul>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</main>