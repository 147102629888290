import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tax-detail',
  templateUrl: './tax-detail.component.html',
  styleUrls: ['./tax-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TaxDetailComponent implements OnInit {
  Drop = true;
  Resturants = [];
  Category = [];
  SubCategory = [];
  MenuItem = [];
  restaurantId: string;
  categoryId: string;
  subCategoryId: string;
  MenuItemId: string;
  isShownError: boolean = true;
  isShownSuccess: boolean = true;
  ErrorMsg: string;
  SuccessMsg: string;
  SalesTax: string;
  HospitalityTax: string;
  OtherTax: string;
  Description: string;
  EditedTax = [] as any;
  EditedTaxId: string;
  isShownSaveBtn: boolean = false;
  isShownUpdateBtn: boolean = true;
  searchText: string;
  isShownActive: Boolean = true;

  displayedColumns: string[] = ['sno', 'restaurantName', 'categoryName', 'subCategoryName', 'ItemName', 'salesTax', 'hospitalityTax', 'others', 'status'];
  dataSource: MatTableDataSource<any[]>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.loadScript('../../assets/framework.js');
    this.loadScript('../../assets/datatables.min.js');
    this.BindRestaurantDDL();
    this.BindTaxGrid("Active");
  }

  Show() {
    this.Drop = false;
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  BindTaxGrid(value1) {
    var value = this.http.get(environment.apiUrl + 'AdminTax/GetTaxForGrid?sStatus=' + value1).toPromise().then(
      (data: any) => {
        debugger;
        if (data.length != 0) {
          //this.items = data;          
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;

          this.dataSource.sort = this.sort;
        }
        else {
        };
      });
  }

  BindRestaurantDDL() {
    var value = this.http.get(environment.apiUrl + 'AdminCopoun/GetResturantForDDL').toPromise().then(
      (data: any) => {
        debugger;
        if (data.length != 0) {
          //
          this.Resturants = data;
          this.restaurantId = "0";
          this.categoryId = "0";
          this.subCategoryId = "0";
          this.MenuItemId = "0";

        }
        else {
        };
      });
  }
  onResturantChange(restId: number) {
    this.BindCategoryDDL(restId);
  }
  BindCategoryDDL(vResId) {
    var value = this.http.get(environment.apiUrl + 'AdminItemSettings/GetCategoryForDDL?iResturantId=' + vResId).toPromise().then(
      (data: any) => {
        debugger;
        if (data.length != 0) {
          //
          this.Category = data;
          this.categoryId = "0";
          this.subCategoryId = "0";
          this.MenuItemId = "0";
        }
        else {
        };
      });
  }
  onCatChange(catId: number) {
    debugger;
    var vCatId = catId;
    if (vCatId != 0) {
      var value = this.http.get(environment.apiUrl + 'AdminCopoun/GetSubCategoryForDDL?iCatID=' + vCatId).toPromise().then(
        (data: any) => {
          debugger;
          if (data.length != 0) {
            //
            this.SubCategory = data;
            this.subCategoryId = "0";
          }
          else {
          };
        });
    }
  }
  onSubCatChange(subcatId: number) {
    debugger;
    var vSubCatId = subcatId;
    var vCatId = this.categoryId;
    if (vSubCatId != 0) {
      var value = this.http.get(environment.apiUrl + 'AdminCopoun/GetItemsForDDL?iCatID=' + vCatId + '&iSubCatId=' + vSubCatId).toPromise().then(
        (data: any) => {
          debugger;
          if (data.length != 0) {
            //
            this.MenuItem = data;
            this.MenuItemId = "0";
          }
          else {
          };
        });
    }
  }

  SaveTax() {
    this.isShownError = true;
    this.isShownSuccess = true;
    this.ErrorMsg = "";
    this.SuccessMsg = "";

    var vRestaurant_ID = this.restaurantId;
    var vCategory_Id = this.categoryId;
    var vSubCategory_ID = this.subCategoryId;
    var vMenuItemId = this.MenuItemId;
    var SalesTax = this.SalesTax;
    var HospitalityTax = this.HospitalityTax;
    var OtherTax = this.OtherTax;
    var Description = this.Description;

    if (vRestaurant_ID == undefined || vRestaurant_ID == "" || vRestaurant_ID == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select any one Resturant.";
      return false;
    }
    if (vCategory_Id == undefined || vCategory_Id == "" || vCategory_Id == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select any one Item Category.";
      return false;
    }
    if ((SalesTax == undefined || SalesTax == "") && (HospitalityTax == undefined || HospitalityTax == "") && (OtherTax == undefined || OtherTax == "")) {
      this.isShownError = false;
      this.ErrorMsg = "Please Enter Any One Tax.";
      return false;
    }

    let params = new HttpParams();
    params = params.append('iRestaurant_ID', this.restaurantId);
    params = params.append('dSalesTax', this.SalesTax);
    params = params.append('dHospitalityTax', this.HospitalityTax);
    params = params.append('dOtherTax', this.OtherTax);
    params = params.append('sDescription', this.Description);
    var value = this.http.get(environment.apiUrl + 'AdminTax/InsertTax', { params: params }).toPromise().then(
      (data: any) => {
        debugger;
        var vTaxId = data;
        //this.BindCouponGrid("Active");

        this.isShownSuccess = false;
        this.SuccessMsg = "Tax Details Added.";
        //return false;

        let params1 = new HttpParams();
        params1 = params1.append('iRestaurant_ID', this.restaurantId);
        params1 = params1.append('iCatId', this.categoryId);
        params1 = params1.append('iSubCatId', this.subCategoryId);
        params1 = params1.append('iItemId', this.MenuItemId);
        params1 = params1.append('iTaxId', vTaxId)


        var value11 = this.http.get(environment.apiUrl + 'AdminTax/UpdateTaxToItem', { params: params1 }).toPromise().then(
          (data: any) => {
            debugger;
            // var vcouponId = data;
          }).catch((data: any) => {
            debugger;
            var vDataError = JSON.stringify(data.error.text);
            var vErrorMsg = vDataError.replace('"', '');
            var vErrorMsg1 = vErrorMsg.replace('"', '');
            if (vErrorMsg1 == "Failed") {
              this.isShownError = false;
              this.ErrorMsg = "Insert Failed, Please Try Again.";
              return false;
            }
            else if (vErrorMsg1 == "Success") {
              //this.BindCouponGrid("Active");
              this.restaurantId = "0";
              this.categoryId = "0";
              this.subCategoryId = "0";
              this.MenuItemId = "0";
              this.SalesTax = "";
              this.HospitalityTax = "";
              this.OtherTax = "";
              this.Description = "";
              this.isShownError = false;
              this.ErrorMsg = "Tax Details Added.";
              this.BindTaxGrid("Active");
              return false;
            }
          });
      }).catch((data: any) => {
        debugger;
        var vDataError = JSON.stringify(data.error.text);
        var vErrorMsg = vDataError.replace('"', '');
        var vErrorMsg1 = vErrorMsg.replace('"', '');//Exist
        if (vErrorMsg1 == "Failed") {
          this.isShownError = false;
          this.ErrorMsg = "Insert Failed, Please Try Again.";
          return false;
        }
        else if (vErrorMsg1 == "Exist") {
          this.isShownError = false;
          this.ErrorMsg = "Tax Already Exist.";
          return false;
        }
      });
  }
  TaxEdit(item: any) {
    this.isShownError = true;
    this.isShownSuccess = true;
    this.ErrorMsg = "";
    this.SuccessMsg = "";

    this.isShownActive=false;
    this.EditedTax = item;
    var vCouponID = this.EditedTax.taxId;
    this.EditedTaxId = vCouponID;
    this.restaurantId = this.EditedTax.restaurantId;
    this.SalesTax = this.EditedTax.restaurantId;
    this.HospitalityTax = this.EditedTax.hospitalityTax;
    this.OtherTax = this.EditedTax.others;
    this.Description = this.EditedTax.description;

    var value = this.http.get(environment.apiUrl + 'AdminItemSettingsCopoun/GetCategoryForDDL?iResturantId=' + this.restaurantId).toPromise().then(
      (data: any) => {
        debugger;
        if (data.length != 0) {
          //
          this.Category = data;
          this.categoryId = this.EditedTax.categoryId;
          this.subCategoryId = "0";
          this.MenuItemId = "0";
          this.isShownSaveBtn = true;
          this.isShownUpdateBtn = false;

          var value = this.http.get(environment.apiUrl + 'AdminCopoun/GetSubCategoryForDDL?iCatID=' + this.categoryId).toPromise().then(
            (data: any) => {
              debugger;
              if (data.length != 0) {
                //
                this.SubCategory = data;
                this.subCategoryId = this.EditedTax.subCategoryId;

                var value = this.http.get(environment.apiUrl + 'AdminCopoun/GetItemsForDDL?iCatID=' + this.categoryId + '&iSubCatId=' + this.subCategoryId).toPromise().then(
                  (data: any) => {
                    debugger;
                    if (data.length != 0) {
                      //
                      this.MenuItem = data;
                      this.MenuItemId = this.EditedTax.menuItemId;
                    }
                    else {
                    };
                  });

              }
              else {
              };
            });
        }
        else {
        };
      });


  }

  UpdateTax() {
    this.isShownError = true;
    this.isShownSuccess = true;
    this.ErrorMsg = "";
    this.SuccessMsg = "";

    var vRestaurant_ID = this.restaurantId;
    var vCategory_Id = this.categoryId;
    var vSubCategory_ID = this.subCategoryId;
    var vMenuItemId = this.MenuItemId;
    var SalesTax = this.SalesTax;
    var HospitalityTax = this.HospitalityTax;
    var OtherTax = this.OtherTax;
    var Description = this.Description;

    if (vRestaurant_ID == undefined || vRestaurant_ID == "" || vRestaurant_ID == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select any one Resturant.";
      return false;
    }
    if (vCategory_Id == undefined || vCategory_Id == "" || vCategory_Id == "0") {
      this.isShownError = false;
      this.ErrorMsg = "Please Select any one Item Category.";
      return false;
    }
    if ((SalesTax == undefined || SalesTax == "") && (HospitalityTax == undefined || HospitalityTax == "") && (OtherTax == undefined || OtherTax == "")) {
      this.isShownError = false;
      this.ErrorMsg = "Please Enter Any One Tax.";
      return false;
    }
    var vEditedTaxId = this.EditedTaxId;
    let params = new HttpParams();
    params = params.append('iRestaurant_ID', this.restaurantId);
    params = params.append('dSalesTax', this.SalesTax);
    params = params.append('dHospitalityTax', this.HospitalityTax);
    params = params.append('dOtherTax', this.OtherTax);
    params = params.append('sDescription', this.Description);
    params = params.append('iTaxId', vEditedTaxId);
    var value = this.http.get(environment.apiUrl + 'AdminTax/UpdateTax', { params: params }).toPromise().then(
      (data: any) => {
        debugger;
        var vTaxId = data;
      }).catch((data: any) => {
        debugger;
        var vDataError = JSON.stringify(data.error.text);
        var vErrorMsg = vDataError.replace('"', '');
        var vErrorMsg1 = vErrorMsg.replace('"', '');
        if (vErrorMsg1 == "Failed") {
          this.isShownError = false;
          this.ErrorMsg = "Update Failed, Please Try Again.";
          return false;
        }
        else if (vErrorMsg1 == "Success") {
          //this.BindCouponGrid("Active");
          this.isShownUpdateBtn = true;
          this.isShownSaveBtn = false;
          this.isShownActive=true;
          this.restaurantId = "0";
          this.categoryId = "0";
          this.subCategoryId = "0";
          this.MenuItemId = "0";
          this.SalesTax = "";
          this.HospitalityTax = "";
          this.OtherTax = "";
          this.Description = "";
          this.isShownError = false;
          this.ErrorMsg = "Tax Details Updated.";
          this.BindTaxGrid("Active");
          return false;
        }
      });
  }
  Clear() {
    this.isShownError = true;
    this.isShownSuccess = true;
    this.ErrorMsg = "";
    this.SuccessMsg = "";

    this.isShownActive=true;
    this.isShownUpdateBtn = true;
    this.isShownSaveBtn = false;
    this.restaurantId = "0";
    this.categoryId = "0";
    this.subCategoryId = "0";
    this.MenuItemId = "0";
    this.SalesTax = "";
    this.HospitalityTax = "";
    this.OtherTax = "";
    this.Description = "";
    this.isShownError = false;
  }
  applyFilter(filterValue: string) {
    debugger;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  exportexcel(): void {
    debugger;
    /* table id is passed over here */
    const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    //let element = document.getElementById('data-table-5'); 
    // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, workSheet, 'CouponDetails');

    /* save to file */
    XLSX.writeFile(wb, "CouponDetails.xlsx");

  }

}
