
<main class="body-content">

  <!-- Navigation Bar -->
  <nav class="navbar ms-navbar" style=" margin: unset;">
    <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
        class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
    </div>
    
  </nav>

  <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">Category</h4>
    </div>
    <div class="col-md-7 align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
        <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
          (click)="Show()"><i class="fa fa-plus-circle"></i> Add Category</button>
      </div>
    </div>
    <div *ngIf="!Drop " class="col-md-12" id="Menus" style="    margin-top: 20px;">
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Category Details</button>
        </div>


      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Restaurant</label>
            <select class="form-control" [(ngModel)]="restaurantId">
              <option value="0">Select</option>
              <option *ngFor="let rst of Resturants" value={{rst.restaurantId}}>{{rst.name}}</option>
            </select>
          </div>
        </div>

        <!--/span-->
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Category Name</label>
            <input type="text" class="form-control"  [(ngModel)]="CategoryName">
          </div>
        </div>

      </div>

      <div class="col-md-12" style="padding-bottom: 25px;">

        <div class="col-md-6">
          <div class="col-md-3" style="padding-top: 39px;" [(ngModel)]="Status" (change)="toggleEditable($event)"
            [hidden]="isShownActive">
            <input type="checkbox"> Set As In Active
          </div>


          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;"
              (click)="InsertCategory()" [hidden]="isShownSaveBtn">SAVE</button>
              <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;"
              (click)="UpdateCategory()" [hidden]="isShownUpdateBtn">Update</button>
          </div>
          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: white; color: black;;border-radius: 4px;border-color: #ED562E;"
              (click)="Clear()">CLEAR</button>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
        <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
      </div>
    </div>
    <!--/span-->
  </div>

  <div class="ms-content-wrapper">
    <div class="row">

      <div class="col-md-12">

        <div class="ms-panel">
          <div class="ms-panel-body">
            <div class="table-responsive">
              <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="data-table-5_length">
                      <label style="padding-right: 25px;">Search</label>
                      <input type="text" placeholder="Search"
                        style="border: unset;background:  #ececec;height: 37px;width: 72%;"  [(ngModel)]="searchText" (keyup)="applyFilter($event.target.value)" > <i
                        class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 79%; top: 3px;"></i>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                      <!-- <select style="text-align: left;height: 26px;width: 27%;    border-radius: 4px;">
                        <option>Search By</option>
                      </select> -->
                      <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" (click)="exportexcel()" style="cursor: pointer;">
                      <!-- <button class="btn"
                        style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;" (click)="exportexcel()">Export</button> -->
                      <label style="padding-left: 50px;">Show</label>
                      <label class="switch" style="margin-left: 10px;">
                        <input type="checkbox" checked (change)="ActiveCheckboxChange($event)">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                    <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSortmat-table [dataSource]="dataSource" #table style="width: 100%;" matSort>
                      <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef style="color: white !important;background-color: #ED562E">
                          S.NO </th>
                        <td mat-cell *matCellDef="let item; let j = index">
                          {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                      </ng-container>
                      <ng-container matColumnDef="restaurantName" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> RESTAURANT NAME </th>
                        <td mat-cell *matCellDef="let element" (click)="EditCategory(element)"> {{element.restaurantName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="categoryName" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> CATEGORY </th>
                        <td mat-cell *matCellDef="let element" (click)="EditCategory(element)"> {{element.categoryName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="status" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;background-color: #ED562E"> STATUS </th>
                        <td mat-cell *matCellDef="let element" (click)="EditCategory(element)"> {{element.status}} </td>
                      </ng-container>
                      
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>



                    <!-- <table id="data-table-5" class="table w-100 thead-primary dataTable no-footer" role="grid"
                      aria-describedby="data-table-5_info" style="width: 1099px;">
                      <thead>
                        <tr role="row">
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Price: activate to sort column ascending" style="width: 102px;">S.NO</th>
                          <th class="sorting_asc" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-sort="ascending" aria-label="Product ID: activate to sort column descending"
                            style="width: 255px;">RESTAURANT NAME</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Product Name: activate to sort column ascending" style="width: 255px;">CATEGORY
                            NAME</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Price: activate to sort column ascending" style="width: 102px;">ACTIVE</th>
                          <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                            aria-label="Price: activate to sort column ascending" style="width: 102px;">EDIT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role="row" class="odd">
                          <td class="sorting_1">01</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">02</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">03</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">04</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">05</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">06</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">07</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">08</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">09</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                        <tr role="row" class="odd">
                          <td class="sorting_1">10</td>
                          <td>Zapata Florence</td>
                          <td>Food</td>
                          <td><label class="switch">
                              <input type="checkbox" checked>
                              <span class="slider round"></span>
                            </label></td>
                          <td>
                            <i class="fa fa-pencil-square-o"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="data-table-5_info" role="status" aria-live="polite">Showing 1 to 10
                      of 36 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">s
                    <div class="dataTables_paginate paging_simple_numbers" id="data-table-5_paginate">
                      <ul class="pagination has-gap">
                        <li class="paginate_button page-item previous disabled" id="data-table-5_previous">
                          <a href="" aria-controls="data-table-5" data-dt-idx="0" tabindex="0"
                            class="page-link">Previous</a></li>
                        <li class="paginate_button page-item active"><a href="" aria-controls="data-table-5"
                            data-dt-idx="1" tabindex="0" class="page-link">1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="" aria-controls="data-table-5" data-dt-idx="2"
                            tabindex="0" class="page-link">2</a></li>
                        <li class="paginate_button page-item "><a href="" aria-controls="data-table-5" data-dt-idx="3"
                            tabindex="0" class="page-link">3</a></li>
                        <li class="paginate_button page-item ">
                          <a href="#" aria-controls="data-table-5" data-dt-idx="4" tabindex="0" class="page-link">4</a>
                        </li>
                        <li class="paginate_button page-item next" id="data-table-5_next"><a href="#"
                            aria-controls="data-table-5" data-dt-idx="5" tabindex="0" class="page-link">Next</a></li>
                      </ul>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</main>