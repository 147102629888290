

<title>Favourites</title>

<div class="container" style="padding-top: 50px;">
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
<img src="../../assets/Images/icon/wishlist.png" class="center-block" style="width: 100px;">

<h4 style="text-align: center;font-size: 30px;font-family:Ubuntu-Medium !important ;">My Favourites</h4>
    </div>
</div>

<div class="container-fluid">
    <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="border-bottom: 1px solid rgba(111,111,111,0.5);padding-top: 40px;">
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12">
            <h4 style="text-align: center;font-family:Ubuntu-Medium !important  ;font-size: 25px;">Items</h4>
        </div>
        <div class="col-lg-3 col-xs-2 col-md-3 col-sm-12">
            <h4 style="text-align: center;font-family:Ubuntu-Medium !important  ;font-size: 25px;">Description</h4>
        </div>
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12">
            <h4 style="text-align: center;;font-family:Ubuntu-Medium !important  ;font-size: 25px;">Quantity</h4>
        </div>
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12">
            <h4 style="text-align: center;font-family:Ubuntu-Medium !important  ;font-size: 25px;">Price</h4>
        </div>
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12">
            <h4 style="text-align: center;font-family:Ubuntu-Medium !important  ;font-size: 25px;">Cart</h4>
        </div>
    </div>
    <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-bottom: 30px; border-bottom: 1px solid rgba(111,111,111,0.5);padding-top: 20px;" *ngFor='let item of Favourites'>
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12">
            <img src={{item.imageUrl}} class="center-block" style="width: 130px;height: 100px;;">
        </div>
        <div class="col-lg-3 col-xs-2 col-md-3 col-sm-12">
            <h4 style="text-align: center;font-family:Ubuntu-Medium !important  ;font-size: 20px;    padding-top: 14px;">{{item.itemName}}</h4>
        </div>
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12" style="padding-top: 23px;padding-left: 60px;" >
            <div class="input-group" id="wi" style="   text-align: center;background-color: #EE562F;color: white;">
                <div class="input-group-btn">
                    <button id="up" class="btn" style="padding-top: unset;padding-bottom: unset;border:unset;font-size: 20px;z-index: 0;background: none;color: white;"  (click)="Adddown('1',item)"><span class="glyphicon glyphicon-minus" style="font-size: 23px;"  ></span></button>
                </div>
               
                <input type="text" id="AddNumber" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"   style="text-align: center;-webkit-box-shadow:unset;box-shadow: unset; border:unset;background-color: #EE562F;color: white; font-size: 20px;z-index: 0;font-family: Ubuntu-BOLD !important;" class="form-control input-number"  [(ngModel)]="item.newPrice" (change)="AddcartQuant(item)" />
                <div class="input-group-btn">
                    <button id="down" class="btn" style="padding-top: unset;padding-bottom: unset; border:unset;font-size: 20px;z-index: 0;background: none;color: white;" (click)="Addup('100',item)" ><span class="glyphicon glyphicon-plus" style="font-size: 23px;"  ></span></button>
                </div> 
            </div>
        </div>
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12">
            <h4 style="text-align: center;font-family:Ubuntu-Medium !important  ;font-size: 20px;    padding-top: 14px;">  $ {{item.couponType=="P" ? ( (item.price -(item.couponAmt/100))  | number:'.2') : (((item.price -(item.couponAmt))  | number:'.2') )}} </h4>
        </div>
        <div class="col-lg-2 col-xs-2 col-md-2 col-sm-12" style="padding-top: 23px;text-align: center;">
            <button class="btn" style="text-align: center;font-family:Ubuntu-Medium !important  ;font-size: 16px;color: white;background-color: #EE562F;width:72%;outline: none ;height: 35px;border-color: unset;" (click)="open(Addon1,item)">ADD TO CART</button>
        </div>
        <div class="col-lg-1 col-xs-1 col-md-1 col-sm-1" style="padding-top: 23px;cursor: pointer;">
            <i class="fa fa-trash fa-2x" style="color: #EE562F;"  (click)='openfav(Addon,item)'></i>
        </div>
    </div>
    
</div>

<div class="container-fluid" style="padding-bottom: 50PX;padding-top:50PX ;">
    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12">
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6" style="text-align: right;">
            <button class="btn" style="border: 1px solid rgba(111,111,111,0.5); border-radius: 25px;width: 28%;font-family:Ubuntu-Medium !important  ;font-size: 22px;height: 47px;"  (click)='openRemov(RemoveAll)'  >CLEAR ALL</button>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6" style="text-align: left;">
            <button class="btn" style="border: 1px solid rgba(111,111,111,0.5);color: white;width: 28%;font-family:Ubuntu-Medium !important  ;height: 47px; font-size: 22px; border-radius: 25px;background-color: #EE562F;" (click)="Addtotalcart()" >ADD TO CART</button>
        </div>
    </div>
</div>

<ng-template #RemoveAll let-modal id="" style="width:90%">

    <div class="modal-header" style="border: none;padding-bottom: unset;background-color: #EE562F;">
     
  
    </div>
  
    <div class="modal-body" style="padding:25px;padding-top: unset;">
  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;"  style="padding-bottom: 10px;" >
    <fieldset>
      <div style="padding-top: 10px;">
          <h3 style="text-align: center;">Are You Want to delete Favourites Item(s)</h3>
      </div>
        <!-- Button -->
        <div class="row" style="padding-top:10px ;">
           
           
                <div class="col-md-6" style="text-align: center;">
                    <button  class="btn " style="width: 50%; background-color:#EE562F;color:white" (click)="Remove()" >OK</button>
                </div>
                <div class="col-md-6" style="text-align: center;">
                    <button  class="btn " style="width: 50%; background-color:#EE562F;color:white" (click)="modal.dismiss('Cross click')" >Cancel</button>
                </div>
            </div>
    
        

    </fieldset>
  
    </div>
    </div>

  
   
  
  </ng-template>
<ng-template #Addon let-modal id="" style="width:90%">

    <div class="modal-header" style="border: none;padding-bottom: unset;background-color: #EE562F;border-radius: 20px;">
      
  
    </div>
  
    <div class="modal-body" style="padding:25px;padding-top: unset;">
  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;"  style="padding-bottom: 10px;" >
    <fieldset>
      <div style="padding-top: 10px;">
          <h3 style="text-align: center;">Are You Want to delete on Favourites Item(s)</h3>
      </div>
        <!-- Button -->
        <div class="row" style="padding-top:10px ;">
           
           
                <div class="col-md-6" style="text-align: center;">
                    <button  class="btn " style="width: 50%; background-color:#EE562F;color:white" (click)="RemoveCart()" >OK</button>
                </div>
                <div class="col-md-6" style="text-align: center;">
                    <button  class="btn " style="width: 50%; background-color:#EE562F;color:white" (click)="modal.dismiss('Cross click')" >Cancel</button>
                </div>
            </div>
    
        

    </fieldset>
  
    </div>
    </div>

  
   
  
  </ng-template>

  <ng-template #Addon1 let-modal id="" style="width:90%">

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold;">ADD ON</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;"  style="padding-bottom: 10px;" >
    <fieldset>
        <!-- Sign In Form -->
        <!-- Text input-->
        <div class="control-group">

            <div class="controls">
                <textarea required="" id="userid" name="userid"  [(ngModel)]="AddonText" width="200" style="height: 200px;" class="form-control " placeholder=""></textarea>
             
            </div>
        </div>


        <!-- Button -->
        <div class="control-group">
            <label class="control-label" for="signin"></label>
            <div class="controls" style="    text-align: center;">
                <button id="signin" class="btn " style="background-color:#EE562F;color:white" (click)="AddonCartitems('Addon')" >Submit</button>
            </div>
        </div>

    </fieldset>
  
    </div>
    </div>

  
   
  
  </ng-template>