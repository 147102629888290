<title>Search</title>
<div class="container-fluid" style="padding-top: 35px;">
    <div class="col-md-3 col-lg-2 col-sm-3 col-xs-12" style="cursor: pointer;" (click)="menuopen('Filter')">
        <h2 style="margin-top: unset;text-align: center;padding-top: 10px;">
            Filter
            <img src="../../assets/Images/icon/filter.png" />
        </h2>
    </div>
    <div class="col-lg-10 col-md-9 col-xs-6 col-sm-6" style="font-size: 20px;">
        <h3>Search Result for "{{Search}}"</h3>
    </div>
</div>


<div class="container-fluid" >
    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12"  style="padding-top:30px;padding-bottom: 50px;"  data-aos="fade-up-left">
       
        <div class="col-lg-3 col-xs-12 col-sm-6 col-md-4" *ngFor="let item of SplArray" style="padding-top:30px">
            <div class="col-lg-10 col-md-11 col-xs-12 col-sm-12 " style="padding:unset;border:1px solid rgba(111,111,111,0.8)">
                <div  *ngFor="let menu of item.menuvariance | slice:0:1" class="imagehover">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="padding:unset">
                    
                    <div  class=" img__wrap"  >
                        <div class="trapezoid" style="position: absolute;" *ngIf='item.discountAmt != 0 ? true :false'><span class="test">{{item.discountAmt}}% OFF</span></div>
                        <img src={{menu.imageUrl1}} class="center-block  img__img" style="width:90%;height:198px"  [routerLink]="'/Food/' + item.menuItemId  +'/'+item.quan +'/' + item.adddon" />
                        <p class="img__description" *ngIf='menu.description1 != null && menu.description1 !="" ? true :false' >{{menu.description1}}</p>
                  
                    </div>
                </div>

                <div class="row pricerange" style="padding:10px; ">
                    <div class="container" style="width:100%; height: 32px;">
                       
                        <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? false : true">
                            <h5>{{item.itemName}} </h5>
                        </div>
                        <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                            <h5>{{item.itemName}} - {{menu.menuvarianceName1}}</h5>
                        </div>
                        <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4" style="padding:unset;    z-index: -13;">
                           
                            <div class=" rating1"  style="text-align: center;background-color:#48C479; padding-top: 4px; padding-right: unset;border-left:1px">
                                <p style="color:white"  >
                                  $ {{(menu.price1)  | number:'.2'}}
                                </p>
                            </div>
                           
                        </div>
                       
                    </div>

                </div>
                <div class="row" *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                    <div class="container"  style="    height: 56px;">
                        <div class="col-md-4" style="padding: 5px 2px 7px 2px !important; cursor: pointer;" *ngFor="let menu1 of item.menuvariance | slice:0:3;">
                            <h5 style="font-size: 11px !important;text-align:center" class="menuvariance" (click)="Addvariance(item,menu1.menuVariance)" >{{menu1.menuvarianceName}}</h5>
                        </div>
                    </div>
                   
                </div>
                
                </div>
                <div style="border-top: 1px solid rgba(111,111,111,0.8) ;">
                    <div class="col-md-3"></div>
                      <div class="col-lg-6 col-xs-12 col-md-6 col-sm-12"  style="padding-top:10px ;padding-right:unset;padding-left:unset;" >
                        <div class="input-group">
                            <div class="input-group-btn">
                                <button id="down" class="btn" style="border:unset;z-index: 0;background: none;padding-right:unset;    font-size: 16px;" (click)="Adddown('1',item)" ><span style="font-size: 25px;" class="glyphicon glyphicon-minus" ></span></button>
                            </div>
                            <input type="number" id="AddNumber"  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"  style=" border:unset;z-index: 0;text-align: center;    font-size: 25px; font-family: Ubuntu-BOLD !important;box-shadow:unset;padding-left: 14px;background-color: snow;" class="form-control input-number "  [(ngModel)]="item.quan" (change)="AddcartQuant(item)"  />
                            <div class="input-group-btn">
                                <button id="up" class="btn" style="border:unset;z-index: 0;background: none;    font-size: 16px;padding-left:unset" (click)="Addup('100',item)"><span  style="font-size: 25px;" class="glyphicon glyphicon-plus" ></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <div class="">
                    <input type="text" style="height: 48px;width: 100%;text-align: center;" placeholder="ADD SPECIAL COMMENTS"  [(ngModel)]="item.adddon">
                </div>

                <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding:10px">
                    <div class="col-lg-1"></div>
                    
                    <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='item.favId== "" ? true : false'>
                        <div class="Wish" style="top:0px; " (click)="Fav(item)">
                            <img src="../../assets/Images/icon/wishlist.png" id="st" style="width:unset" class="center-block" />

                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='item.favId== "" ? false : true'>
                        <div class="Wish" style="top:0px; " (click)="Fav(item)">
                            <img src="../../assets/Images/wishlist.png" id="st" style="width:unset" class="center-block" />

                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" (click)="AddonCartitems(item)">
                        <img src="../../assets/Images/icon/add-tocart.png" style="width:unset" />

                    </div>
                    <div class="col-lg-1"></div>
                </div>
            </div>
        </div>
    </div>
</div>





<ng-template #Addon let-modal id="" style="width:90%">

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold;">ADD ON</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
  <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;"  style="padding-bottom: 10px;" >
    <fieldset>
        <!-- Sign In Form -->
        <!-- Text input-->
        <div class="control-group">

            <div class="controls">
                <textarea required="" id="userid" name="userid"  [(ngModel)]="AddonText" width="200" style="height: 200px;" class="form-control " placeholder=""></textarea>
             
            </div>
        </div>


        <!-- Button -->
        <div class="control-group">
            <label class="control-label" for="signin"></label>
            <div class="controls" style="    text-align: center;">
                <button id="signin" class="btn " style="background-color:#EE562F;color:white" (click)="AddonCartitems('Addon')" >Submit</button>
            </div>
        </div>

    </fieldset>
  
    </div>
    </div>

  
   
  
  </ng-template>