<div style="padding: 10px 20px 0px 60px ;">
    <a href="/"> <img src="../../assets/Images/Homeicon.png"> Home </a> &nbsp;/&nbsp;<span
        style="font-family: Ubuntu-Regular !important;">SiteMap</span>
</div>
<div class="container" style="padding-top: 30px;">
    <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;">SITEMAP</h4>
    <br>
    <!-- <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;" (click)="Braintree()">Payment</h4> -->
    
    <div class="row">
        <div class="col-lg-4 col-xs-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-lg-4 ">

                </div>
                <div class="col-lg-4 col-xs-12 col-sm-12 col-xs-12 SiteMapHeader" [routerLink]="['/']" style="cursor: pointer;" >
                    Home
                </div>
                <div class="col-lg-4 ">

                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-6 col-xs-6 col-sm-6 col-xs-6" style="border-right: 1px solid; min-height: 70px;">

                </div>
                <div class="col-lg-6 col-xs-6 col-sm-6 col-xs-6">

                </div>
                
            </div>
            <div class="row">
                <div class="col-lg-4 ">
                </div>
                <div class="col-lg-2" style="border-bottom: 1px solid;">

                </div>
                <div class="col-lg-2" style="border-bottom: 1px solid;">

                </div>
                <div class="col-lg-4 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 ">
                </div>
                <div class="col-lg-2" style="min-height: 30px;border-left: 1px solid;" >

                </div>
                <div class="col-lg-2" style="min-height: 30px;border-right: 1px solid;" >

                </div>
                <div class="col-lg-4 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2">
                </div>
                <div class=" col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent">
                    Products
                </div>
                <div class="col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent" [routerLink]="['/About']" style="cursor: pointer;">
                    About Us
                </div>
                <div class="col-lg-2">
                </div>
            </div>
            <div class=" row">
                <div class="col-lg-2">
                </div>
                <div class=" col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent">
                    Categories
                </div>
                <div class="col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent" [routerLink]="['/ContactUs']" style="cursor: pointer;">
                    Contact Us
                </div>
                <div class="col-lg-2">
                </div>
            </div>
            <div class=" row">
                <div class="col-lg-2">
                </div>
                <div class=" col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent">
                    Product Details
                </div>
                <div class="col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent" [routerLink]="['/Terms&Condition']" style="cursor: pointer;">
                    Terms & Conditions
                </div>
                <div class="col-lg-2">
                </div>
            </div>
            <div class=" row">
                <div class="col-lg-2">
                </div>
                <div class=" col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent">
                    Filter Products
                </div>
                <div class="col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent" [routerLink]="['/Privacy']" style="cursor: pointer;">
                    Privacy Polices
                </div>
                <div class="col-lg-2">
                </div>
            </div>
            <div class=" row">
                <div class="col-lg-2">
                </div>
                <div class=" col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent">
                    Restaurant Location 
                </div>
                <div class="col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent">
                   
                </div>
                <div class="col-lg-2">
                </div>
            </div>
            <div class=" row">
                <div class="col-lg-2">
                </div>
                <div class=" col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent"  [routerLink]="['/My Favourites']" style="cursor: pointer;">
                    My Favourites
                </div>
                <div class="col-lg-4 col-xs-6 col-sm-6 col-xs-6 SiteMapContent">
                   
                </div>
                <div class="col-lg-2">
                </div>
            </div>
        </div>
        <div class=" col-lg-4 col-xs-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapHeader">
                    Cart
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-5 col-xs-6 col-sm-6 col-xs-6" style="border-right: 1px solid; min-height: 100px;">

                </div>
                <div class="col-lg-7 col-xs-6 col-sm-6 col-xs-6">

                </div>
                
            </div>
            <div class="row">
                <div class="col-lg-2 ">

                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent" [routerLink]="['/CheckOut']" style="cursor: pointer;">
                    Cart
                </div>
                <div class="col-lg-2 ">

                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent" (click)="SitemapClick('Order Summary')"  style="cursor: pointer;">
                    Order Summary
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent">
                    Delivery Method
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent">
                    Payment
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-xs-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapHeader">
                    My Account
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-5 col-xs-6 col-sm-6 col-xs-6" style="border-right: 1px solid; min-height: 100px;">

                </div>
                <div class="col-lg-7 col-xs-6 col-sm-6 col-xs-6">

                </div>
                
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent" (click)="SitemapClick('My Account')" style="cursor: pointer;">
                    My Account
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent" (click)="SitemapClick('My Order')" style="cursor: pointer;">
                    My Order
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent" (click)="SitemapClick('Order Details')" style="cursor: pointer;">
                    Order Details
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent" (click)="SitemapClick('My Addresses')" style="cursor: pointer;">
                    My Addresses
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 ">
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12 SiteMapContent" (click)="SitemapClick('Reviews')" style="cursor: pointer;">
                    Reviews
                </div>
                <div class="col-lg-2 ">
                </div>
            </div>
        </div>
    </div>
</div>