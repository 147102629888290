<div class="container-fluid" id="Bac">
<div class="container" style="    padding-top: 12%;height: 100%;padding-bottom: 12%;">
    <div class="col-lg-4 col-md-4 col-xs-2 col-sm-2">

    </div>
    <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12" style="background-color: white;border-radius: 15px;" *ngIf="Login">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top: 30px;padding-bottom: 11px;">
            <img src="../../assets/Images/icon/Logo (2).png" class="center-block">
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-xs-12">
            <div class="form-group" style="padding-top:10px ;">
                <input type="text" placeholder="User Name" class="form-control line" [(ngModel)]="UserName" />
            </div>
            <div class="form-group" style="padding-top:10px ;">
                <input type="text" placeholder="Password" class="form-control line" [(ngModel)]="Password" />
            </div>
            <!-- <h4 style="text-align: right;cursor: pointer;"  (click)="Forgot()" >Forgot Password ?</h4> -->
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="form-group">
               
                <button class="btn" style="text-align: center;margin-top: 10px;color: white;    font-size: 20px; " (click)="LoginClick()" >LOGIN</button>
            </div>
    
        </div>
        <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
            <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
            <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
          </div>
    </div>
    <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12" style="background-color: white;border-radius: 15px;"  *ngIf="!Login">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top: 30px;padding-bottom: 11px;">
            <img src="../../assets/Images/icon/Logo (2).png" class="center-block">
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-xs-12">
            <div class="form-group" style="padding-top:10px ;">
                <input type="text" placeholder="Enter Your Email Id" class="form-control line" />
            </div>
           
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="form-group">
               
                <button class="btn" style="text-align: center;margin-top: 10px;color: white;    font-size: 20px; ">SUBMIT</button>
            </div>
    
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="form-group">
               
                <button class="btn btn1" style="text-align: center;margin-top: 10px;color: black;    font-size: 20px;border: 0.5px solid; " (click)="login()">LOGIN</button>
            </div>
    
        </div>

    </div>
    <div class="col-lg-4 col-md-4 col-xs-2 col-sm-2">

    </div>
</div>
</div>

<ng-template #Forget let-modal >

    <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
  
      <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold !important;">Forgot Password</h3>
  
      <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
  
        <span aria-hidden="true">×</span>
  
      </button>
  
    </div>
  
    <div class="modal-body" style="padding:35px;">
 
  
    <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
        <div class="form-group" style="padding-top:10px ;">
            <input type="text" placeholder="Enter Your Email Id" class="form-control line" />
        </div>
        <div class="row" style="padding-top: 10px;">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" id="Instant" style="display: block;">
                <div class="input-group" style=" padding-bottom:5px;" draggable="true" ondragstart="return false" ondrop="return false">
                    <div class="input-group-addon" style="background-color:#294f91;cursor:pointer;height:0px;">
                        <span id="refresh2"><img style="width:20px; height:20px;" src="../assets/Images/icons8-refresh-30.png"></span>

                    </div>

                    <input type="text" class="form-control" style="margin-bottom: 0px; margin-top: 0px;     width: 100px; color: black; height: 40px; font-size: 16px; border-radius: 2px; font-weight: bolder; text-decoration: line-through; display: inline-block; background-color: rgb(255, 255, 255);" id="mainCaptcha" disabled="" draggable="true" ondragstart="return false" ondrop="return false">

                    <div style="margin-top:10px; color: #D8000C; border-radius: 7px;" id="log"></div>
                </div>
            </div>
            <div class="col-lg-8 col-md-4 col-sm-4 col-xs-12" id="Instant1" style="display: block;">
                <div class="form-group box" style="padding-left: 22%;">
                    <input type="text" style="border-top:0px;width: 100%; border-left:0px; border-right:0px; border-radius: 0px; border-bottom: 1px solid ;height: 40px; font-size: 14px; font-weight: bolder; text-decoration: line-through; display: inline-block;" id="txtInput" placeholder="Enter Captcha" ng-model="Data3.shCaptcha" name="captcha" value="" class="ng-pristine ng-untouched ng-valid ng-empty">
                </div>
            </div>


        </div>
        
        <div class="form-group" style=" margin-top: 10px;margin-bottom: 30px; border-radius: 34px;border: 1px solid ;background-color: #EE562F;text-align: center;">
           
            <h3 class="" style="text-align: center;margin-top: 10px;color: white;" >SUBMIT</h3>
        </div>
    </div>
  
    </div>
  
   
  
  </ng-template>

