import { Component, OnInit , ViewChild,ElementRef} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import{ HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from  '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { interval } from 'rxjs';
@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.css','../../assets/bootstrap.min.css','../../assets/Style.css','../../assets/datatables.min.css'],
  encapsulation: ViewEncapsulation.None
})

export class ViewOrdersComponent implements OnInit {
  displayedColumns: string[] = ['orderId', 'orderDate', 'deliveryId', 'orderAmount','Status'];
  dataSource: MatTableDataSource<any[]>;
ViewOrder=[] as any;
config: any;
collection = [];
Name:string;
MobileNo:string;
Address:string;
Vieworderdate:string;
OrderId : string;
OrderDate:string;
DeliveryType :string;
Status:string;
tax:string;
ResName:string;
total :string;

Monthdate:string;
DeliveryAmt :string;
DiscountAmt:string;
Tips:string;
Coupon:string;
OrderTotal:string;
OrderGRID =[] as any;
items = [];
pageOfItems: Array<any>;
DeliveryAddress =true;
@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
@ViewChild('TABLE') table: ElementRef;
  constructor(private modalService: NgbModal,private http:HttpClient) { 
    interval(20000).subscribe((func => {
      debugger;
      this.Vieworderscreen(this.Vieworderdate);
    }))
  }
  closeResult: string;
  exportexcel(): void 
  {
    debugger;
     /* table id is passed over here */   
     const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
     //let element = document.getElementById('data-table-5'); 
    // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
   // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, workSheet, 'Vieworders');

     /* save to file */
     XLSX.writeFile(wb, "ViewOrder.xlsx");
    
  }

  ngOnInit(): void {
    debugger;
    this.Monthdate="";
    this.Vieworderdate="Today";
    this.Vieworderscreen(this.Vieworderdate);
   this.Timeout();
    this.loadScript('../../assets/js/Alert.js');
    this.loadScript('../../assets/framework.js');
    this.loadScript('../../assets/datatables.min.js');
  
  }
  applyFilter(filterValue: string) {
    debugger;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}
  OrderStatus(OrderType,OrderId){
    debugger;
    var OrderStatus = this.http.get(environment.apiUrl +'ViewOrders/OrderStatus?OrderType='+OrderType+'&OrderId='+OrderId +'&UserId=1').toPromise().then((data:any)=>{
      debugger;
      if(data.length !=0){
        this.ViewOrder=data;
     //  this.ReOrder=data;
      }
     // this.check();
          }).catch(function(data:any){
            debugger;
            var tes = data.error.text;
if(tes == "Success"){
  alert("Successfully Status Updated");
}
else{
  alert("Status update Failed");
}
          });
  }
  printer(): void {
    debugger;
    let printContents, popupWin;
    printContents = document.getElementById('OrderDetail').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}
Datechose(Monthdate){
debugger;
this.Vieworderdate=Monthdate;
this.Vieworderscreen(this.Vieworderdate);
}
Timeout(){
  setTimeout(function () {
    console.log('hide');
    debugger;
    var test = this.Vieworderdate;
    if(test != undefined){
      this.Vieworderscreen(this.Vieworderdate);
    }
  
    ///this.showElement = false; // here... this has different context
  }, 2000);
}
Vieworderscreen(Monthdate){
  
var ViewOrder = this.http.get(environment.apiUrl +'ViewOrders/ViewOrders?UserId=1&usertype=Admin&Date='+Monthdate).toPromise().then((data:any)=>{
  debugger;
  if(data.length !=0){
    this.items=data;
    this.dataSource  = new MatTableDataSource(data); 
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
  this.OrderView(data["0"].orderId);
 this.dataSource.sort = this.sort;

  }
 // this.check();
      });
}
OrderView(itemId){
  var OrderView = this.http.get(environment.apiUrl +'ViewOrders/OrdersView?OrderId=' +itemId).toPromise().then((data:any)=>{
    debugger;
    if(data.length !=0){
      this.OrderGRID=(data) ;

      if(data["0"].deliveryAddressId != 0){
        
        this.Address=data["0"].addressLine1 + " " +data["0"].addressLine2 + " " + (data["0"].City !=  null ? data["0"].City :"") + " " +  (data["0"].State !=  null ? data["0"].State :"")  + " " +  (data["0"].Zip !=  null ? data["0"].Zip :"")   ;
       this.DeliveryAddress=true;
      }
      else{
        this.DeliveryAddress=false;
      }
      this.Name = data["0"].firstName + "-" + (data["0"].lastName != null ? data["0"].lastName : "")  ;
        this.MobileNo=data["0"].mObile;
      this.ResName=data["0"].resName ;
    
     this.DeliveryAmt=data["0"].deliveryAmount ;
     this.Tips=data["0"].tipsAmount ;
     this.tax=data["0"].taxAmount ;
     this.DiscountAmt=data["0"].discountAmount ;
     this.DeliveryType=data["0"].deliveryType ;
     this.OrderDate=data["0"].orderDate ;
     this.OrderId=data["0"].orderId ;
     this.OrderTotal=data["0"].orderAmount ;
     this.Status=data["0"].type ;
    //  this.ViewOrder=data;
   //  this.ReOrder=data;
    }
   // this.check();
        });
}
  open(content,itemId) {
    debugger;
    var OrderView = this.http.get(environment.apiUrl +'ViewOrders/OrdersView?OrderId=' +itemId).toPromise().then((data:any)=>{
      debugger;
      if(data.length !=0){
        this.OrderGRID=(data) ;

        if(data["0"].deliveryAddressId != 0){
          this.Name = data["0"].firstName + "-" + (data["0"].lastName != null ? data["0"].lastName : "")  ;
          this.MobileNo=data["0"].mObile;
          this.Address=data["0"].addressLine1 + " " +data["0"].addressLine2 + " " + (data["0"].City !=  null ? data["0"].City :"") + " " +  (data["0"].State !=  null ? data["0"].State :"")  + " " +  (data["0"].Zip !=  null ? data["0"].Zip :"")   ;
         this.DeliveryAddress=true;
        }
        else{
          this.DeliveryAddress=false;
        }
      
       this.DeliveryAmt=data["0"].deliveryAmount ;
       this.Tips=data["0"].tipsAmount ;
       this.tax=data["0"].taxAmount ;
       this.DiscountAmt=data["0"].discountAmount ;
       this.DeliveryType=data["0"].deliveryType ;
       this.OrderDate=data["0"].orderDate ;
       this.OrderId=data["0"].orderId ;
       this.OrderTotal=data["0"].orderAmount ;
       this.Status=data["0"].type ;
      //  this.ViewOrder=data;
     //  this.ReOrder=data;
      }
     // this.check();
          });
    this.modalService.open(content, {windowClass: 'OrderPop',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });

  }
  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {

      return 'by pressing ESC';

    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {

      return 'by clicking on a backdrop';

    } else {

      return  `with: ${reason}`;

    }

  }

 
      public loadScript(url: string) {
        const body = <HTMLDivElement> document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
      }
}
