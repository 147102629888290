
<main class="body-content">

  <!-- Navigation Bar -->
  <nav class="navbar ms-navbar" style=" margin: unset;">
    <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
        class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
    </div>
  
  </nav>

  <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">Rating</h4>
    </div>
    <div class="col-md-7 align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
        <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
          (click)="Show()"><i class="fa fa-plus-circle"></i> View Rating</button>
      </div>
    </div>
    <div *ngIf="!Drop" class="col-md-12" id="Menus" style="    margin-top: 20px;">
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Rating Details</button>
        </div>


      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Restaurant Name</label>
            <select class="form-control" [(ngModel)]="restaurantId" (ngModelChange)="onResturantChange($event)">
              <option value="0">Select Restaurant</option>
              <option *ngFor="let rst of Resturants" value={{rst.restaurantId}}>{{rst.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Category</label>
            <select class="form-control" [(ngModel)]="categoryId">
              <option value="0">Select Category</option>
              <option *ngFor="let cat of Category" value={{cat.categoryId}}>{{cat.categoryName}}</option>
            </select>
          </div>
        </div>

      </div>
      <div class="col-md-12" style="padding-bottom: 30px;">
        <div class="col-md-3">
          <button class="btn" style="width: 100%; color: white; background-color :#ED562E;"
            (click)="ViewRatings()">View</button>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
        <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
      </div>

    </div>
    <!--/span-->
  </div>


  <div class="ms-content-wrapper">
    <div class="row">

      <div class="col-md-12">

        <div class="ms-panel">
          <div class="ms-panel-body">
            <div class="table-responsive">
              <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="data-table-5_length">
                      <label style="padding-right: 25px;">Search</label>
                      <input type="text" placeholder="Search"
                        style="border: unset;background:  #ececec;height: 37px;width: 84%;"> <i
                        class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;"
                      [(ngModel)]="searchText" (keyup)="applyFilter($event.target.value)">
                      <!-- <select style="text-align: left;height: 26px;width: 27%;    border-radius: 4px;">
                                        <option>Search By</option>
                                    </select> -->
                                    <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" (click)="exportexcel()" style="cursor: pointer;">
                      <!-- <button class="btn"
                        style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;"
                        (click)="exportexcel()">Export</button> -->

                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                    <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSortmat-table
                      [dataSource]="dataSource" #table style="width: 100%;" matSort>
                      <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef style="color: white !important;background-color: #ED562E">
                          S.NO </th>
                        <td mat-cell *matCellDef="let item; let j = index">
                          {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                      </ng-container>
                      <ng-container matColumnDef="restaurantName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> RESTAURANT NAME </th>
                        <td mat-cell *matCellDef="let element" >
                          {{element.restaurantName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="customerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> CUSTOMER NAME </th>
                        <td mat-cell *matCellDef="let element">
                          {{element.customerName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="rating">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> RATING </th>
                        <td mat-cell *matCellDef="let element"> {{element.rating}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="itemName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> ITEM NAME </th>
                        <td mat-cell *matCellDef="let element" > {{element.itemName}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="average">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> AVERAGE </th>
                        <td mat-cell *matCellDef="let element"> {{element.average}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="ratingDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                          style="color: white !important;background-color: #ED562E"> RATING DATE </th>
                        <td mat-cell *matCellDef="let element"> {{element.ratingDate}}
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons>
                    </mat-paginator>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</main>