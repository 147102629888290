
<main class="body-content">

  <!-- Navigation Bar -->
  <nav class="navbar ms-navbar" style=" margin: unset;">
    <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
        class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
    </div>
  
  </nav>

  <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">Tips</h4>
    </div>
    <div class="col-md-7 align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
        <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
          (click)="Show()"><i class="fa fa-plus-circle"></i> Generate Tips</button>
      </div>
    </div>
    <div *ngIf="!Drop" style="    margin-top: 20px;">
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Tips Details</button>
        </div>


      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Restaurant Name</label>
            <select class="form-control" [(ngModel)]="restaurantId" (ngModelChange)="onResturantChange($event)">
              <option value="0">Select Restaurant</option>
              <option *ngFor="let rst of Resturants" value={{rst.restaurantId}}>{{rst.name}}</option>
            </select>
          </div>
        </div>

      </div>
      <div class="col-md-12" style="padding-bottom: 30px;">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Waiter Name</label>
            <select class="form-control">
              <option  value="0">All</option>
              <option *ngFor="let wtr of Waiter" value={{wtr.waiterId}}>{{wtr.userName}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-12 ">
        <h6>Duration</h6>
        <div class="col-md-3" style="padding-top: 10px;">
          <div class="form-group">
            <label class="control-label">From</label>
            <input type="date" class="form-control" [(ngModel)]="DateFrom">
          </div>
        </div>
        <div class="col-md-3" style="padding-top: 10px;">
          <div class="form-group">
            <label class="control-label">To</label>
            <input type="date" class="form-control" [(ngModel)]="DateTo">
          </div>
        </div>
      </div>
      <div class="col-md-12 ">

        <div class="col-md-3" style="padding-top: 20px;padding-bottom: 30px;">
          <button class="btn" style="width: 100%; color: white; background-color :#ED562E;" (click)="ViewTips()">Generate Tips
            Report</button>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
        <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
      </div>
    </div>
    <!--/span-->
  </div>

  <div class="ms-content-wrapper">
    <div class="row">

      <div class="col-md-12">

        <div class="ms-panel">
          <div class="ms-panel-body">
            <div class="table-responsive">
              <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="data-table-5_length">
                      <label style="padding-right: 25px;">Search</label>
                      <input type="text" placeholder="Search"
                        style="border: unset;background:  #ececec;height: 37px;width: 84%;"  [(ngModel)]="searchText" (keyup)="applyFilter($event.target.value)" > <i
                        class="fa fa-search fa-2x" style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                      <!-- <select style="text-align: left;height: 26px;width: 27%;    border-radius: 4px;">
                        <option>Search By</option>
                      </select> -->
                      <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" (click)="exportexcel()" style="cursor: pointer;">
                      <!-- <button class="btn"
                        style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;" (click)="exportexcel()">Export</button> -->
                      <!-- <label style="padding-left: 50px;">Show</label> -->
                      <!-- <label class="switch" style="margin-left: 10px;">
                        <input type="checkbox" checked>
                        <span class="slider round"></span>
                      </label> -->
                    </div>
                  </div>
                </div>
                <div class="row" style="padding-top: 15PX;">
                  <div class="col-sm-12 mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSortmat-table
                      [dataSource]="dataSource" #table style="width: 100%;" matSort>
                      <ng-container matColumnDef="orderId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important; background-color: #ED562E;"> OrderId
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
                      </ng-container>
                      <ng-container matColumnDef="orderAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;background-color: #ED562E"> Order
                          Amount </th>
                        <td mat-cell *matCellDef="let element"> {{element.orderAmount}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="orderDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;background-color: #ED562E"> Order
                          Date </th>
                        <td mat-cell *matCellDef="let element" > {{element.orderDate}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="tipsAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;background-color: #ED562E"> Tips
                          Amount </th>
                        <td mat-cell *matCellDef="let element"> {{element.tipsAmount}}
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="data-table-5_info" role="status" aria-live="polite">Showing 1 to 10
                      of 36 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div class="dataTables_paginate paging_simple_numbers" id="data-table-5_paginate">
                      <ul class="pagination has-gap">
                        <li class="paginate_button page-item previous disabled" id="data-table-5_previous">
                          <a href="" aria-controls="data-table-5" data-dt-idx="0" tabindex="0"
                            class="page-link">Previous</a></li>
                        <li class="paginate_button page-item active"><a href="" aria-controls="data-table-5"
                            data-dt-idx="1" tabindex="0" class="page-link">1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="" aria-controls="data-table-5" data-dt-idx="2"
                            tabindex="0" class="page-link">2</a></li>
                        <li class="paginate_button page-item "><a href="" aria-controls="data-table-5" data-dt-idx="3"
                            tabindex="0" class="page-link">3</a></li>
                        <li class="paginate_button page-item ">
                          <a href="#" aria-controls="data-table-5" data-dt-idx="4" tabindex="0" class="page-link">4</a>
                        </li>
                        <li class="paginate_button page-item next" id="data-table-5_next"><a href="#"
                            aria-controls="data-table-5" data-dt-idx="5" tabindex="0" class="page-link">Next</a></li>
                      </ul>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</main>