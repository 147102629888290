<title>Welcome to Zapata Mexician</title>
  <div style="overflow:hidden;">
    <div class="slider-container row" style="padding-top: 0px; " >
  
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                <!-- Indicators -->
                <ol class="carousel-indicators">
                    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2"></li>
                </ol>
  
                <!-- Wrapper for slides -->
                <div class="carousel-inner">
                    <div class="item active" >
                       
                        <img src="../../assets/Images/Banner/Slider_Pic.jpg" alt="test" style="width: 100%;">
                    </div>
                </div>
            </div>
  
        </div>
    </div>
  
  
  
    <div class="container-fluid" style="padding:15px" data-aos="fade-up" data-aos-anchor-placement="top-center">
        <div class="row">
            <div class="col-lg-1 col-md-1"></div>
            <div class="col-sm-10 col-xs-10 col-md-6 col-lg-4" style="box-shadow: 0px 5px 14px;padding-top: 20px;background-color:white;position:absolute;z-index:6;padding-bottom:20px" id="Filter">
                <div class="col-lg-1 col-md-1"></div>
                <div class="col-lg-11 col-md-11 col-xs-12 col-sm-12" style="padding:unset">
                    <ul style="list-style: none;padding:unset">
                        <li>
                            <h4>
                                <span style="color:black;font-size:18px; font-family: Ubuntu-Regular!important;text-align:left">
                                    Filters
                                    <img src="../../assets/Images/icon/filter.png"  style="width:44px;padding-left:10px"/>
                                </span>
                                <i class="pull-right " aria-hidden="true" style="color: black; font-family: Ubuntu-Regular!important;font-size:25px;cursor:pointer" (click)="menuopen('Filter')"> x</i>
                            </h4>
                            
                        </li>
                    </ul>
                    <ul style="color:black; list-style: none;  padding-left: unset;padding-top:15px">
                        <li class="treeview" >
                            <h4 href="" style="cursor: pointer; font-size:17px" (click)="ShowHideCategory('menuCategory')" >
  
                                <span style="color:black;font-size: unset;">CATEGORY</span>
                                <i class="fa fa-plus glyphicon glyphicon-plus pull-right" id="Categoryplus" aria-hidden="true" style="color: black;"></i>
                            </h4>
                            <ul class="treeview-menu" id="menuCategory" style="list-style:none;padding-left: unset;padding-top: 15px;">
                                <li>
                                    <div class="row">
                                    <div class="col-lg-4 col-md-4 col-xs-6 col-sm-4" style="padding:unset"  *ngFor="let it of ItemsArrayMenu" >
                                        <h4 style="font-family: Ubuntu-Regular!important;font-size:14PX"><input type="checkbox" value={{it.categoryName}} id="CategoryMenulist" (click)="handleSelected($event,it)" /> <label style="padding-left:10px;font-family: Ubuntu-Regular!important;">{{it.categoryName}}</label> </h4>
                                    </div>
                                </div>
                                </li>
                            
                            </ul>
                        </li>
                    </ul>
     
                    <ul style="color:black;padding-top:30px;  list-style: none;padding-left: unset;">
                        <li class="treeview" >
                            <h4 href="" style="cursor: pointer;  font-size:17px" (click)="ShowHidePrice('PriceShow')">
  
                                <span style="color:black;;font-size: unset;">PRICE RANGE</span>
                                <i class="fa fa-plus glyphicon glyphicon-plus pull-right" id="priceplus" aria-hidden="true" style="color: black;"></i>
                            </h4>
                            <ul class="treeview-menu" id="PriceShow" style="list-style:none;padding-left: unset;padding-top: 15px;padding-bottom: 25px;">
                                <li>
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                                    <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding:unset">
                                        <input type="text" placeholder="$0.0" style="width: 80%;" [(ngModel)]="FromPrize" />
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
                                        <p>To </p>
                                    </div>
  
                                    <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3" style="padding:unset">
                                        <input type="text" placeholder="$0.0" style="width: 80%;"  [(ngModel)]="ToPrize" (change)="PriceList()"/>
                                    </div>
  
                                    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 " style="padding-top:20px;  padding: unset;padding-bottom: 67px;">
                                        <div class="rangeslider center-block" style="padding-top: 17px;width: 76%;text-align:center;left: 38px;">
                                            <input class="min" id="MinIndex" name="range_1" type="range" min="1" max="100" value="0" />
                                            <input class="max" id="Maxindex" name="range_1" type="range" min="1" max="100" value="100" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    
  
                    <!-- <ul style="color:black; list-style: none;padding-left: unset;padding-top:15px">
                        <li class="treeview" >
                            <h4 href="" style="cursor: pointer; font-size:17px" (click)="ShowHidePayment('Payment')">
  
                                <span style="color:black;;font-size: unset;">OFFERS</span>
                                <i class="fa fa-plus glyphicon glyphicon-plus pull-right" id="Paymentplus" aria-hidden="true" style="color: black;"></i>
                            </h4>
                            <ul class="treeview-menu" id="Payment" style="list-style:none;padding-left: unset;padding-top: 15px;padding-bottom: 25px;">
                                <li>
                                    <div class="col-lg-4 col-md-4 col-xs-6 col-sm-4" style="padding:unset">
                                        <h4 style="font-family: Ubuntu-Regular!important;font-size:15px"><input type="checkbox" /><label style="padding-left:10px;font-family: Ubuntu-Regular!important;" (click)='handleoffer($event,"Coupon")'> Coupon</label></h4>
                                    </div>
  
                                    <div class="col-lg-4 col-md-4 col-xs-6 col-sm-4" style="padding:unset">
                                        <h4 style="font-family: Ubuntu-Regular!important;font-size:15px"> <input type="checkbox" /><label style="padding-left:10px;font-family: Ubuntu-Regular!important;" (click)='handleoffer($event,"Discount")' > Discount</label></h4>
                                    </div>
                                   
                                </li>
                            </ul>
                        </li>
                    </ul> -->
  
  
                </div>
            </div>
        </div>
    </div>
  
  
    <div class="container-fluid" style="padding-top:20px;padding-bottom: 30px;" data-aos="fade-up" data-aos-anchor-placement="top-center">
        <div class="col-md-3 col-lg-2 col-sm-3 col-xs-12" style="cursor: pointer;" (click)="menuopen('Filter')">
            <h2 style="margin-top: unset;text-align: center;">
                Filter
                <img src="../../assets/Images/icon/filter.png" />
            </h2>
        </div>
        <div class="col-md-9 col-sm-9 col-lg-10 col-xs-12" style="padding:unset">
            <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding:unset">
                <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2 FF" style="  padding:unset;  margin-bottom: 10px;width: 15%;"  *ngFor="let it of ItemsArrayMenu">
                  <img src={{it.imageUrl}} class="Imagecolor">
                    <a style="font-size: 16px;color:unset" href=#{{it.categoryName}} target="_self" id="MhF_menu">  {{it.categoryName}}</a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="Filter">
   <div  *ngFor="let item of AllArray">
    <div  class="container-fluid carousel-wrap" *ngIf="item.viewAll=='All'? true :false"  style="width:96%;border:1px solid rgba(111,111,111,0.5);margin-top:50px"  data-aos="fade-up-left"> 
        <h1 style="float:left;position:absolute" id={{item.subCategoryName}}>{{item.subCategoryName}}</h1>
        <h5 style="text-align:right; padding-top:20px;cursor: pointer; color: #b62601; " (click)="Allcilick(item)" class="Lu">View All</h5>
        <div style="padding-top:40px;padding-bottom:50px;    padding-left: 20px;margin: 21px;">
            <owl-carousel-o [options]="customOptions" class="">
                <ng-template carouselSlide *ngFor="let Menuitem of item.menuitem" >
                    <div class="" style="border:1px solid rgba(111,111,111,0.8);width: 87%; "  >
                        <div  *ngFor="let menu of Menuitem.menuvariance | slice:0:1">
                                        <!-- <div  class=" img__wrap"  >
                                            <div class="trapezoid" style="position: absolute;" *ngIf='item.discountAmt != 0 ? true :false'><span class="test">{{item.discountAmt}}% OFF</span></div>
                                            <img src={{menu.imageUrl1}} class="center-block  img__img" style="width:90%;height:198px"  [routerLink]="'/Food/' + item.menuItemId  +'/'+item.quan +'/' + item.adddon" />
                                            <p class="img__description" *ngIf='menu.description1 != null && menu.description1 !="" ? true :false' >{{menu.description1}}</p>
                                      
                                        </div> -->
                                        <div  class=" img__wrap"  >
                                            <div class="trapezoid" style="position: absolute;" *ngIf='Menuitem.discountAmt != 0 ? true :false'><span class="test">{{Menuitem.discountAmt}}% OFF</span></div>
                                            <img src={{menu.imageUrl1}} class="center-block  img__img" style="width:90%;height:198px"  [routerLink]="'/Food/' + Menuitem.menuItemId  +'/'+Menuitem.quan +'/' + Menuitem.adddon" />
                                            <p class="img__description" *ngIf='menu.description1 != null && menu.description1 !="" ? true :false' >{{menu.description1}}</p>
                                      
                                        </div>
                                        <div class="row" style="padding:10px; " >
                                            <div class="container" style="width:100%;height: 32px; ">
                                                
                                                <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? false : true">
                                                    <h5>{{Menuitem.itemName}} </h5>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                                                    <h5>{{Menuitem.itemName}} - {{menu.menuvarianceName1}}</h5>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4"  style="padding:unset; z-index: -13  ">
                                                   
                                                    <div class=" rating1" style="text-align: center;background-color:#48C479; padding-top: 4px; padding-right: unset;border-left:1px">
                                                        <p style="color:white"  >
                                                          $ {{(menu.price1)  | number:'.2'}}
                                                        </p>
                                                    </div>
                                                   
                                                </div>
                                               
                                            </div>
                    
                                        </div>
                                        <div class="row" *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                                            <div class="container"  style="    height: 56px;">
                                                <div class="col-md-4" style="padding: 5px 2px 7px 2px !important; cursor: pointer;" *ngFor="let menu1 of Menuitem.menuvariance | slice:0:3;">
                                                    <h5 style="font-size: 11px !important;text-align:center" class="menuvariance" (click)="Addvariance(Menuitem,menu1.menuVariance)" >{{menu1.menuvarianceName}}</h5>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                       
                                        <div style="border-top: 1px solid rgba(111,111,111,0.8) ;">
                                            <div class="col-md-3"></div>
                                              <div class="col-lg-6 col-xs-12 col-md-6 col-sm-12"  style="padding-top:10px ;padding-right:unset;padding-left:unset;" >
                                                <div class="input-group">
                                                    <div class="input-group-btn">
                                                        <button id="down" class="btn" style="border:unset;z-index: 0;background: none;padding-right:unset;    font-size: 16px;" (click)="Adddown('1',Menuitem)" ><span style="font-size: 25px;" class="glyphicon glyphicon-minus" ></span></button>
                                                    </div>
                                                    <input type="number" id="AddNumber" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" style=" border:unset;z-index: 0;text-align: center;    font-size: 25px; font-family: Ubuntu-BOLD !important;box-shadow:unset;padding-left: 14px;background-color: snow;" class="form-control input-number "  [(ngModel)]="Menuitem.quan" (change)="AddcartQuant(Menuitem)"  />
                                                    <div class="input-group-btn">
                                                        <button id="up" class="btn" style="border:unset;z-index: 0;background: none;    font-size: 16px;padding-left:unset" (click)="Addup('100',Menuitem)"><span  style="font-size: 25px;" class="glyphicon glyphicon-plus" ></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3"></div>
                                        </div>
                                        <div class="">
                                            <input type="text" style="height: 48px;width: 100%;" placeholder="ADD SPECIAL COMMENTS"  [(ngModel)]="Menuitem.adddon">
                                        </div>
                    
                                        <div class="row" style="padding:10px">
                                            <div class="col-lg-1"></div>
                                            
                                            <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== 0 ? true : false'>
                                                <div class="Wish" style="top:0px; " (click)="Fav(Menuitem)">
                                                    <img src="../../assets/Images/icon/wishlist.png" id="st" style="width:unset" class="center-block" />
                    
                                                </div>
                                            </div>
                                           <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== 0 ? false : true'>
                                                <div class="Wish" style="top:0px; " (click)="Fav(Menuitem)">
                                                    <img src="../../assets/Images/wishlist.png" id="st" style="width:unset" class="center-block" />
                    
                                                </div>
                                            </div>
                                            <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" (click)="AddonCartitems(Menuitem)">
                                                <img src="../../assets/Images/icon/add-tocart.png" style="width:unset" />
                    
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                    </div>
                </ng-template>  
                
                </owl-carousel-o>
            </div>
    </div>
    <div class="container-fluid "*ngIf="item.viewAll=='less'? true :false"  >
        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:30px" data-aos="flip-left">
            <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-bottom:30px">
                <h2 style="float:left;position:absolute">{{item.subCategoryName}} </h2>
                <p style="float:right;padding-top:20px;cursor: pointer;" (click)="Allcilick(item)" class="Lu">Show less</p>
            </div>
            <div class="col-lg-3 col-xs-12 col-sm-6 col-md-4"  *ngFor="let Menuitem of item.menuitem" style="padding-top:30px">
                <div class="col-lg-10 col-md-11 col-xs-12 col-sm-12 " style="padding:unset;border:1px solid rgba(111,111,111,0.8)">
                    <div  *ngFor="let menu of Menuitem.menuvariance | slice:0:1" class="imagehover">
                    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="padding:unset">
                        
                        <div  class=" img__wrap"  >
                            <div class="trapezoid" style="position: absolute;" *ngIf='Menuitem.discountAmt != 0 ? true :false'><span class="test">{{Menuitem.discountAmt}}% OFF</span></div>
                            <img src={{menu.imageUrl1}} class="center-block  img__img" style="width:90%;height:198px"  [routerLink]="'/Food/' + Menuitem.menuItemId  +'/'+Menuitem.quan +'/' + Menuitem.adddon" />
                            <p class="img__description" *ngIf='menu.description1 != null && menu.description1 !="" ? true :false' >{{menu.description1}}</p>
                      
                        </div>
                    </div>
  
                    <div class="row pricerange" style="padding:10px; ">
                        <div class="container" style="width:100%; height: 32px;">
                           
                            <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? false : true">
                                <h5>{{Menuitem.itemName}} </h5>
                            </div>
                            <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                                <h5>{{Menuitem.itemName}} - {{menu.menuvarianceName1}}</h5>
                            </div>
                            <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4" style="padding:unset;    z-index: -13;">
                               
                                <div class=" rating1"  style="text-align: center;background-color:#48C479; padding-top: 4px; padding-right: unset;border-left:1px">
                                    <p style="color:white"  >
                                      $ {{(menu.price1)  | number:'.2'}}
                                    </p>
                                </div>
                               
                            </div>
                           
                        </div>

                    </div>
                    <div class="row" *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                        <div class="container"  style="    height: 56px;">
                            <div class="col-md-4" style="padding: 5px 2px 7px 2px !important; cursor: pointer;" *ngFor="let menu1 of Menuitem.menuvariance | slice:0:3;">
                                <h5 style="font-size: 11px !important;text-align:center" class="menuvariance" (click)="Addvariance(Menuitem,menu1.menuVariance)" >{{menu1.menuvarianceName}}</h5>
                            </div>
                        </div>
                       
                    </div>
                    
                    </div>
                    <div style="border-top: 1px solid rgba(111,111,111,0.8) ;">
                        <div class="col-md-3"></div>
                          <div class="col-lg-6 col-xs-12 col-md-6 col-sm-12"  style="padding-top:10px ;padding-right:unset;padding-left:unset;" >
                            <div class="input-group">
                                <div class="input-group-btn">
                                    <button id="down" class="btn" style="border:unset;z-index: 0;background: none;padding-right:unset;    font-size: 16px;" (click)="Adddown('1',Menuitem)" ><span style="font-size: 25px;" class="glyphicon glyphicon-minus" ></span></button>
                                </div>
                                <input type="number" id="AddNumber"  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"  style=" border:unset;z-index: 0;text-align: center;    font-size: 25px; font-family: Ubuntu-BOLD !important;box-shadow:unset;padding-left: 14px;background-color: snow;" class="form-control input-number "  [(ngModel)]="Menuitem.quan" (change)="AddcartQuant(Menuitem)"  />
                                <div class="input-group-btn">
                                    <button id="up" class="btn" style="border:unset;z-index: 0;background: none;    font-size: 16px;padding-left:unset" (click)="Addup('100',Menuitem)"><span  style="font-size: 25px;" class="glyphicon glyphicon-plus" ></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <div class="">
                        <input type="text" style="height: 48px;width: 100%;text-align: center;" placeholder="ADD SPECIAL COMMENTS"  [(ngModel)]="Menuitem.adddon">
                    </div>

                    <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding:10px">
                        <div class="col-lg-1"></div>
                        
                        <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== "" ? true : false'>
                            <div class="Wish" style="top:0px; " (click)="Fav(item)">
                                <img src="../../assets/Images/icon/wishlist.png" id="st" style="width:unset" class="center-block" />

                            </div>
                        </div>
                        <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== "" ? false : true'>
                            <div class="Wish" style="top:0px; " (click)="Fav(Menuitem)">
                                <img src="../../assets/Images/wishlist.png" id="st" style="width:unset" class="center-block" />

                            </div>
                        </div>
                        <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" (click)="AddonCartitems(Menuitem)">
                            <img src="../../assets/Images/icon/add-tocart.png" style="width:unset" />

                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                </div>
            </div>
  
        </div>
    </div>
   </div>

   

    <div class="conteiner" style="padding-top:60px;padding-bottom: 60px ;" *ngIf="More">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;">
            <button class="btn " id="Moreclick" style="text-align: center;background-color: white;border: 1px solid rgba(111,111,111,0.5);font-size: 18px;" (click)="LoadDetail()">Load More</button>
        </div>
    </div>
    <div *ngIf="!More">
       
        <div  *ngFor="let item of RemainAllArray">
            <div  class="container-fluid carousel-wrap" *ngIf="item.viewAll=='All'? true :false"  style="width:96%;border:1px solid rgba(111,111,111,0.5);margin-top:50px"  data-aos="fade-up-left"> 
                <h1 style="float:left;position:absolute" id={{item.subCategoryName}}>{{item.subCategoryName}}</h1>
                <h5 style="text-align:right; padding-top:20px;cursor: pointer; color: #b62601; " (click)="Allcilick(item)" class="Lu">View All</h5>
                <div style="padding-top:40px;padding-bottom:50px;    padding-left: 20px;margin: 21px;">
                    <owl-carousel-o [options]="customOptions" class="">
                        <ng-template carouselSlide *ngFor="let Menuitem of item.menuitem" >
                            <div class="" style="border:1px solid rgba(111,111,111,0.8);width: 87%; "  >
                                <div  *ngFor="let menu of Menuitem.menuvariance | slice:0:1">
                                                <!-- <div  class=" img__wrap"  >
                                                    <div class="trapezoid" style="position: absolute;" *ngIf='item.discountAmt != 0 ? true :false'><span class="test">{{item.discountAmt}}% OFF</span></div>
                                                    <img src={{menu.imageUrl1}} class="center-block  img__img" style="width:90%;height:198px"  [routerLink]="'/Food/' + item.menuItemId  +'/'+item.quan +'/' + item.adddon" />
                                                    <p class="img__description" *ngIf='menu.description1 != null && menu.description1 !="" ? true :false' >{{menu.description1}}</p>
                                              
                                                </div> -->
                                                <div  class=" img__wrap"  >
                                                    <div class="trapezoid" style="position: absolute;" *ngIf='Menuitem.discountAmt != 0 ? true :false'><span class="test">{{Menuitem.discountAmt}}% OFF</span></div>
                                                    <img src={{menu.imageUrl1}} class="center-block  img__img" style="width:90%;height:198px"  [routerLink]="'/Food/' + Menuitem.menuItemId  +'/'+Menuitem.quan +'/' + Menuitem.adddon" />
                                                    <p class="img__description" *ngIf='menu.description1 != null && menu.description1 !="" ? true :false' >{{menu.description1}}</p>
                                              
                                                </div>
                                                <div class="row" style="padding:10px; " >
                                                    <div class="container" style="width:100%;height: 32px; ">
                                                        
                                                        <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? false : true">
                                                            <h5>{{Menuitem.itemName}} </h5>
                                                        </div>
                                                        <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                                                            <h5>{{Menuitem.itemName}} - {{menu.menuvarianceName1}}</h5>
                                                        </div>
                                                        <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4"  style="padding:unset; z-index: -13  ">
                                                           
                                                            <div class=" rating1" style="text-align: center;background-color:#48C479; padding-top: 4px; padding-right: unset;border-left:1px">
                                                                <p style="color:white"  >
                                                                  $ {{(menu.price1)  | number:'.2'}}
                                                                </p>
                                                            </div>
                                                           
                                                        </div>
                                                       
                                                    </div>
                            
                                                </div>
                                                <div class="row" *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                                                    <div class="container"  style="    height: 56px;">
                                                        <div class="col-md-4" style="padding: 5px 2px 7px 2px !important; cursor: pointer;" *ngFor="let menu1 of Menuitem.menuvariance | slice:0:3;">
                                                            <h5 style="font-size: 11px !important;text-align:center" class="menuvariance" (click)="Addvariance(Menuitem,menu1.menuVariance)" >{{menu1.menuvarianceName}}</h5>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                               
                                                <div style="border-top: 1px solid rgba(111,111,111,0.8) ;">
                                                    <div class="col-md-3"></div>
                                                      <div class="col-lg-6 col-xs-12 col-md-6 col-sm-12"  style="padding-top:10px ;padding-right:unset;padding-left:unset;" >
                                                        <div class="input-group">
                                                            <div class="input-group-btn">
                                                                <button id="down" class="btn" style="border:unset;z-index: 0;background: none;padding-right:unset;    font-size: 16px;" (click)="Adddown('1',Menuitem)" ><span style="font-size: 25px;" class="glyphicon glyphicon-minus" ></span></button>
                                                            </div>
                                                            <input type="number" id="AddNumber" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" style=" border:unset;z-index: 0;text-align: center;    font-size: 25px; font-family: Ubuntu-BOLD !important;box-shadow:unset;padding-left: 14px;background-color: snow;" class="form-control input-number "  [(ngModel)]="Menuitem.quan" (change)="AddcartQuant(Menuitem)"  />
                                                            <div class="input-group-btn">
                                                                <button id="up" class="btn" style="border:unset;z-index: 0;background: none;    font-size: 16px;padding-left:unset" (click)="Addup('100',Menuitem)"><span  style="font-size: 25px;" class="glyphicon glyphicon-plus" ></span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3"></div>
                                                </div>
                                                <div class="">
                                                    <input type="text" style="height: 48px;width: 100%;" placeholder="ADD SPECIAL COMMENTS"  [(ngModel)]="Menuitem.adddon">
                                                </div>
                            
                                                <div class="row" style="padding:10px">
                                                    <div class="col-lg-1"></div>
                                                    
                                                    <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== 0 ? true : false'>
                                                        <div class="Wish" style="top:0px; " (click)="Fav(Menuitem)">
                                                            <img src="../../assets/Images/icon/wishlist.png" id="st" style="width:unset" class="center-block" />
                            
                                                        </div>
                                                    </div>
                                                   <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== 0 ? false : true'>
                                                        <div class="Wish" style="top:0px; " (click)="Fav(Menuitem)">
                                                            <img src="../../assets/Images/wishlist.png" id="st" style="width:unset" class="center-block" />
                            
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" (click)="AddonCartitems(Menuitem)">
                                                        <img src="../../assets/Images/icon/add-tocart.png" style="width:unset" />
                            
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </div>
                        </ng-template>  
                        
                        </owl-carousel-o>
                    </div>
            </div>
            <div class="container-fluid "*ngIf="item.viewAll=='less'? true :false"  >
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-top:30px" data-aos="flip-left">
                    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12" style="padding-bottom:30px">
                        <h2 style="float:left;position:absolute">{{item.subCategoryName}} </h2>
                        <p style="float:right;padding-top:20px;cursor: pointer;" (click)="Allcilick(item)" class="Lu">Show less</p>
                    </div>
                    <div class="col-lg-3 col-xs-12 col-sm-6 col-md-4"  *ngFor="let Menuitem of item.menuitem" style="padding-top:30px">
                        <div class="col-lg-10 col-md-11 col-xs-12 col-sm-12 " style="padding:unset;border:1px solid rgba(111,111,111,0.8)">
                            <div  *ngFor="let menu of Menuitem.menuvariance | slice:0:1" class="imagehover">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="padding:unset">
                                
                                <div  class=" img__wrap"  >
                                    <div class="trapezoid" style="position: absolute;" *ngIf='Menuitem.discountAmt != 0 ? true :false'><span class="test">{{Menuitem.discountAmt}}% OFF</span></div>
                                    <img src={{menu.imageUrl1}} class="center-block  img__img" style="width:90%;height:198px"  [routerLink]="'/Food/' + Menuitem.menuItemId  +'/'+Menuitem.quan +'/' + Menuitem.adddon" />
                                    <p class="img__description" *ngIf='menu.description1 != null && menu.description1 !="" ? true :false' >{{menu.description1}}</p>
                              
                                </div>
                            </div>
          
                            <div class="row pricerange" style="padding:10px; ">
                                <div class="container" style="width:100%; height: 32px;">
                                   
                                    <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? false : true">
                                        <h5>{{Menuitem.itemName}} </h5>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-xs-7 col-sm-7" style="    z-index: -13;"  *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                                        <h5>{{Menuitem.itemName}} - {{menu.menuvarianceName1}}</h5>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4" style="padding:unset;    z-index: -13;">
                                       
                                        <div class=" rating1"  style="text-align: center;background-color:#48C479; padding-top: 4px; padding-right: unset;border-left:1px">
                                            <p style="color:white"  >
                                              $ {{(menu.price1)  | number:'.2'}}
                                            </p>
                                        </div>
                                       
                                    </div>
                                   
                                </div>
        
                            </div>
                            <div class="row" *ngIf="menu.menuvarianceName1 != null &&  menu.menuvarianceName1 != '' ? true : false">
                                <div class="container"  style="    height: 56px;">
                                    <div class="col-md-4" style="padding: 5px 2px 7px 2px !important; cursor: pointer;" *ngFor="let menu1 of Menuitem.menuvariance | slice:0:3;">
                                        <h5 style="font-size: 11px !important;text-align:center" class="menuvariance" (click)="Addvariance(Menuitem,menu1.menuVariance)" >{{menu1.menuvarianceName}}</h5>
                                    </div>
                                </div>
                               
                            </div>
                            
                            </div>
                            <div style="border-top: 1px solid rgba(111,111,111,0.8) ;">
                                <div class="col-md-3"></div>
                                  <div class="col-lg-6 col-xs-12 col-md-6 col-sm-12"  style="padding-top:10px ;padding-right:unset;padding-left:unset;" >
                                    <div class="input-group">
                                        <div class="input-group-btn">
                                            <button id="down" class="btn" style="border:unset;z-index: 0;background: none;padding-right:unset;    font-size: 16px;" (click)="Adddown('1',Menuitem)" ><span style="font-size: 25px;" class="glyphicon glyphicon-minus" ></span></button>
                                        </div>
                                        <input type="number" id="AddNumber"  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"  style=" border:unset;z-index: 0;text-align: center;    font-size: 25px; font-family: Ubuntu-BOLD !important;box-shadow:unset;padding-left: 14px;background-color: snow;" class="form-control input-number "  [(ngModel)]="Menuitem.quan" (change)="AddcartQuant(Menuitem)"  />
                                        <div class="input-group-btn">
                                            <button id="up" class="btn" style="border:unset;z-index: 0;background: none;    font-size: 16px;padding-left:unset" (click)="Addup('100',Menuitem)"><span  style="font-size: 25px;" class="glyphicon glyphicon-plus" ></span></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3"></div>
                            </div>
                            <div class="">
                                <input type="text" style="height: 48px;width: 100%;text-align: center;" placeholder="ADD SPECIAL COMMENTS"  [(ngModel)]="Menuitem.adddon">
                            </div>
        
                            <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding:10px">
                                <div class="col-lg-1"></div>
                                
                                <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== "" ? true : false'>
                                    <div class="Wish" style="top:0px; " (click)="Fav(item)">
                                        <img src="../../assets/Images/icon/wishlist.png" id="st" style="width:unset" class="center-block" />
        
                                    </div>
                                </div>
                                <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='Menuitem.favId== "" ? false : true'>
                                    <div class="Wish" style="top:0px; " (click)="Fav(Menuitem)">
                                        <img src="../../assets/Images/wishlist.png" id="st" style="width:unset" class="center-block" />
        
                                    </div>
                                </div>
                                <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" (click)="AddonCartitems(Menuitem)">
                                    <img src="../../assets/Images/icon/add-tocart.png" style="width:unset" />
        
                                </div>
                                <div class="col-lg-1"></div>
                            </div>
                        </div>
                    </div>
          
                </div>
            </div>
           </div>
        </div>
        <div class="container-fluid" style="padding-top:30px" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div class="col-lg-4 col-md-2 col-xs-1 col-sm-1">
      
                </div>
                <div class="col-lg-5 col-md-8 col-xs-12 col-sm-12">
                    <div class="col-lg-5 col-md-6 col-xs-12 col-sm-5 " style="cursor: pointer;" (click)="Dish()">
                        <button class="btn " *ngIf="DISHColor" style="text-align:center;width:100%;font-size:20px;background:#EE562F;color:white;border-radius:32px" id="normalDish">
                            <img src="../../assets/Images/icon/dishes.png" class="" />Entrées
                        </button>
                        <button *ngIf="!DISHColor" style="text-align:center;width:100%;font-size:20px;background:white;border:1px solid #EE562F; border-radius:32px;color:#EE562F;" id="colordish" class="btn" >
                            <img src="../../assets/Images/icon/dishes-1.png" />Entrées
                        </button>
      
                    </div>
                    <div class="col-lg-5 col-md-5 col-xs-12 col-sm-5 Drin " style="cursor: pointer;font-size:20px" (click)="Drink()">
                        <button class="btn " *ngIf="DrinkColor" style="text-align:center;width:100%;border: 1px solid #EE562F;background-color:white;font-size:20px;  border-radius:32px;color:#EE562F" id="normal">
                            Drinks
                            <img src="../../assets/Images/icon/drinks-1.png" />
                        </button>
                        <button *ngIf="!DrinkColor"  style="text-align:center;border:1px solid #EE562F;border-radius:32px ;width:100%;background-color:#EE562F;font-size:20px;color:white;" id="Colour" class="btn">
                            Drinks
                            <img src="../../assets/Images/icon/drinks.png" />
                        </button>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
      
                </div>
      
            </div>
        </div>
    
        <div style="padding-top: 80px;padding-bottom: 50px;">
            <div  class="container-fluid " *ngIf="DISHColor" style="width: 80%;"   data-aos="fade-up-left"> 
              
                <owl-carousel-o [options]="DirinkAndDish" class="">
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/Spicy.jpg" class="center-block" style="width:140px;height:140px;    border-radius: 31px;" />
                        <h5 style="text-align:center;font-size:13px;" id="Lun">APPETIZERS</h5>
                    </ng-template>  
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/Special-4-new.jpg" class="center-block" style="width:140px;height:140px;    border-radius: 31px;" />
                        <h5 style="text-align:center;font-size:13px; " id="Lun" >HOUSE SPL DISH</h5>
                    </ng-template>   
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/Gonsale.jpeg" class="center-block" style="width:140px;height:140px;  border-radius: 31px;" />
                    <h5 style="text-align:center;font-size:13px;" id="Lun">POULTRY</h5>
                    </ng-template>  
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/Fajita.jpg" class="center-block" style="width:140px;height:140px;   border-radius: 31px;" />
                        <h5 style="text-align:center;font-size:13px;" id="Lun">FROM THE SEA</h5>
                    </ng-template>  
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/ten mix.jpg" class="center-block" style="width:140px;height:140px;  border-radius: 31px;" />
                    <h5 style="text-align:center;font-size:13px;" id="Lun">CARNES</h5>
                    </ng-template>  
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/Special-4-new.jpg" class="center-block" style="width:140px;height:140px; border-radius: 31px;" />
                    <h5 style="text-align:center;font-size:13px;" id="Lun">LUNCH ONLY</h5>
                </ng-template>  
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/The-Best-Guacamole-Recipe.jpg" class="center-block" style="width:140px;height:140px; border-radius: 31px;" />
                    <h5 style="text-align:center;font-size:13px;" id="Lun">Especialidades</h5>
                    </ng-template> 
                    <ng-template carouselSlide>
                        <img src="../../assets/Images/Torilla.jpg" class="center-block" style="width:140px;height:140px; border-radius: 31px;" />
                    <h5 style="text-align:center;font-size:13px;" id="Lun">Lunch Only</h5>
                    </ng-template>  
                    </owl-carousel-o>
                </div>
          
                <div  class="container-fluid " *ngIf="!DISHColor"  style="width: 80%;"  data-aos="fade-up-left"> 
                  
                    <owl-carousel-o [options]="DirinkAndDish" class="">
                       
                        <ng-template carouselSlide>
                            <img src="../../assets/Images/Drinks/PEACH.jpg" class="center-block" style=" width:140px;height:140px;  border-radius: 31px;" />
                            <h5 style="text-align:center;font-size: 13px !important;" id="Lun">PEACH</h5>
                        </ng-template>  
                        <ng-template carouselSlide>
                            <img src="../../assets/Images/Drinks/DIET PEPSI.jpeg" class="center-block" style="width:140px;height:140px;    border-radius: 31px;" />
                    <h5 style="text-align:center;font-size: 13px !important;" id="Lun">DIET PEPSI </h5>
                        </ng-template>  
                        <ng-template carouselSlide>
                            <img src="../../assets/Images/Drinks/JARRITOS.jpg" class="center-block" style="width:140px;height:140px;    border-radius: 31px;" />
                            <h5 style="text-align:center;font-size: 13px !important;" id="Lun">JARRITOS  </h5>
                        </ng-template>  
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
<div *ngIf="!Filter">
    <div style="padding-top: 80px;padding-bottom: 50px;">
        <div class="col-lg-3 col-xs-12 col-sm-6 col-md-4" *ngFor="let item of FilterData  " style="padding-top:10px;padding-bottom:40px ;">
            <div class="col-lg-10 col-md-11 col-xs-12 col-sm-12 " style="padding:unset;border:1px solid rgba(111,111,111,0.8)">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="padding:unset">
                    
                    <div class="tooltip1">
                        <div class="trapezoid" style="position: absolute;" *ngIf='item.couponAmt != 0 ? true :false'><span class="test">{{item.couponAmt}}% OFF</span></div>
                        <img src={{item.imageUrl}} class="center-block " style="width:90%;height:198px"   [routerLink]="'/Food/' + item.menuItemId +'/'+item.quan +'/' + item.adddon"/>
                        <span class="tooltiptext">{{item.description}}</span>
                    </div>
                </div>

                <!-- <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding-bottom:10px ;border-bottom:1px solid rgba(111,111,111,0.8)">
                    <div>
                        <h5 style="text-align:center">{{item.itemName}}</h5>
                        <div style="padding-top:10px">
                            <div>
                                <div class="col-lg-1  col-md-1"></div>
                                <div class="col-lg-3 col-sm-3 col-md-3 col-xs-3 rating1" style="text-align: center ;margin:unset; background-color:#48C479;padding-top: 4px; padding-right: unset;border-left:1px">

                                    <p style="color:white;margin:unset">
                                        <i class="glyphicon glyphicon-star"></i>  <a style="color:white;"> 4.0</a>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-sm-3 col-md-3 col-xs-3 dollar" style="padding-right: unset;padding-top:5px;text-align:center">
                                <p style="color:black;" class="oldprice"  *ngIf='item.couponAmt != 0 ? true :false' >
                                    $ {{item.price}}
                                </p>

                            </div>
                            <div class="col-lg-3 col-xs-3 col-md-3 col-sm-3 dollar" style="padding-right: unset;padding-top:5px;">
                                <p style="font-size:15px">  $ {{item.couponType=="P" ? (item.price -(item.couponAmt/100)) : (item.price -(item.couponAmt)) }}</p>
                            </div>

                        </div>
                    </div>
                </div> -->
                <div class="row" style="padding:10px; ">
                    <div class="container" style="width:100%; ">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-7 col-md-7 col-xs-7 col-sm-7">
                            <h5 style="  height: 32px;">{{item.itemName}}</h5>
                        </div>
                        <div class="col-lg-3 col-md-4 col-xs-4 col-sm-4" style="padding:unset;    margin-top: 7px;">
                           
                            <div class=" rating1" style="text-align: center;background-color:#48C479; padding-top: 4px; padding-right: unset;border-left:1px">
                                <p style="color:white">
                                  $ {{(item.quan)*(item.price) | number:'.2'}}
                                </p>
                            </div>
                           
                        </div>
                        <div class="col-lg-1"></div>
                    </div>

                </div>
                <div style="border-top: 1px solid rgba(111,111,111,0.8) ;">
                    <div class="col-md-3"></div>
                      <div class="col-lg-6 col-xs-12 col-md-6 col-sm-12"  style="padding-top:10px ;padding-right:unset;padding-left:unset;" >
                        <div class="input-group">
                            <div class="input-group-btn">
                                <button id="down" class="btn" style="border:unset;z-index: 0;background: none;padding-right:unset;    font-size: 16px;" (click)="Adddown('1',item)" ><span style="font-size: 25px;" class="glyphicon glyphicon-minus" ></span></button>
                            </div>
                            <input type="number" id="AddNumber"  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"  style=" border:unset;z-index: 0;text-align: center;    font-size: 25px; font-family: Ubuntu-BOLD !important;box-shadow:unset;padding-left: 14px;background-color: snow;" class="form-control input-number "  [(ngModel)]="item.quan" (change)="AddcartQuant(item)"  />
                            <div class="input-group-btn">
                                <button id="up" class="btn" style="border:unset;z-index: 0;background: none;    font-size: 16px;padding-left:unset" (click)="Addup('100',item)"><span  style="font-size: 25px;" class="glyphicon glyphicon-plus" ></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <div class="">
                    <input type="text" style="height: 48px;width: 100%;text-align: center;" placeholder="ADD SPECIAL COMMENTS"  [(ngModel)]="item.adddon">
                </div>

                <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="padding:10px">
                    <div class="col-lg-1"></div>
                   
                    <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='item.favId== "" ? true : false'>
                        <div class="Wish" style="top:0px; " (click)="Fav(item)">
                            <img src="../../assets/Images/icon/wishlist.png" id="st" style="width:unset" class="center-block" />

                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-5 col-xs-5 col-md-5" *ngIf='item.favId== "" ? false : true'>
                        <div class="Wish" style="top:0px; " (click)="Fav(item)">
                            <img src="../../assets/Images/wishlist.png" id="st" style="width:unset" class="center-block" />

                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-4 col-xs-4 col-md-4" (click)="AddonCartitemsFilter(item)">
                        <img src="../../assets/Images/icon/add-tocart.png" style="width:unset" />

                    </div>
                    <div class="col-lg-1"></div>
                </div>
            </div>
        </div>
    </div>
</div>
              <ng-template #Addon let-modal id="" style="width:90%">

                <div class="modal-header" style="border: none;padding: 35px;padding-bottom: unset;">
              
                  <h3 class="modal-title" id="modal-basic-title" style="font-family: Ubuntu-Bold;">ADD ON</h3>
              
                  <button type="button" class="close" aria-label="Close" style="outline: none;opacity: 1;font-size: 34px;padding-top: unset;" (click)="modal.dismiss('Cross click')">
              
                    <span aria-hidden="true">×</span>
              
                  </button>
              
                </div>
              
                <div class="modal-body" style="padding:35px;">
              <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12" style="text-align: center;"  style="padding-bottom: 10px;" >
                <fieldset>
                    <!-- Sign In Form -->
                    <!-- Text input-->
                    <div class="control-group">

                        <div class="controls">
                            <textarea required="" id="userid" name="userid"  [(ngModel)]="AddonText" width="200" style="height: 200px;" class="form-control " placeholder=""></textarea>
                         
                        </div>
                    </div>


                    <!-- Button -->
                    <div class="control-group">
                        <label class="control-label" for="signin"></label>
                        <div class="controls" style="    text-align: center;">
                            <button id="signin" class="btn " style="background-color:#EE562F;color:white" (click)="AddonCartitems('Addon')" >Submit</button>
                        </div>
                    </div>

                </fieldset>
              
                </div>
                </div>

              
               
              
              </ng-template>
            
            
    <style>
        @media(min-width:768px) {
            .dollar {
                padding-right: unset !important;
                padding-left: unset !important;
            }
        }
 .owl-next{
    position: absolute;
    top: 41%;
    right:  -30px;
    float:right;
}
 .owl-prev{
    position: absolute;
    top: 41%;
left: -30px;
}
    </style>
  
  </div>
  
  