
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<div class="ms-aside-overlay ms-overlay-left ms-toggler" data-target="#ms-side-nav" data-toggle="slideLeft"></div>
  <div class="ms-aside-overlay ms-overlay-right ms-toggler" data-target="#ms-recent-activity" data-toggle="slideRight"></div>
  <div class="ms-aside-overlay ms-overlay-left ms-toggler" data-target="#ms-side-nav" data-toggle="slideLeft"></div>
  <aside id="ms-side-nav" class="side-nav fixed ms-aside-scrollable ms-aside-left ps ps--active-y">

    <!-- Logo -->
    <div class="logo-sn ms-d-block-lg">
      <a class="pl-0 ml-0 text-center" > <img src="../assets/Images/Zapata_Logo.png" alt="logo"> </a>
    </div>
  
     <!-- Navigation -->
    <ul class="accordion ms-main-aside fs-14" id="side-nav-accordion" style="padding-top: 15px;">
      <!-- Dashboard -->
      <li class="menu-item">
          <a href="#"  [routerLink]="'/AdminDashboard'" >
             <span><img src="../../assets/Images/icon/Home.png" style="    padding-right: 11px;"> Home </span>
           </a>
         
      </li>
      <!-- /Dashboard -->
      <!-- product -->
  
      <li class="menu-item">
          <a href="#" [routerLink]="'/AdminHome'">
               <span> <img src="../../assets/Images/icon/Menu.png" style="    padding-right: 11px;"> Menu </span>
           </a>
        
      </li>
      <li class="menu-item">
        <a href="#"  [routerLink]="'/AdminViewOrder'">
             <span> <img src="../../assets/Images/icon/Orders.png" style="    padding-right: 11px;">View Order </span>
         </a>
      
    </li>
      <li class="menu-item">
                <a href="" [routerLink]="'/AdminWaiter'">
                 <span> <img src="../../assets/Images/icon/Waiter.png" style="    padding-right: 11px;">Waiter</span>
                </a>
              </li>
  <li class="menu-item">
                      <a  [routerLink]="'/AdminTable'">
                        <span> <img src="../../assets/Images/icon/Table.png" style="    padding-right: 11px;">Table</span>
                      </a>
                    </li>
                    <li class="menu-item menu-item1">
                        <a  class="has-chevron" href="javascript:void(0);" data-toggle="collapse" data-target="#product" >
                             <span>  <img src="../../assets/Images/icon/Reports.png" style="   padding-right: 11px;">Master </span>
                        </a>
                        <ul id="product" class="collapse">
                            <li> <a [routerLink]="'/AdminCategory'">Add Category</a>
                            </li>
                            <li> <a [routerLink]="'/AdminSubCategory'">Add Subcategory</a>
                            </li>
                            <li> <a [routerLink]="'/ItemSettings'">Menu Settings</a>
                            </li>
                            <li> <a [routerLink]="'/AdminOffer'"> Discounts / Offers</a>
                            </li>
                            <li> <a [routerLink]="'/AdminCoupon'"> Coupons</a>
                            </li>
                            <li> <a [routerLink]="'/SuperAdminRestarunt'">Restaurant Masters</a>
                            </li>
                            <li> <a [routerLink]="'/Adminworking'">Restaurant Working Hours</a>
                            </li>
                            <li> <a [routerLink]="'/AdminHoliday'"> Restaurant Holiday</a>
                            </li>
                            <li> <a [routerLink]="'/AdminPrinter'">  Printer Config</a>
                            </li>
                        </ul>
                      </li>
                    <li class="menu-item">
                        <a  [routerLink]="'/AdminTips'">
                          <span> <img src="../../assets/Images/icon/Tips.png" style="    padding-right: 11px;">Tips</span>
                        </a>
                      </li>
                      <li class="menu-item">
                        <a href="" [routerLink]="'/AdminTax'">
                          <span> <img src="../../assets/Images/icon/Tax.png" style="    padding-right: 11px;">Tax</span>
                        </a>
                      </li>
                    <li class="menu-item">
                        <a href="#"  [routerLink]="'/AdminRating'" >
                             <span> <img src="../../assets/Images/icon/Rating.png" style="    padding-right: 11px;">Rating </span>
                         </a>
                   
                    </li>
           
            <li class="menu-item">
                <a href="#"  [routerLink]="'/AdminReports'"  >
                     <span>  <img src="../../assets/Images/icon/Reports.png" style="    padding-right: 11px;">Reports </span>
                 </a>
              
            </li>
  
            
            <li class="menu-item" >
              <a href="" [routerLink]="'/Adminprofile'" >
                    <span> <img src="../../assets/Images/icon/Profileupdate.png" style="    padding-right: 11px;">Profile Update</span>
              </a>
            </li>
            <li class="menu-item">
              <a href="" [routerLink]="'/UserRights'">
                <span> <img src="../../assets/Images/icon/Profileupdate.png" style="    padding-right: 11px;">User Rights</span>
              </a>
            </li>
        <!-- /Advanced UI Elements -->
        <li class="menu-item">
            <a  [routerLink]="'/Admin'">
              <span><img src="../../assets/Images/icon/Logout.png" style="    padding-right: 11px;">Logout</span>
            </a>
        </li>
        
    </ul>
    <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; height: 390px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 131px;"></div></div>
    </aside>

   