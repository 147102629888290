<div style="padding-top: 0px;">
    <img src="../../assets/Images/ContavtusBanner.jpeg" class="center-block" style="width:100% !important;">
</div>
<div style="padding: 10px 20px 0px 60px ;"><a href="/"><img src="../../assets/Images/Homeicon.png">Home </a> &nbsp;/&nbsp;<span
    style="font-family: Ubuntu-Regular !important;">Contact Us</span></div>
<div class="container" style="padding-top: 50px;">

    <div class="row">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;">Contact with us</h4><br>           
        </div>
    </div>
    <div class="row">
    <!-- <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
        <span [hidden]="isShownError" style="color: red;" >{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;" >{{SuccessMsg}}</span>
     </div> -->
    </div>
    <div class="row" style="padding-bottom: 15px;">
        <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12">
            <input type="text" placeholder="Your Name" class="form-control" [(ngModel)]="Name" style="padding: 30px 10px;" />
        </div>
        <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12">
            <input type="text" placeholder="Mobile Number" class="form-control" [(ngModel)]="MobileNo" maxlength="13"  style="padding: 30px 10px;" (ngModelChange)="onChange($event)"   />
        </div>
    </div>
    <div class="row" style="padding-bottom: 15px;">
        <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12">
            <input type="text" placeholder="Email ID" class="form-control" [(ngModel)]="EmailId"  style="padding: 30px 10px;" />
        </div>
        <div class="col-lg-6 col-xs-12 col-sm-12 col-xs-12">
            <input type="text" placeholder="Your Location" class="form-control" [(ngModel)]="Location" style="padding: 30px 10px;" />
        </div>
    </div>
    <div class="row" style="padding-bottom: 15px;">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <textarea id="w3review" name="w3review" rows="8" cols="50" placeholder="Enter Your Message" class="form-control" [(ngModel)]="Message">
</textarea>
            <!-- <input type="text" placeholder="Enter Your Message" class="form-control" [(ngModel)]="Message" style="padding: 30px 10px; height: 250px;" /> -->
        </div>
    </div>
    <div class="row" style="text-align: center; ">
        <div class="col-lg-10 col-xs-10 col-sm-12 col-xs-12"  style="text-align: center;">
            <span [hidden]="isShownError" style="color: red;" >{{ErrorMsg}}</span>
            <span [hidden]="isShownSuccess" style="color: green;" >{{SuccessMsg}}</span>
        </div>
        <div class="col-lg-2 col-xs-2 col-sm-12 col-xs-12 form-group " style="margin-top: 10px; border-radius: 10px;border: 1px solid;background-color: #EE562F;text-align: center; float:right;">
               
            <h3 class="" (click)="SendMail()" style="text-align: center;margin-top: 10px;color: white;font-family: Ubuntu-Bold !important;padding: 0 10px;" >SUBMIT</h3>
        </div>
    </div>
</div>

<div class="container" style="padding-top: 50px;">

    <div class="row">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-xs-12">
            <h4 style="font-size: 30px;font-family:Ubuntu-Medium !important ;">Get in Touch</h4><br>           
        </div>
    </div>
    <div class="row" style="padding-bottom: 25px;">
        <div class="col-lg-4 col-xs-12 col-sm-12 col-xs-12">
            <img src="../../assets/Images/About-usImage.jpeg" class="center-block" style="width: 100%; max-height: 230px;">
        </div>
        <div class="col-lg-4 col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">ZAPATA HARTSVILLE</h5>
            <p style="font-size: 16px; text-align:justify ;">1217, Retail Row            
            </p>
            <p style="font-size: 16px; text-align:justify ;">Hartsville, SC-29550           
            </p>
            <p style="font-size: 16px; text-align:justify ;">(843) 332-2937
            </p>
        </div>
         <div class="col-lg-4 col-xs-12 col-sm-12 col-xs-12">
            <h5 style="font-size: 20px !important;font-family:Ubuntu-Medium !important ;">El San Jose</h5>
            <p style="font-size: 16px; text-align:justify ;">275, W. Main Street            
            </p>
            <p style="font-size: 16px; text-align:justify ;">Lake City, SC-29560
            </p>
            <p style="font-size: 16px; text-align:justify ;">(843) 394-5522
            </p>            
         </div>
    </div>
    </div>