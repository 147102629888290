
<main class="body-content">

  <!-- Navigation Bar -->
  <nav class="navbar ms-navbar" style=" margin: unset;">
    <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span
        class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
      <span class="ms-toggler-bar bg-primary"></span>
    </div>

  </nav>

  <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">

    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">Menu</h4>
    </div>
    <div class="col-md-7 align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center" *ngIf="Drop"  [hidden]="isShownWrite">
        <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;"
          (click)="Show()"><i class="fa fa-plus-circle"></i> Add Menu</button>
      </div>
    </div>
    <div *ngIf="!Drop " style="    margin-top: 20px;">
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Menu Details</button>
        </div>


      </div>
      <!-- <div>
        <form name="order" method="POST">
          <iframe id="tokenframe" name="tokenframe" src="https://boltgw-uat.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true" frameborder="0" scrolling="no" width="200" height="200" useexpiry="true"></iframe>
          <input type="hidden" name="mytoken" id="mytoken"/>
          </form>
          <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;"
          (click)="payment()">Pay</button>
      </div> -->
      
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Restaurant</label>
            <select class="form-control" [(ngModel)]="restaurant_ID" (ngModelChange)="onResturantChange($event)">
              <option value="0">Select</option>
              <option *ngFor="let rst of Resturants" value={{rst.restaurant_ID}}>{{rst.name}}</option>
            </select>
          </div>
        </div>

        <!--/span-->
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Category</label>
            <select class="form-control" [(ngModel)]="category_Id" (ngModelChange)="onCatChange($event)">
              <option value="0">Select</option>
              <option *ngFor="let cat of Category" value={{cat.category_Id}}>{{cat.categoryName}}</option>
            </select> </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 30px;">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Sub Category</label>
            <select class="form-control" [(ngModel)]="subCategory_ID">
              <option value="0">Select</option>
              <option *ngFor="let scat of SubCategory" value={{scat.subCategory_ID}}>{{scat.subCategoryName}}</option>
            </select>
          </div>
        </div>

        <!--/span-->
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Item Name</label>
            <input type="text" class="form-control" [(ngModel)]="ItemName"> </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Coupon Code</label>
            <select class="form-control" [(ngModel)]="CopounDetailsId">
              <option value="0">Select</option>
              <option *ngFor="let cpn of Coupons" value={{cpn.copounDetailsId}}>{{cpn.copounCode}}</option>
            </select>
          </div>
        </div>

        <!--/span-->
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Discount</label>
            <select class="form-control" [(ngModel)]="DiscountId">
              <option value="0">Select</option>
              <option *ngFor="let dis of Discounts" value={{dis.discountId}}>{{dis.description}}</option>
            </select> </div>
        </div>
      </div>
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Variance</button>
        </div>


      </div>
      <div class="col-md-12" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="col-md-9" style="border: 1px solid #d8d5d5;padding-top:15px ;padding-bottom: 15px;"
          *ngFor="let mvr of MenuVariance">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Variance</label>
              <input type="text" class="form-control" style="min-height: 27px;" id={{mvr.vName}}>

            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Price</label>
              <input type="number" class="form-control" style="min-height: 27px;" id={{mvr.vPrice}}>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Item Image</label>
              <div class="">
                <!-- <span><i class="ion-upload m-r-5"></i>Item Image</span> -->
                <!-- <input type="file" class=" form-control" id={{mvr.vImage}}> </div> -->
                <input type="file" #file (change)="uploadFile(file.files,mvr)" id={{mvr.vImage}}
                  class="form-control line" style="font-family: Ubuntu-Medium !important;" />
              </div>
            </div>

          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label class="control-label">Description</label>
              <input type="text" class="form-control" style="height: 50px;" id={{mvr.vDescription}}>
              <span id={{mvr.MenuVar}} style="visibility: hidden;">{{mvr.MenuVarianceID}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3" style="    padding-top: 21px; padding-bottom: 15px;">
          <button class="btn " style="background-color:brown ;color: white;" (click)="AddMenuVariance()">Add
            New</button>
        </div>

      </div>
      <div class="col-md-12" id="Menus">
        <div class="">
          <button id="Menu" tabindex="92" class="accordion" style="display: block;">Others</button>
        </div>
      </div>
      <!-- <div class="col-md-4" style="padding-top: 30px;padding-bottom: 30px;">
        <div class="input-group mb-3">
          <label style="padding-right: 20px;padding-top: 5px;">Customize</label>
          <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
            [(ngModel)]="txtCustomize">
          <div class="input-group-append" (click)="AddCustomize()">
            <span class="input-group-text" id="basic-addon2" style="background: blue;"><i class="fa fa-plus fa-2x"
                style="color: white;"></i></span>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <span>{{Customize}}</span>
      </div> -->
      <div class="col-md-12" style="padding-bottom: 25px;">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Description</label>
            <!-- <input type="text" class="form-control" style="height: 50px;" [(ngModel)]="Description"> -->
            <textarea rows="7" cols="10" placeholder="Description" [(ngModel)]="Description" class="form-control" ></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <label style="padding-right: 20px;padding-top: 5px;">Customize</label>
          <div style="border: 1px solid #d8d5d5; padding: 5px;">
          <div class="row" style="padding-bottom: 15px;">
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize1">
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize2">
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize3">
            </div>
          </div>
          <div class="row" style="padding-bottom: 15px;">
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize4">
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize5">
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize6">
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize7">
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize8">
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" [(ngModel)]="Customize9">
            </div>
          </div>
        </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-6" style="padding-top: 39px;" [hidden]="isShownActive">
            <input type="checkbox"> Set As InActive
          </div>


          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: #ED562E; color: white;border-radius: 4px;"
              (click)="SaveMenuItem()">SAVE</button>
          </div>
          <div class="col-md-3" style="padding-top: 22px;">
            <button class="btn " style="background: white; color: black;border-radius: 4px;border-color: #ED562E;">CLEAR</button>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="padding-bottom: 25px; text-align: center;">
        <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
        <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
      </div>
      <!-- </div> -->
      <!--/span-->
    </div>

    <div class="ms-content-wrapper" style="width: 100%;">
      <div class="row">

        <div class="col-md-12">

          <div class="ms-panel">
            <div class="ms-panel-body">
              <div class="table-responsive">
                <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="data-table-5_length">
                        <label style="padding-right: 25px;">Search</label>
                        <input type="text" placeholder="Search"
                          style="border: unset;background:  #ececec;height: 37px;width: 84%;" [(ngModel)]="searchText"
                          (keyup)="applyFilter($event.target.value)"> <i class="fa fa-search fa-2x"
                          style="color:red;position: absolute;    left: 88%; top: 3px;"></i>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="data-table-5_filter" class="dataTables_filter"
                        style="text-align: left;padding-top: 5px;">
                        <!-- <select style="text-align: left;height: 26px;width: 27%;    border-radius: 4px;">
                          <option>Search By</option>
                        </select> -->
                        <img src="../../assets/Images/icon/ExportImg.jpeg" alt="logo" (click)="exportexcel()" style="cursor: pointer;">
                        <!-- <button class="btn"
                          style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;"
                          (click)="exportexcel()">Export</button> -->
                        <label style="padding-left: 50px;">Show</label>
                        <label class="switch" style="margin-left: 10px;">
                          <input type="checkbox" checked (change)="ActiveCheckboxChange($event)">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                      <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSortmat-table
                        [dataSource]="dataSource" #table style="width: 100%;" matSort>
                        <ng-container matColumnDef="sno">
                          <th mat-header-cell *matHeaderCellDef
                            style="color: white !important;background-color: #ED562E">
                            S.NO </th>
                          <td mat-cell *matCellDef="let item; let j = index">
                            {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="color: white !important;background-color: #ED562E"> RESTAURANT NAME </th>
                          <td mat-cell *matCellDef="let element" (click)="EditItemDetail(element)"> {{element.name}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="categoryName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="color: white !important;background-color: #ED562E"> CATEGORY </th>
                          <td mat-cell *matCellDef="let element" (click)="EditItemDetail(element)">
                            {{element.categoryName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="itemName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="color: white !important;background-color: #ED562E"> ITEM NAME</th>
                          <td mat-cell *matCellDef="let element" (click)="EditItemDetail(element)"> {{element.itemName}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="price">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="color: white !important;background-color: #ED562E"> PRICE </th>
                          <td mat-cell *matCellDef="let element" (click)="EditItemDetail(element)"> {{element.price}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="color: white !important;background-color: #ED562E"> STATUS </th>
                          <td mat-cell *matCellDef="let element" (click)="EditItemDetail(element)"> {{element.status}}
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                      <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons>
                      </mat-paginator>





                      <!-- <table id="data-table-5" class="table w-100 thead-primary dataTable no-footer" role="grid"
                        aria-describedby="data-table-5_info" style="width: 1099px;">
                        <thead>
                          <tr role="row">
                            <th class="sorting_asc" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                              aria-sort="ascending" aria-label="Product ID: activate to sort column descending"
                              style="width: 165px;">RESTAURANT NAME</th>
                            <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                              aria-label="Product Name: activate to sort column ascending" style="width: 325px;">
                              CATEGORY
                            </th>
                            <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                              aria-label="Quantity: activate to sort column ascending" style="width: 141px;">ITEM NAME
                            </th>
                            <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                              aria-label="Status: activate to sort column ascending" style="width: 156px;">IMAGE </th>
                            <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                              aria-label="Price: activate to sort column ascending" style="width: 102px;">PRICE</th>
                            <th class="sorting" tabindex="0" aria-controls="data-table-5" rowspan="1" colspan="1"
                              aria-label="Price: activate to sort column ascending" style="width: 102px;">ACTIVE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" *ngFor="let itm of pageOfItems | grdFilter: {name: searchText, categoryName:searchText, itemName: searchText, price: searchText}; let i=index;">
                            <td class="sorting_1" (click)="EditItemDetail(itm)">{{itm.name}}</td>
                            <td (click)="EditItemDetail(itm)">{{itm.categoryName}}</td>
                            <td (click)="EditItemDetail(itm)">{{itm.itemName}}</td>
                            <td (click)="EditItemDetail(itm)">In Stock</td>
                            <td (click)="EditItemDetail(itm)">${{itm.price}}</td>
                            <td><label class="switch">
                                <input type="checkbox" checked>
                                <span class="slider round"></span>
                              </label></td>
                          </tr>
                          
                        </tbody>
                      </table> -->
                    </div>

                  </div>
                  <!-- <div class="row">
                   
                    <div class="col-sm-12 col-md-5">
                      <div class="dataTables_info" id="data-table-5_info" role="status" aria-live="polite">Showing 1 to
                        10
                        of 36 entries</div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                      <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                      
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</main>