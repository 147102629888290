import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { ViewEncapsulation } from '@angular/core';
import AOS from 'aos';
import * as XLSX from 'xlsx';

import{ HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import{Observable,Subject} from "rxjs";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from  '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
@Component({
  selector: 'app-waiter',
  templateUrl: './waiter.component.html',
  styleUrls: ['./waiter.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WaiterComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'name', 'userName', 'mobileNo','description','Status'];
  dataSource: MatTableDataSource<any[]>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
@ViewChild('TABLE') table: ElementRef;
  Drop=true;
  Res_Id:number;
  ResArray=[] as any;
  FName:string;
  LName:string;
  MobileNo:string;
  SSNNo:string;
  Address1:string;
  Address2:string;
  City:string;
  State:string;
  Zip:string;
  Description:string;
  UserName:string;
  Password:string;
  Activebutton=false;
Activebutton1=false;
Image = [] as any;
  Saveshow=true;
  ErrorMsg:string;
  isShownError:boolean=false;
  isShownSuccess:boolean=false;
  SuccessMsg:string;
  StatusActive:string;
   file=[] as any ;
   UserRefId:number;
   @ViewChild('file') document: ElementRef;
  constructor(private http:HttpClient) {
    
   }

  ngOnInit(): void {
    this.Active("Active");
    this.StatusActive="Active";
    this.Res_Id=0;
    this.loadScript('../../assets/js/Alert.js');
    this.loadScript('../../assets/framework.js');
    this.loadScript('../../assets/datatables.min.js');
    var ViewRes = this.http.get(environment.apiUrl +'Holiday/Restaurent?UserId=1').toPromise().then((data:any)=>{
      debugger;
      if(data.length !=0){
     this.ResArray=data;
   
      }
    });
  }
  Show(){
    this.Drop=false;
      }

      public uploadFile = (files) => {
        debugger;
        this.isShownError=true;
        if (files.length === 0) {
          return;
        }
        debugger;
        var cusId= sessionStorage.getItem('CustomerId');
        let fileToUpload = <File>files[0];
        let size =fileToUpload.size / 1024 / 1024;
        if(size > 2){
          this.isShownError=false;
          this.ErrorMsg="Please Choose Below 2 MB";
          return false;
        }
      
        var index = fileToUpload.name.lastIndexOf(".");
        var strsubstring = fileToUpload.name.substring(index, fileToUpload.name.length);

        if (strsubstring == '.pdf' || strsubstring == '.xlsx' || strsubstring == '.xls' || strsubstring == '.doc' ||strsubstring == '.docx' ) {
          // debugger;
      }
      else {
          //  debugger;
          this.isShownError=false;
          this.ErrorMsg="Please Choose valid file Type";
          return false;
      }
       // const formData = new FormData();
      this.file=files;
     
        // this.http.post(environment.apiUrl +'Myorder/UploadContent?Customer_ID='+cusId, formData, {reportProgress: true, observe: 'events'})
        //   .subscribe(event => {
        //  //   if (event.type === HttpEventType.UploadProgress)
        //    //   this.progress = Math.round(100 * event.loaded / event.total);
        //     //else if (event.type === HttpEventType.Response) {
        //       //this.message = 'Upload success.';
        //       //this.onUploadFinished.emit(event.body);
        //    // }
        //   });
      }
    
      clear(){
        this.document.nativeElement.value = null;
        this.Res_Id=0;
        this.FName="";
        this.LName="";
        this.MobileNo=null;
        this.Password="";
        this.UserName="";
        this.SSNNo =null;
        this.Saveshow=true;
        this.Activebutton=false;
        this.file=null;
this.Activebutton1=false;
this.Address1="";
this.Address2="";
this.Description="";
this.Zip=null;
this.State="";
this.City="";

      }
      Save(){
        this.isShownError = true;
        this.isShownSuccess = true;
        if(this.Res_Id == undefined || this.Res_Id==0){
          this.isShownError=false;
          this.ErrorMsg="Please Choose Resturant Name";
          return false;
          }
          if(this.FName == undefined || this.FName==""){
            this.isShownError=false;
            this.ErrorMsg="Please Enter First Name";
            return false;
            }
            if(this.LName == undefined || this.LName==""){
              this.isShownError=false;
              this.ErrorMsg="Please Enter Last Name";
              return false;
              }
              if(this.SSNNo == undefined || this.SSNNo==""){
                this.isShownError=false;
                this.ErrorMsg="Please Enter SSN No";
                return false;
                }
                if(this.MobileNo == undefined || this.MobileNo==""){
                  this.isShownError=false;
                  this.ErrorMsg="Please Enter Mobile No";
                  return false;
                  }
                  var regMobile = /^[\(]\d{3}[\)]\d{3}[\-]\d{4}$/;

                  if (regMobile.test(this.MobileNo) == false) {
                    this.isShownError = false;
                    this.ErrorMsg = "Please Enter The Valid MobileNo. Format Should like (123)456-7890";
                    return false;
                  }
                  if(this.Address1 == undefined || this.Address1==""){
                    this.isShownError=false;
                    this.ErrorMsg="Please Enter Address Line 1";
                    return false;
                    }
                  if(this.City == undefined || this.City==""){
                    this.isShownError=false;
                    this.ErrorMsg="Please Enter City";
                    return false;
                    }
                    if(this.State == undefined || this.State==""){
                      this.isShownError=false;
                      this.ErrorMsg="Please Enter State";
                      return false;
                      }
                      if(this.Zip == undefined || this.Zip==null){
                        this.isShownError=false;
                        this.ErrorMsg="Please Enter Zip";
                        return false;
                        }
                        var regMobile=/^\d{5}$/;

    if (regMobile.test(this.Zip) == false) {
      this.isShownError = false;
      this.ErrorMsg = "Please Enter The Valid Zip code. ";
      return false;
    }
                        if(this.UserName == undefined || this.UserName==null){
                          this.isShownError=false;
                          this.ErrorMsg="Please Enter UserName";
                          return false;
                          }
                          if(this.Password == undefined || this.Password==null){
                            this.isShownError=false;
                            this.ErrorMsg="Please Enter UserName";
                            return false;
                            }
                            debugger;
                            if(this.file == undefined || this.file.length == 0){
                              this.isShownError=false;
                            this.ErrorMsg="Please Choose file";
                            return false;
                            }
                            var OrderInse = this.http.get(environment.apiUrl +'Waiter/WaiterInsert?Resid='+this.Res_Id  +'&FirstName='+this.FName+'&LName='+ this.LName +'&ssn='+this.SSNNo +'&Mobile='+this.MobileNo +'&Username=' +this.UserName+'&Password='+this.Password +'&Address1='+this.Address1+'&Address2='+this.Address2+'&city='+this.City+'&state='+this.State +'&zip='+this.Zip+'&desc='+this.Description ).toPromise().then((data:any)=>{
                              debugger;
                              if(data =="0"){
                                this.isShownError=false;
                                this.ErrorMsg= "Waiter Insert Failed";
                              
                              }
                              else  {
                                this.isShownSuccess=false;
                                this.SuccessMsg ="Waiter Inserted Successfully";
                                this.Fileupload(data);
                                this.Active("Active");
                                this.clear();
                              }
                            }).catch(function(data:any){
                             
                            });

              
      }
Fileupload(id){
  let fileToUpload = <File>this.file[0];
  const formData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
 this.http.post(environment.apiUrl +'Waiter/UploadContent?UserId='+id, formData, {reportProgress: true, observe: 'events'})
          .subscribe(event => {
        //  //   if (event.type === HttpEventType.UploadProgress)
        //    //   this.progress = Math.round(100 * event.loaded / event.total);
        //     //else if (event.type === HttpEventType.Response) {
        //       //this.message = 'Upload success.';
        //       //this.onUploadFinished.emit(event.body);
        //    // }
          });
}
      onMobileChange(event: any): void {
        debugger;
        var vVal=this.MobileNo.toString();
        var vlength=vVal.toString().length;
       if(vlength==3)
       this.MobileNo="("+vVal+")";
       else if(vlength==8)
       this.MobileNo=vVal+"-";
     }
      Update(){
        this.isShownError = true;
        this.isShownSuccess = true;
        if(this.Res_Id == undefined || this.Res_Id==0){
          this.isShownError=false;
          this.ErrorMsg="Please Choose Resturant Name";
          return false;
          }
          if(this.FName == undefined || this.FName==""){
            this.isShownError=false;
            this.ErrorMsg="Please Enter First Name";
            return false;
            }
            if(this.LName == undefined || this.LName==""){
              this.isShownError=false;
              this.ErrorMsg="Please Enter Last Name";
              return false;
              }
              if(this.SSNNo == undefined || this.SSNNo==""){
                this.isShownError=false;
                this.ErrorMsg="Please Enter SSN No";
                return false;
                }
                if(this.MobileNo == undefined || this.MobileNo==""){
                  this.isShownError=false;
                  this.ErrorMsg="Please Enter Mobile No";
                  return false;
                  }
                  var regMobile = /^[\(]\d{3}[\)]\d{3}[\-]\d{4}$/;

                  if (regMobile.test(this.MobileNo) == false) {
                    this.isShownError = false;
                    this.ErrorMsg = "Please Enter The Valid MobileNo. Format Should like (123)456-7890";
                    return false;
                  }
                  if(this.Address1 == undefined || this.Address1==""){
                    this.isShownError=false;
                    this.ErrorMsg="Please Enter Address Line 1";
                    return false;
                    }
                  if(this.City == undefined || this.City==""){
                    this.isShownError=false;
                    this.ErrorMsg="Please Enter City";
                    return false;
                    }
                    if(this.State == undefined || this.State==""){
                      this.isShownError=false;
                      this.ErrorMsg="Please Enter State";
                      return false;
                      }
                      if(this.Zip == undefined || this.Zip==null){
                        this.isShownError=false;
                        this.ErrorMsg="Please Enter Zip";
                        return false;
                        }
                        var regMobile=/^\d{5}$/;

                        if (regMobile.test(this.Zip) == false) {
                          this.isShownError = false;
                          this.ErrorMsg = "Please Enter The Valid Zip code. ";
                          return false;
                        }
                        if(this.UserName == undefined || this.UserName==null){
                          this.isShownError=false;
                          this.ErrorMsg="Please Enter UserName";
                          return false;
                          }
                          if(this.Password == undefined || this.Password==null){
                            this.isShownError=false;
                            this.ErrorMsg="Please Enter UserName";
                            return false;
                            }
                         
                            var OrderInse = this.http.get(environment.apiUrl +'Waiter/WaiterUpdate?UserId='+this.UserRefId+'&Resid='+this.Res_Id  +'&FirstName='+this.FName+'&LName='+ this.LName +'&ssn='+this.SSNNo +'&Mobile='+this.MobileNo +'&Username=' +this.UserName+'&Password='+this.Password +'&Address1='+this.Address1+'&Address2='+this.Address2+'&city='+this.City+'&state='+this.State +'&zip='+this.Zip+'&desc='+this.Description ).toPromise().then((data:any)=>{
                              debugger;
                              if(data =="0"){
                                this.isShownError=false;
                                this.ErrorMsg= "Waiter Insert Failed";
                              
                              }
                              else  {
                                this.isShownSuccess=false;
                                this.SuccessMsg ="Waiter Inserted Successfully";
                                if(this.file.length == 0 || this.file == undefined){
                                  this.Fileupload(data);
                                }
                               
                                this.Active("Active");
                                this.clear();
                              }
                            }).catch(function(data:any){
                             
                            });

              
      }
      WaiterEdit(id){
        var ViewRes = this.http.get(environment.apiUrl +'Waiter/WaiterEdit?UserId='+id).toPromise().then((data:any)=>{
          debugger;
          if(data.length !=0){
            debugger;
            this.Drop=false;
           this.Saveshow=false;
           this.isShownError = true;
           this.isShownSuccess = true;
        this.Res_Id=data["0"].restaurantId;
        this.FName =data["0"].firstName;
     
        this.LName=(data["0"].lastName);
        this.Image= data;
        this.SSNNo=(data["0"].ssn);
        this.UserName=data["0"].userName;
        this.Password=data["0"].password;
        this.MobileNo=data["0"].mobileNo;
        this.Address1=data["0"].addressLine1;
        this.Address2=data["0"].addressLine2;
        this.City=data["0"].city;
        this.State=data["0"].state;
        this.Zip=data["0"].zip;
        this.Description=data["0"].description;
        this.UserRefId=id;
      //  this.Activebutton=true;
    if(data["0"].status=="Active"){
      this.Activebutton=true;
    }
    else{
      this.Activebutton1=true;
    }
      }
      });
    }
      public loadScript(url: string) {
        const body = <HTMLDivElement> document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
      }
      ActiveCheckboxChangeHeaddr(event){
        if (event.target.checked) {
         this.StatusActive="Active";
    
        }
        else {
        
          this.StatusActive="InActive";
        }
      }
      ActiveCheckboxChange(event) {
        if (event.target.checked) {
          this.Active("Active");
        }
        else {
          this.Active("InActive");
        }
     
      }

      Active(value){
        var ViewGrid = this.http.get(environment.apiUrl +'Waiter/WaiterGrid?Active='+value).toPromise().then((data:any)=>{
          debugger;
       
          //  this.items=data;
            this.dataSource  = new MatTableDataSource(data); 
            this.dataSource.data = data;
            this.dataSource.paginator = this.paginator;
        
         this.dataSource.sort = this.sort;
       
          
        });
      }

      ngAfterViewInit(){
        this.dataSource.paginator = this.paginator;
      }
      exportexcel(): void 
      {
        debugger;
         /* table id is passed over here */   
         const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
         //let element = document.getElementById('data-table-5'); 
        // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
         /* generate workbook and add the worksheet */
         const wb: XLSX.WorkBook = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(wb, workSheet, 'Waiter');
    
         /* save to file */
         XLSX.writeFile(wb, "Waiter.xlsx");
        
      }
      applyFilter(filterValue: string) {
        debugger;
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
      }
}
