
<div class="ms-aside-overlay ms-overlay-left ms-toggler" data-target="#ms-side-nav" data-toggle="slideLeft"></div>
  <div class="ms-aside-overlay ms-overlay-right ms-toggler" data-target="#ms-recent-activity" data-toggle="slideRight"></div>

    <main class="body-content">

        <!-- Navigation Bar -->
        <nav class="navbar ms-navbar" style=" margin: unset;">
            <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft"> <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
              <span class="ms-toggler-bar bg-primary"></span>
            </div>
         
          </nav>

          <div class="row page-titles ms-panel" style="margin-right: unset;  margin-left: unset;">
             
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">Printer Config</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
                <div class="d-flex justify-content-end align-items-center" *ngIf="Drop">
                    <button type="button" class="btn btn-info d-none d-lg-block m-l-15" style="margin-bottom: 10px;" (click)="Show()"><i class="fa fa-plus-circle"></i> Add Printer</button>
                </div>
            </div>
            <div *ngIf="!Drop" class="col-md-12" id="Menus" style="    margin-top: 20px;">
<div class="col-md-12" id="Menus">
    <div class="">
        <button id="Menu" tabindex="92" class="accordion" style="display: block;">Printer Config Details</button>
    </div>

  
</div>
<div class="col-md-12" style="padding-top: 30px;padding-bottom: 15px;">
    <div class="col-md-3">
        <div class="form-group">
            <label class="control-label">Restaurant Name</label>
            <select class="form-control"  [(ngModel)]="Res_Id">
                <option disabled selected value=0>Select Resturant</option>
                <option *ngFor="let res of ResArray" value={{res.restaurantId}}>{{res.name}}</option>

            </select>
    </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <label class="control-label">Printer Name</label>
           <input type="text" class="form-control" [(ngModel)]="PrinterName">
    </div>
    </div>
   
</div>
<div class="col-md-12" style="padding-bottom: 30px;">
    <div class="col-md-3">
        <div class="form-group">
            <label class="control-label">Printer Brand</label>
            <input type="text" class="form-control" [(ngModel)]="PrinterBrand">
    </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <label class="control-label">Printer Location</label>
           <input type="text" class="form-control" [(ngModel)]="Printerloc">
    </div>
    </div>
   
</div>
<div class="col-md-12" style="padding-bottom: 25px;">
    <div class="col-md-6">
        <div class="form-group">
            <label class="control-label">Description</label>
         <input type="text" class="form-control" style="height: 50px;" [(ngModel)]="Description">
    </div>
    </div>
    <div class="col-md-6">
        <div class="col-md-3" style="padding-top: 39px;"  *ngIf="Activebutton">
            <input type="checkbox" checked (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
        </div>
        <div class="col-md-3" style="padding-top: 39px;"   *ngIf="Activebutton1">
          <input type="checkbox" (change)="ActiveCheckboxChangeHeaddr($event)"> Set As Active
      </div>

        <div class="col-md-3"  style="padding-top: 22px;" *ngIf="Saveshow">
            <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Save()">SAVE</button>
        </div>
        <div class="col-md-3"  style="padding-top: 22px;" *ngIf="!Saveshow">
          <button class="btn " style="background: green; color: white;border-radius: 4px;" (click)="Update()">Update</button>
      </div>
        <div class="col-md-3"  style="padding-top: 22px;">
            <button class="btn " style="background: blue; color: white;;border-radius: 4px" (click)="clear()">CLEAR</button>
        </div>
    </div>
</div>
<div class="col-md-12">
  <span [hidden]="isShownError" style="color: red;">{{ErrorMsg}}</span>
  <span [hidden]="isShownSuccess" style="color: green;">{{SuccessMsg}}</span>
</div>


</div>
    <!--/span-->
        </div>

     
        <div class="ms-content-wrapper">
            <div class="row">
      
              <div class="col-md-12">
      
                <div class="ms-panel">
                  <div class="ms-panel-body">
                    <div class="table-responsive">
                      <div id="data-table-5_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="row"><div class="col-sm-12 col-md-6">
                              <div class="dataTables_length" id="data-table-5_length">
                                <label style="padding-right: 25px;">Printer</label>
                                <input type="text"matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" style="border: unset;background:  #ececec;height: 37px;width: 83%;"> <i class="fa fa-search fa-2x" style="color:#EE562F;position: absolute;    left: 88%; top: 3px;"></i>
                            </div></div><div class="col-sm-12 col-md-6"><div id="data-table-5_filter" class="dataTables_filter" style="text-align: left;padding-top: 5px;">
                                 
                                    <button class="btn" (click)="exportexcel()" style="background: blue;color: white;padding:unset;margin: unset; margin-left: 25px;" >Export</button>
                                    <label style="padding-left: 50px;">Show</label>
                                    <label class="switch" style="margin-left: 10px;">
                                        <input type="checkbox" checked (change)="ActiveCheckboxChange($event)">
                                        <span class="slider round"></span>
                                      </label>
                                </div></div></div>
                                <div class="mat-elevation-z8" style="box-shadow: unset;padding-top: 30px ;" #TABLE>
                                    <table mat-table [dataSource]="dataSource" #table style="width: 100%;" matSort >
                                     
                                      <!-- Position Column -->
                                      <ng-container matColumnDef="sno" >
                                        <th mat-header-cell *matHeaderCellDef  style="color: white !important;"> S.NO </th>
                                        <td mat-cell  *matCellDef="let item; let j = index"> {{(j +1)  + (myPaginator.pageIndex * myPaginator.pageSize) }} </td>
                                      </ng-container>
                                  
                                      <!-- Name Column -->
                                      <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef  mat-sort-header style="color: white !important;"> Restaurant Name </th>
                                        <td mat-cell *matCellDef="let element" (click)="printerEdit(element.printerConfigId)"> <a>{{element.name}}</a>  </td>
                                      </ng-container>
                                  
                                      <!-- Weight Column -->
                                      <ng-container matColumnDef="Printer" >
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white !important;">Printer Name </th>
                                        <td mat-cell *matCellDef="let element"  (click)="printerEdit(element.printerConfigId)"> {{element.printerName}} </td>
                                      </ng-container>
                                  
                                      <!-- Symbol Column -->
                                      <ng-container matColumnDef="PrinterBrand" >
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> Printer Brand </th>
                                        <td mat-cell *matCellDef="let element"  (click)="printerEdit(element.printerConfigId)">  {{element.printerBrand}} </td>
                                      </ng-container>
                                      <ng-container matColumnDef="Printerloc" >
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> Printer Location </th>
                                        <td mat-cell *matCellDef="let element"  (click)="printerEdit(element.printerConfigId)">  {{element.printerlocation}} </td>
                                      </ng-container>
                                      <ng-container matColumnDef="description" >
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="color: white !important;"> Description </th>
                                        <td mat-cell *matCellDef="let element"  (click)="printerEdit(element.printerConfigId)">  {{element.description}} </td>
                                      </ng-container>
                                      <ng-container matColumnDef="Status" >
                                        <th mat-header-cell *matHeaderCellDef  style="color: white !important;text-align: center;"> Active </th>
                                        <td mat-cell *matCellDef="let element"  (click)="printerEdit(element.printerConfigId)"> 
                                       {{element.active}}
                                       </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                  
                                    <mat-paginator #myPaginator [pageSizeOptions]="[5, 10,15, 20,30,40]" showFirstLastButtons></mat-paginator>
                                  </div>
                    
                                                            </div>
                    </div>
                  </div>
                </div>
      
              </div>
      
            </div>
          </div>
    
      </main>
  
